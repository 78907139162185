import React from 'react';
import { Typography,Row, Col, Space, BackTop } from 'antd';
import {TransText} from './PublicMethods'
const { Title, Text, Link } = Typography;

class Footer extends React.Component {
  render() {
    return <>
      <div className = "box" style={{padding:30}}>
        <div style={{maxWidth:1200,margin:'auto'}}>
          <Row>
            <Col xs={24} sm={12} md={12} lg={6}>
              <Space direction="vertical" size={"middle"}>
                <Title level={3} style={{color:'#002766'}}><TransText CN='下载ZOOM' EN='Download ZOOM' /></Title>
                <a href="https://zoom.us/zh-cn/meetings.html"><img style = {{width:135, height:135}} src = "images/static/zoom.jpg" alt = "ZOOM"/></a>
              </Space>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6}>
              <Space direction="vertical" size={"middle"}>
                <Title level={3} style={{color:'#002766'}}><TransText CN='客服一' EN='Support' /></Title>
                <img style = {{width:135, height:135}} src = "images/users/service_wechat.jpg" alt = "客服二维码"/>
              </Space>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6}>
              <Space direction="vertical" size={"middle"}>
                <Title level={3} style={{color:'#002766'}}><TransText CN='客服二' EN='Support' /></Title>
                <img style = {{width:135, height:135}} src = "images/users/service_wechat2.jpg" alt = "客服二维码"/>
              </Space>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6}>
              <Space direction="vertical" size={"middle"}>
                <Title level={3} style={{color:'#002766'}}><TransText CN='传送门' EN='Portal' /></Title>
                <Link href="/" style={{color:'#0050b3'}}><TransText CN='网站首页' EN='Home' /></Link>
                <Link href="/course" style={{color:'#0050b3'}}><TransText CN='课程总览' EN='Course' /></Link>
                <Link href="/about" style={{color:'#0050b3'}}><TransText CN='关于我们' EN='About Us' /></Link>
                <Link href="/admin" style={{color:'#0050b3'}}><TransText CN='管理入口' EN='Admin' /></Link>
              </Space>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col span={24}>
              <Text style={{fontWeight:'bold', color:'#002766'}}>eEmu Education. All rights reserved. © Copyright 2021</Text>
            </Col>
          </Row>
          {/* <Row>
            <Col span={24}>
              <Text copyable style={{color:'#003a8c'}}>Suite 405, 74 Pitt St, Sydney NSW 2000</Text>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Text copyable style={{color:'#003a8c'}}>Level 16, 239 George St, Brisbane QLD 4000</Text>
            </Col>
          </Row> */}
          <Row>
            <Col span={24}>
              <Text><img style={{width:16,height:16}} alt="" src="//img.alicdn.com/tfs/TB1..50QpXXXXX7XpXXXXXXXXXX-40-40.png" /><a href="https://beian.miit.gov.cn/" target="#">浙ICP备2021004999</a></Text>
            </Col>
          </Row>
          <BackTop />
        </div>
      </div>
		</>;
  }
}

export default Footer;