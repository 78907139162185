const isDev = false;

export const backHost = isDev?
{
    host:"http://localhost",
    port:3000,
}
: {
    host:"https://eemuedu.com",
    port:3000,
};


export const socketHost = isDev? 
{
    host:"//localhost:3001",
}
: {
    host:"//eemuedu.com",
};