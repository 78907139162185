import React,{Component} from 'react';
import { Layout, Breadcrumb } from 'antd';
import { SideMenu } from '../components/userprofile/SideMenu';
import ViewProxyStudent from '../components/admin/ViewProxyStudent';
import ViewProgress from '../components/admin/ViewProgress';
import ViewSubject from '../components/admin/ViewSubject';
import ViewCategory from '../components/admin/ViewCategory';
// import ViewTeacher from '../components/admin/ViewTeacher';
import ViewAssign from '../components/admin/ViewAssign';
import ViewNewOrder from '../components/admin/ViewNewOrder';
import AdminProfile from '../components/AdminProfile';
import ViewLesson from '../components/admin/ViewLesson';
import EditLesson from '../components/admin/EditLesson';
import ViewProxySettleSheet from '../components/admin/ViewProxySettleSheet';
import { UserOutlined, AppstoreOutlined, PercentageOutlined, CalculatorOutlined, ClusterOutlined, BellOutlined, BranchesOutlined, AimOutlined,DollarOutlined } from '@ant-design/icons';

const { Sider, Content } = Layout;


class AdminProxy extends Component{
    state={
        tag_selected:0,
        tag_list:[
            {
                tag:<><UserOutlined />{'我的学生'}</>,
                node:<ViewProxyStudent />
            },
            {
                tag:<><PercentageOutlined />{'我的学生课程进度'}</>,
                node:<ViewProgress />
            },
            {
                tag:<><AimOutlined />{'最近课程安排'}</>,
                node:<ViewLesson/>
            },
            {
                tag:<><BranchesOutlined />{'查看待上课时'}</>,
                node:<EditLesson />
            },
            {
                tag:<><BellOutlined />{'课程预约'}</>,
                node:<ViewNewOrder />
            },
            {
                tag:<><CalculatorOutlined />{'全部课程'}</>,
                node:<ViewSubject />
            },
            {
                tag:<><ClusterOutlined />{'全部分类'}</>,
                node:<ViewCategory />
            },
            // {
            //     tag:<><SolutionOutlined />{'全部教师'}</>,
            //     node:<ViewTeacher />
            // },
            {
                tag:<><AppstoreOutlined />{'查看授课安排'}</>,
                node:<ViewAssign />
            },
            {
                tag:<><DollarOutlined />{'结算明细'}</>,
                node:<ViewProxySettleSheet />
            },
        ],
    }

    componentDidMount(){
    }

    handleTagChange(index){
        this.setState({
            tag_selected:index
        })
    }

    render(){
        const {tag_selected,tag_list} = this.state
        const content = tag_list[tag_selected].node
        const title = tag_list[tag_selected].tag
        return (
            <>
            <Sider width={260}>
                <AdminProfile />
                <SideMenu 
                menuItem={tag_selected+''} 
                menu={tag_list} 
                divider={new Map([[0,'学生'],[5,'课程'],[7,'教师'],[9,"结算"]])}
                tagClick = {this.handleTagChange.bind(this)}
                />
            </Sider>
            <Content style={{ padding: '20px 50px' }}>
                <div style={{maxWidth:1450, margin:'auto', marginBottom: 20}}>
                    <Breadcrumb  style={{ textAlign:'left' }}>
                        <Breadcrumb.Item>代理</Breadcrumb.Item>
                        <Breadcrumb.Item>{title}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <div id="container" style={{padding:'30px 10px', maxWidth:1450, margin:'auto', backgroundColor:'white'}}>
                    {content}
                </div>
            </Content>
            </>
        )
    }
}

export default AdminProxy;
