import React,{Component} from 'react';
import TopNav from '../components/TopNav';
import { Layout } from 'antd';
import Content1 from '../components/home/Content1';
import Content2 from '../components/home/Content2';
import Content3 from '../components/home/Content3';
import Footer1 from '../components/Footer1';
import '../style/pages/home.css'
const { Header, Content, Footer } = Layout;



class Home extends Component{
    constructor(){
        super()
        this.state={
            page: [
                {
                    content: <Content1 />
                },
                {
                    content: <Content2 />,
                    style:{backgroundColor:'#ffffff'}
                },
                {
                    content: <Content3 />
                },
               ]
        }
    }

    render(){
        const {page} = this.state
        return (
            <Layout>
                <Header style={{backgroundColor: 'white'}}>
                    <TopNav menuItem={'1'}/>
                </Header>
                {
                    page.map((contents,index)=>{
                        return (
                            <Content 
                            key={index}
                            style={contents.style}>
                                {contents.content}
                            </Content>
                        )
                    })
                }
                <Footer style={{ textAlign: 'center', backgroundColor:'#fafafa' }}>
                    <Footer1 />
                </Footer>
            </Layout>
        )
    }
}

export default Home;