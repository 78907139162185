import React from 'react';
import { Menu, Divider } from 'antd';

export const SideMenu = (props) =>{
	const {menuItem,menu,divider,tagClick} = props
	return <>
			<Menu
				mode="inline"
				defaultSelectedKeys={[menuItem]}
				style={{ height: '100%', borderRight: 0 }}
			>
				{menu.map((tagNode,index)=>{
					return (
					<React.Fragment key={index}>
					{
						divider.get(index)?
						<div>
							<Divider orientation="left" plain>{divider.get(index)}</Divider>
						</div>
						:
						<></>
					}
					<Menu.Item 
					key={index}
					onClick={()=>tagClick(index)}
					style={{textAlign:'left' }}
				>{tagNode.tag}</Menu.Item>
					</React.Fragment>
					)
					
				})}
			</Menu>
	</>;
}
