import React,{Component} from 'react';
import { Layout, Breadcrumb } from 'antd';
import { SideMenu } from '../components/userprofile/SideMenu';
import ViewAdmin from '../components/admin/ViewAdmin';
import AddAdmin from '../components/admin/AddAdmin';
import AddSubject from '../components/admin/AddSubject';
import AddCategory from '../components/admin/AddCategory';
import AdminProfile from '../components/AdminProfile';
import SendEmail from '../components/admin/SendEmail';
import { CalculatorOutlined, MailOutlined, UsergroupAddOutlined, TeamOutlined, ClusterOutlined } from '@ant-design/icons';
const { Sider, Content } = Layout;

class AdminFounder extends Component{
    state={
        tag_selected:0,
        tag_list:[
            {
                tag:<><CalculatorOutlined />{'新增课程'}</>,
                node:<AddSubject />
            },
            {
                tag:<><ClusterOutlined />{'新增分类'}</>,
                node:<AddCategory />
            },
            {
                tag:<><TeamOutlined />{'全部员工'}</>,
                node:<ViewAdmin />
            },
            {
                tag:<><UsergroupAddOutlined />{'添加员工'}</>,
                node:<AddAdmin />
            },
            {
                tag:<><MailOutlined />{'发送eEmu官方邮件'}</>,
                node:<SendEmail />
            },
        ]
    }

    handleTagChange(index){
        this.setState({
            tag_selected:index
        })
    }

    render(){
        const {tag_selected,tag_list} = this.state
        const content = tag_list[tag_selected].node
        const title = tag_list[tag_selected].tag
        return (
            <>
            <Sider width={260}>
                <AdminProfile />
                <SideMenu 
                menuItem={tag_selected+''} 
                menu={tag_list} 
                divider={new Map([[0,'课程'],[2,'内部'],[4,'邮件']])}
                tagClick = {this.handleTagChange.bind(this)}
                />
            </Sider>
            <Content style={{ padding: '20px 50px' }}>
                <div style={{maxWidth:1450, margin:'auto', marginBottom: 20}}>
                    <Breadcrumb  style={{ textAlign:'left' }}>
                        <Breadcrumb.Item>站长</Breadcrumb.Item>
                        <Breadcrumb.Item>{title}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                
                <div id="container" style={{padding:'30px 10px', maxWidth:1450, margin:'auto', backgroundColor:'white'}}>
                    {content}
                </div>
            </Content>
            </>
        )
    }
}

export default AdminFounder;
