import React from 'react';
import { Table, Input, Button, Space, Tooltip, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { GetCourseInfo } from '../../request/axios'
import { AlertMsg } from '../PublicMethods';

class SearchableTable extends React.Component {
	state = {
	  searchText: '',
	  searchedColumn: '',
	  data: [],
	  loading:true
	};
	
	loadData(){
		GetCourseInfo({admin_id:sessionStorage.getItem('admin_id'),table:'cate'},data=>{
			const {data:{result,status,tips}} = data
			if(status!==100){
				AlertMsg({message:'数据错误',des:tips})
				return
			}
			result.forEach((el,index)=>{
				el.key = index
			})
			this.setState({
				data:result,
				loading:false
			})
		}).catch(err=>{
			AlertMsg({message:'网络错误',des:'网络连接出错'})
			console.log(err)
		})
	}

	componentDidMount(){
		this.loadData()
	}
    
	getColumnSearchProps = dataIndex => ({
	  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		<div style={{ padding: 8 }}>
		  <Input
			ref={node => {
			  this.searchInput = node;
			}}
			placeholder={`搜索 ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ width: 188, marginBottom: 8, display: 'block' }}
		  />
		  <Space>
			<Button
			  type="primary"
			  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  icon={<SearchOutlined />}
			  size="small"
			  style={{ width: 90 }}
			>
			  搜索
			</Button>
			<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  重置
			</Button>
		  </Space>
		</div>
	  ),
	  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	  onFilter: (value, record) =>
		record[dataIndex]
		  ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		  : '',
	  onFilterDropdownVisibleChange: visible => {
		if (visible) {
		  setTimeout(() => this.searchInput.select(), 100);
		}
	  },
	  render: text =>
		this.state.searchedColumn === dataIndex ? (
		  <Highlighter
			highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			searchWords={[this.state.searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
		  />
		) : (
		  text
		),
	});
  
	handleSearch = (selectedKeys, confirm, dataIndex) => {
	  confirm();
	  this.setState({
		searchText: selectedKeys[0],
		searchedColumn: dataIndex,
	  });
	};
  
	handleReset = clearFilters => {
	  clearFilters();
	  this.setState({ searchText: '' });
	};
  
	render() {
		if(this.state.loading){
            return <Spin size="large"/>
        }
		const columns = [
            {
				title: 'id',
				dataIndex: 'cate_id',
                key: 'cate_id',
                width: 60,
				...this.getColumnSearchProps('cate_id'),
            },
            {
				title: '分类代码',
				dataIndex: 'course_code',
                key: 'course_code',
                width: 120,
				...this.getColumnSearchProps('course_code'),
            },
            {
				title: '分类名称',
				dataIndex: 'course_name',
                key: 'course_name',
                width: 300,
				...this.getColumnSearchProps('course_name'),
            },
            {
				title: '描述',
				dataIndex: 'course_des',
                key: 'course_des',
                ellipsis: {
                    showTitle: false,
                },
                  render: course_des => (
                    <Tooltip placement="topLeft" title={course_des}>
                      {course_des}
                    </Tooltip>
                ),
				align:'center'
			}
        ];
	  	return <Table bordered columns={columns} dataSource={this.state.data} pagination={{ pageSize: 20 }} size="middle"/>;
	}
}

class ViewCategory extends React.Component {
    render(){
        return <>
            <SearchableTable />
        </>
    }
}

export default ViewCategory;