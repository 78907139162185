import React,{Component} from 'react';
import { Layout } from 'antd';
import TopNav from '../components/TopNav';
import Form from '../components/login/Form'
import Footer1 from '../components/Footer1';
import { CheckIfLogIn } from '../components/PublicMethods';

const { Header, Content, Footer } = Layout;

class Login extends Component{

    componentDidMount(){
        if(CheckIfLogIn() === 1){
            if(sessionStorage.getItem('s_id')){
                window.location.href = "/userprofile"
                return
            }
            if(sessionStorage.getItem('admin_id')){
                window.location.href = "/admin"
                return
            }
            if(sessionStorage.getItem('t_id')){
                window.location.href = "/teacherprofile"
                return
            }
        }
    }

    render(){
        return <>
            <Layout>
                <Header style={{backgroundColor: 'white'}}>
                    <TopNav menuItem={'6'}/>
                </Header>
                <Content 
                style={{padding: '50px 50px', minHeight: 500}}>
                    <Form target={this.props.target} />
                </Content>
                <Footer style={{ textAlign: 'center', backgroundColor:'#fafafa' }}>
                    <Footer1 />
                </Footer>
            </Layout>  
        </>
    }
}

export default Login;