import React from 'react';
import ReactDOM from 'react-dom';
import IndexRoute from './routes/IndexRouter';
import './style/index.css';
import './iconfont/iconfont.css';
import socket from './request/socketServer'
import {GlobalContext} from './request/context'

const {Provider} = GlobalContext

ReactDOM.render(
<Provider value={{socket,}}>
    <IndexRoute />
</Provider>,document.getElementById('root'))