import React from 'react';
import ReactDOM from 'react-dom';
import {storage} from '../utils/localStorage';
import {deleteFileString, getSTSOss} from '../request/axios'
import OSS from 'ali-oss';
import cookie from 'react-cookies'

export const TransText = (props) =>{
    let language = storage.getItem('language') || 'CN'
    const {CN,EN} = props
    return (
            language==='CN'?
            CN
            :
            EN
    )
}

/**
 * 
 * @param {object} props message,des,type([warning,info,success,error]) 
 */
export const AlertMsg=(props)=>{
    const {type='warning',message='Infomational Notes',des='',callback} = props
    const typecolor = new Map([
        ['warning',['#fffbe6','#ffe58f','#fecc11']],
        ['info',['#e6f7ff','#91d5ff','#5698c3']],
        ['success',['#f6ffed','#b7eb8f','#b7d07a']],
        ['error',['#fff2f0','#ffccc7','#ef475d']],
    ])
    let check = document.getElementById('locator');
    if(check) return
    let div = document.createElement('div');
    div.setAttribute('id','locator');
    let alert = <div 
    style={{backgroundColor:typecolor.get(type)[0],borderColor:typecolor.get(type)[1]}}
    id='alert-message'>
        <div className='content-msg'>
            <h2><span className={`iconfont icon-${type==='success'?'chenggong':type}`}
                style={{color:typecolor.get(type)[2]}}
            />{message}</h2>
            <p>{des}</p>
        </div>
    </div>
    ReactDOM.render(alert,div)
    let root = document.getElementById('root');
    root.appendChild(div);
    div.onclick=(e)=>{
        if(callback){
            callback()
        }
        div.remove();
    }
}

export const SplitENCN=(props)=>{
    if(props){
        let array = props.split("||")
        return {EN: array[0], CN: array[1]};
    }else{
        return {EN: "", CN: ""};
    }
}

export const CheckIfLogIn=()=>{
    if(sessionStorage.getItem('s_id')||sessionStorage.getItem('t_id')||sessionStorage.getItem('admin_id')){
        return 1
    }else{
        return 0
    }
}

/**
 * 
 * @param {String} form like 'Y-M-D H:m:s'
 * @param {time} date new Time(data) 
 */
export const DateFormat = (form,date)=>{
    if(!(date instanceof Date)){
        date = new Date(date);
    }
    var Y = date.getFullYear().toString().padStart(2,"0");
    var M = (date.getMonth()+1).toString().padStart(2,"0");
    var D = date.getDate().toString().padStart(2,"0");
    var H = date.getHours().toString().padStart(2,"0");
    var m = date.getMinutes().toString().padStart(2,"0");
    var s = date.getSeconds().toString().padStart(2,"0");
    var result = form;
    result = result.replace(/Y+/g,Y)
    result = result.replace(/M+/g,M)
    result = result.replace(/D+/g,D)
    result = result.replace(/H+/g,H)
    result = result.replace(/m+/g,m)
    result = result.replace(/s+/g,s)
    return result;
}

export const parseDescription = (string)=>{
    if(!string) return string
    let des_object = JSON.parse(string)
    return (
        <div className='description'>
            {
                Object.keys(des_object).map((key,index)=>{
                    return (
                        <div className='des-card' key={index}>
                            {
                                key==="*"?
                                <></>
                                :
                                <h2>
                                    {key}
                                </h2>
                            }
                            
                            <p>
                                {des_object[key]}
                            </p>
                        </div>
                        
                    )
                })
            }
        </div>
    )
}

/**
 * 
 * @param {*} socket socket server
 * @param {*} role 当前角色 student || admin || teacher
 * @param {*} id 用户id
 * @param {*} online 在线消息回调
 * @param {*} offline 离线消息回调
 */
export const socketMessage = (socket,role,id,online,offline)=>{
    if(!socket) return
    socket.emit("beforeConnect",{role,id,})
    socket.on("onlineMessage",data=>{
        online(data)
    })
    socket.on("offLineMessage",data=>{
        offline(data)
    })
}

/**
 * 获取在线预览url
 * @param {*} bucket bucket name
 * @param {*} object_name object name
 */
export const getViewUrl = (bucket, object_name,getUrl)=>{
    let ossConfig;
    let client;
    let url;
    if (cookie.load("STS")) {
        ossConfig = cookie.load("STS")
        client = new OSS({
            region: 'oss-cn-hangzhou',
            bucket: bucket,
            ...ossConfig
        });
        // 此处以设置URL的有效时长为3600s为例，若不设置有效时长，则默认为1800s。
        url = client.signatureUrl(object_name, {expires: 3600});
        getUrl(url)
        return
    } else {
        getSTSOss().then(data=>{
            const {data:{Expiration,...rest}} = data
            ossConfig = rest
            cookie.save('STS', ossConfig, { maxAge: 3590 })
            client = new OSS({
                region: 'oss-cn-hangzhou',
                bucket: bucket,
                ...ossConfig
            });
            // 此处以设置URL的有效时长为3600s为例，若不设置有效时长，则默认为1800s。
            url = client.signatureUrl(object_name, {expires: 3600});
            getUrl(url)
            return
        })
    }
}

/**
 * 获取下载url
 * @param {*} bucket bucket name
 * @param {*} object_name object name
 */
export const getDownloadUrl =(bucket, object_name,getUrl)=>{
    let ossConfig;
    let client;
    let url;
    if (cookie.load("STS")) {
        ossConfig = cookie.load("STS")
        client = new OSS({
            region: 'oss-cn-hangzhou',
            bucket: bucket,
            ...ossConfig
        });
        // 配置响应头实现通过URL访问时自动下载文件，并设置下载后的文件名。
        const filename = object_name // filename为自定义下载后的文件名。
        const response = {
        'content-disposition': `attachment; filename=${encodeURIComponent(filename)}`
        }
        // object-key表示从OSS下载文件时需要指定包含文件后缀在内的完整路径，例如abc/efg/123.jpg。
        url = client.signatureUrl(object_name, { response });
        getUrl(url)
        return
    } else {
        getSTSOss().then(data=>{
            const {data:{Expiration,...rest}} = data
            ossConfig = rest
            cookie.save('STS', ossConfig, { maxAge: 3590 })
            client = new OSS({
                region: 'oss-cn-hangzhou',
                bucket: bucket,
                ...ossConfig
            });
            // 配置响应头实现通过URL访问时自动下载文件，并设置下载后的文件名。
            const filename = object_name // filename为自定义下载后的文件名。
            const response = {
            'content-disposition': `attachment; filename=${encodeURIComponent(filename)}`
            }
            // object-key表示从OSS下载文件时需要指定包含文件后缀在内的完整路径，例如abc/efg/123.jpg。
            url = client.signatureUrl(object_name, { response });
            getUrl(url)
            return
        })
    }
}

/**
 * 删除单个oss文件
 * @param {*} 
 * @param {*} 
 */
export const deleteOSS =(bucket, file_name, lesson_id, column, callback )=>{
    let ossConfig;
    let client;
    if (cookie.load("STS")) {
        ossConfig = cookie.load("STS")
        client = new OSS({
            region: 'oss-cn-hangzhou',
            bucket: bucket,
            ...ossConfig
        });
    } else {
        getSTSOss().then(data=>{
            const {data:{Expiration,...rest}} = data
            ossConfig = rest
            cookie.save('STS', ossConfig, { maxAge: 3590 })
            client = new OSS({
                region: 'oss-cn-hangzhou',
                bucket: bucket,
                ...ossConfig
            });
            
        })
    }
    async function deletee(client){
        try {
          let result = await client.delete(file_name);
          console.log(result)
        } catch (e) {
          console.log(e);
        }
    }
    deleteFileString({file_name, lesson_id, column,},request_res=>{
        const {data:{result,status,tips="查询错误"}} = request_res
        if(status!==100){
            AlertMsg({message:tips,des:"更新出错"})
        }else{
            deletee(client)
            callback(result)
        }
    }).catch(err=>{
        AlertMsg({message:"网络错误",des:"请求数据出错"})
        console.log(err.message)
    })
}

/**
 * 删除多个oss文件
 * @param {*} 
 * @param {*} 
 */
export const deleteMultipleOSS =(bucket, file_name_array)=>{
    let ossConfig;
    let client;
    if (cookie.load("STS")) {
        ossConfig = cookie.load("STS")
        client = new OSS({
            region: 'oss-cn-hangzhou',
            bucket: bucket,
            ...ossConfig
        });
    } else {
        getSTSOss().then(data=>{
            const {data:{Expiration,...rest}} = data
            ossConfig = rest
            cookie.save('STS', ossConfig, { maxAge: 3590 })
            client = new OSS({
                region: 'oss-cn-hangzhou',
                bucket: bucket,
                ...ossConfig
            });
            
        })
    }
    async function deleteeMulti(client){
        try {
          let result = await client.deleteMulti(file_name_array);
          console.log(result)
        } catch (e) {
          console.log(e);
        }
    }
    deleteeMulti(client)
}

/**
 * responsive
 * @param {*} 
 * @param {*} 
 */
 export const responsiveWithWidth=(width)=>{
    const currentWith = document.body.offsetWidth
    if(currentWith<width){
        return currentWith/width
    }else{
        return 1
    }
}