import React from 'react';
import { Table, Input, Button, Space, Popconfirm, message, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { AdminConfirmCancelledLesson, AdminGetCancelledLesson } from '../../request/axios';
import { AlertMsg } from '../PublicMethods'
class SearchableTable extends React.Component {
	state = {
	  searchText: '',
	  searchedColumn: '',
	  data: [],
	  loading:true
	};
	
	loadData(){
		AdminGetCancelledLesson({admin_id:sessionStorage.getItem('admin_id')}).then(data=>{
			const {data:{result,status,tips}} = data
			if(status!==100){
				AlertMsg({message:'数据错误',des:tips})
				return
			}
			result.forEach((el,index)=>{
				el.key = index
			})
			this.setState({
				data:result,
				loading:false
			})
		}).catch(err=>{
			AlertMsg({message:'网络错误',des:'网络连接出错'})
			console.log(err)
		})
	}

    confirm(lesson_id){
        AdminConfirmCancelledLesson({admin_id:sessionStorage.getItem('admin_id'),lesson_id:lesson_id}).then(data=>{
			const {data:{status,tips}} = data
			if(status!==100){
				AlertMsg({message:'数据错误',des:tips})
				return
			}else{
                message.success('成功标为已读！')
                this.loadData()
            }
		}).catch(err=>{
			AlertMsg({message:'网络错误',des:'网络连接出错'})
			console.log(err)
		})
    }

	componentDidMount(){
		this.loadData()
	}
    
	getColumnSearchProps = dataIndex => ({
	  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		<div style={{ padding: 8 }}>
		  <Input
			ref={node => {
			  this.searchInput = node;
			}}
			placeholder={`搜索 ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ width: 188, marginBottom: 8, display: 'block' }}
		  />
		  <Space>
			<Button
			  type="primary"
			  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  icon={<SearchOutlined />}
			  size="small"
			  style={{ width: 90 }}
			>
			  搜索
			</Button>
			<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  重置
			</Button>
		  </Space>
		</div>
	  ),
	  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	  onFilter: (value, record) =>
		record[dataIndex]
		  ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		  : '',
	  onFilterDropdownVisibleChange: visible => {
		if (visible) {
		  setTimeout(() => this.searchInput.select(), 100);
		}
	  },
	  render: text =>
		this.state.searchedColumn === dataIndex ? (
		  <Highlighter
			highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			searchWords={[this.state.searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
		  />
		) : (
		  text
		),
	});
  
	handleSearch = (selectedKeys, confirm, dataIndex) => {
	  confirm();
	  this.setState({
		searchText: selectedKeys[0],
		searchedColumn: dataIndex,
	  });
	};
  
	handleReset = clearFilters => {
	  clearFilters();
	  this.setState({ searchText: '' });
	};
  
	render() {
		if(this.state.loading){
            return <Spin size="large"/>
        }
		const columns = [
            {
				title: '学生学号',
				dataIndex: 'student_num',
                key: 'student_num',
                width: 120,
				...this.getColumnSearchProps('student_num'),
            },
            {
				title: '学生姓名',
				dataIndex: 'student_username',
                key: 'student_username',
                width: 100,
				...this.getColumnSearchProps('student_username'),
            },
            {
				title: '教师工号',
				dataIndex: 'teacher_num',
                key: 'teacher_num',
                width: 120,
				...this.getColumnSearchProps('teacher_num'),
            },
            {
				title: '教师姓名',
				dataIndex: 'username',
                key: 'username',
                width: 150,
				...this.getColumnSearchProps('username'),
            },
            {
				title: '课程代码',
				dataIndex: 'subject_code',
                key: 'subject_code',
                width: 120,
				...this.getColumnSearchProps('subject_code'),
            },
            {
				title: '名称',
				dataIndex: 'name',
                key: 'name',
                width: 200,
				...this.getColumnSearchProps('name'),
            },
            {
				title: '上课时间',
				dataIndex: 'lesson_time',
                key: 'lesson_time',
                width: 100,
                render: (text)=>{
                    return new Date(parseInt(text)).toLocaleString()
                },
				align:'center'
            },
            {
				title: '备注',
				dataIndex: 'note',
                key: 'note',
                width: 100,
				align:'center'
            },
            {
				title: '操作',
				key: 'action',
				width: 100,
				render: (record) => (
					record.state===4?
					<Space size="middle">
						<Popconfirm
                            title="你确定要标记为已读吗？"
                            onConfirm={()=>this.confirm(record.lesson_id)}
                            onCancel={this.cancel}
                            okText="Yes"
                            cancelText="No"
                            style={{textAlign:'left'}}
                            icon={null}
                        >
                            <a href="/admin">标为已读</a>
                        </Popconfirm>
					</Space>
					:
					<>已读</>
				),
				align:'center'
            },
        ];
	  	return <Table bordered columns={columns} dataSource={this.state.data} pagination={{ pageSize: 20 }} size="middle"/>;
	}
}

class ViewCancelledLesson extends React.Component {
    render(){
        return <>
            <SearchableTable />
        </>
    }
}

export default ViewCancelledLesson;