import React from "react";
import {
    BrowserRouter as Router,
    Route
} from "react-router-dom";
import routeConfig from './RoutesConfig'

export default function RouteConfigExample() {
    return (
        <Router>
            {routeConfig.map((router,index)=>{
                return (
                    <Route key={index}
                    exact={router.exact} path={router.path}>
                        {router.component}
                    </Route>
                )
            })}
        </Router>
    );
}