import React,{Component} from 'react';
import {Input,Button,Row, Col,Card, message} from "antd"
import { sendQuestion } from '../../request/axios'
import '../../style/components/question.css'
const { TextArea } = Input


class QuestionServer extends Component{
    state = {
        type:null,
        count:1,
        value:""
    }

    submit = ()=>{
        let {count,type,value} = this.state
        console.log(count)
        if(type===null){
            message.warning("请选择一个类型")
            return
        }
        let last = sessionStorage.getItem("last_submit")
        if(last&&Date.now()<parseInt(last)+5*60000){
            message.info("消息发送频繁,请稍后再试")
            return
        }
        if(count>3){
            sessionStorage.setItem("last_submit",Date.now())
        }
        sendQuestion({
            username:sessionStorage.getItem("s_username"),
            student_num:sessionStorage.getItem("user_num"),
            type,
            remark:value,
        },data=>{
            const {data:{status}} = data
            if(status===100) message.success("提交成功")
        })
        this.setState({
            count:count+1
        })
    }

    handleText = ({ target: { value } })=>{
        this.setState({
            value,
        })
    }
    
    render(){
        const {type,value} = this.state
        return (
            <div style={{textAlign:"start"}} id="q_server">
                <h1>选择您的问题</h1>
                <div style={{margin:'auto', display:"flex",justifyContent:"space-around",alignItems:"center",marginBottom:40}}>
                    {
                        ["调整课程进度","课程教学建议","课程续费"].map((e,index)=>{
                            return <div key={index} className={`q_card ${index===type?"q_select":""}`} 
                            onClick={()=>this.setState({type:index})}>
                                {e}
                            </div>
                        })
                    }
                </div>
                    {type!==null?
                        <>
                            <h3 style={{fontSize:"1.5em",fontWeight:"bolder"}}>感谢您的建议:</h3>
                            <TextArea value={value} onChange={this.handleText} style={{marginBottom:40}} autoSize={{ minRows: 2, maxRows: 6 }} />
                        </>
                    :
                    <></>}
                <Button type="" onClick={this.submit}>提交</Button>
                <div style={{maxWidth:1000, margin:'auto', display:"flex",justifyContent:"center",alignItems:"center", marginTop:30}}>
                <Row>
                    <Col span={12}>
                        <Card title="客服微信1" bordered={false}>
                            <img src="images/users/service_wechat.jpg" alt="" />
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card title="客服微信2" bordered={false}>
                            <img src="images/users/service_wechat2.jpg" alt="" />
                        </Card>
                    </Col>
                </Row>
            </div>
            </div>
        )
    }
}

export default QuestionServer