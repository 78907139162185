import React, { Component,useState } from 'react';
import { Layout,notification,Button, message,Modal,Typography,DatePicker} from 'antd'
import TopNav from '../components/TopNav'
import {SideMenu} from '../components/userprofile/SideMenu'
import {Profile,ClientServer} from '../components/userprofile/Profile'
import CourseInfo from '../components/userprofile/CourseInfo'
import Timetable from '../components/userprofile/TimeTable'
import ChangeInfo from '../components/userprofile/ChangeInfo'
import AlterTimeForm from '../components/teacherprofile/AlterTimeForm';
import UpcomingLessons from '../components/teacherprofile/UpcomingLessons'
import { getUserProfile,endHoliday,setHoliday } from '../request/axios'
import {GlobalContext} from '../request/context'
import { AlertMsg, TransText, socketMessage, CheckIfLogIn,DateFormat } from '../components/PublicMethods'
import { UserOutlined, TableOutlined, ReadOutlined, FormOutlined, CustomerServiceOutlined,ExclamationCircleOutlined,OrderedListOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const {  Text } = Typography;
const { Sider, Header } = Layout;

class UserProfile extends Component{
    constructor(){
        super()
        this.state={
            s_id:sessionStorage.getItem('s_id'),
            student_info:{},
            tag_selected:0,
            tag_list:[
                {
                    tag:<><UserOutlined /><TransText CN='用户概览' EN='Dashboard'/></>,
                    node:<Profile student_info={{}} student_progress={{}}/>
                },
                {
                    tag:<><OrderedListOutlined /><TransText CN='我的课表' EN='My Timetable'/></>,
                    node:<UpcomingLessons/>
                },
                {
                    tag:<><TableOutlined /><TransText CN='本周安排' EN='Weekly Arrangements'/></>,
                    node:<></>
                },
                {
                    tag:<><ReadOutlined /><TransText CN='我的课程' EN='My Courses'/></>,
                    node:<CourseInfo />
                },
                {
                    tag:<><FormOutlined /><TransText CN='更改资料' EN='Change Profile'/></>,
                    node:<ChangeInfo />
                },
                {
                    tag:<><ExclamationCircleOutlined /><TransText CN='更换时间请求' EN='Time Change Requests'/></>,
                    node:<AlterTimeForm />
                },
                {
                    tag:<><CustomerServiceOutlined /><TransText CN='客服咨询' EN='Contact Support'/></>,
                    node:<ClientServer />
                },
                
            ]
        }
    }

    handleTagChange(index){
        this.setState({
            tag_selected:index
        })
    }

    socket(){
        const socket = this.context.socket
        socketMessage(socket,"student",sessionStorage.getItem("s_id"),onlineData=>{
            this.openNotification(onlineData)
        },offLineData=>{
            offLineData.forEach(data=>{
                this.openNotification(data)
            })
        })
    }

    openNotification = (msg) => {
        const args = {
          message: TransText({CN:"课程通知",EN:"Notification"}),
          description:
          msg,
          duration: 0,
        };
        notification.open(args);
      };
    
    componentDidMount(){
        //检查是否登录
        if(CheckIfLogIn() === 1){
            if(sessionStorage.getItem('t_id')){
                window.location.href = "/teacherprofile"
                return 
            }
            if(sessionStorage.getItem('admin_id')){
                window.location.href = "/admin"
                return 
            }
        }else{
            window.location.href = "/login"
            return 
        }
        getUserProfile({s_id:this.state.s_id},data=>{
            const {data:{result,tips,status}} = data
            if(status!==100){
                AlertMsg({message:'错误',des:tips})
                return
            }
            let [...tag_list] = this.state.tag_list
            tag_list[0].node = <Profile student_info={result}/>
            tag_list[2].node = <this.TimetabkeRender />
            this.socket()
            this.setState({
                tag_list,
                student_info:result
            })
            if(sessionStorage.getItem("holiday") && Date.now()>parseInt(sessionStorage.getItem("holiday").split(","))){
                this.openHolidayNotification(sessionStorage.getItem("holiday").split(","))
            }
        }).catch(err=>{
            AlertMsg({message:'错误',des:'网络错误'+err.message})
        })
        // console.log(sessionStorage.getItem("s_username"))
    }

    TimetabkeRender=()=>{
        const [isModalVisible, setIsModalVisible] = useState(false);
        const showModal = () => {
            setIsModalVisible(true);
        };
        const handleOk = (v) => {
            if(!v){
                message.warning("您还没有选择时间")
                return
            }
            const period = [...v.map(t=>{
                return new Date(t).setHours(6,0,0,0)
            })]
            setHoliday({holidayPeriod:period,s_id:sessionStorage.getItem("s_id")},data=>{
                const {data:{tips,status,result}} = data
                if(status!==100){
                    message.warning(tips)
                    return
                }
                message.success(`假期设置成功${result?",假期中有"+result+"节课已被推迟":""}`)
                setIsModalVisible(false);
                sessionStorage.setItem("holiday",period.join(","))
                setTimeout(()=>window.location.reload(),1500)
            }).catch(err=>{
                message.error("网络错误")
                console.log(err)
            })
        };
        const [dates, setDates] = useState([]);
        const [hackValue, setHackValue] = useState();
        const [dateValue, setValue] = useState();
        const handleCancel = () => {
            setIsModalVisible(false);
        };
        const disabledDate = current => {
            if (!dates || dates.length === 0) {
                //5天内
                let timestamp = new Date(current).getTime()
                return timestamp<new Date().getTime() || timestamp>new Date().getTime()+5*24*3600000
            }
            // 两周假期
            const tooLate = dates[0] && current.diff(dates[0], 'days') > 14;
            const tooEarly = dates[1] && dates[1].diff(current, 'days') > 14;
            const delay = dates[1] && new Date(current).getTime()<new Date().getTime()+7*24*3600000
            return tooEarly || tooLate || delay;
        };
        const onOpenChange = open => {
            if (open) {
              setHackValue([]);
              setDates([]);
            } else {
              setHackValue(undefined);
            }
        };
        return <div style={{padding:'30px',width:'100%'}}>
        <Button type="primary" size="large" onClick={showModal} style={{position:"absolute",right:"5%",marginTop:10}}>假期设置</Button>
        <Modal title="设置假期时间" visible={isModalVisible} onOk={()=>handleOk(dateValue)} onCancel={handleCancel}>
            <Text strong>可设置未来5天内开始的假期时间，两周为最长假期时间。</Text><br />
            <RangePicker
                style={{margin:"20px auto"}}
                value={hackValue || dateValue}
                disabledDate={disabledDate}
                onCalendarChange={val => setDates(val)}
                onChange={val => setValue(val)}
                onOpenChange={onOpenChange}
                />
        </Modal>
        <Timetable 
            s_id={sessionStorage.getItem('s_id')}
            type='student'
             /></div>
    }

    openHolidayNotification = (period) => {
        const key = `open${Date.now()}`;
        const btn = (
          <Button type="primary" size="small" onClick={() =>{
            endHoliday({s_id:sessionStorage.getItem("s_id"),period,},data=>{
                const {data:{status,tips}} = data
                if(status!==100){
                    message.warning(tips)
                    return
                }
                sessionStorage.setItem("holiday","null")
                notification.close(key)
                setTimeout(()=>window.location.reload(),1500)
            }).catch(err=>{
                console.log(err)
            })
          }}>
            立刻结束假期
          </Button>
        );
        notification.open({
          message: '假期时间',
          description:parseInt(period[1])<Date.now()
          ?
          <><p>您的假期已经结束</p><p>结束日期:<b>{DateFormat("Y年M月D日",parseInt(period[1]))}</b></p><p>请点击结束假期</p></>
          :
          <><p>您正处于假期时间</p><p>开始日期:<b>{DateFormat("Y年M月D日",parseInt(period[0]))}</b></p><p>结束日期:<b>{DateFormat("Y年M月D日",parseInt(period[1]))}</b></p></>
          ,
          btn,
          duration: 0,
          key,
          onClose: ()=>console.log("close!"),
        });
      };

    render(){
        const {tag_selected,tag_list} = this.state
        const content = tag_list[tag_selected].node
        return <>
            <Layout style={{ minHeight:'100vh' }}>
                <Header style={{backgroundColor: 'white'}}>
                    <TopNav menuItem={'6'}/>
                </Header>
                <Layout>
                    <Sider width={240}>
                        <SideMenu 
                        menuItem={tag_selected+''} 
                        menu={tag_list} 
                        // ,[2,<TransText CN='购买' EN='Payment'/>]
                        divider={new Map([[0,<TransText CN='学生' EN='Student'/>],[6,<TransText CN='客服' EN='Support'/>]])}
                        tagClick = {this.handleTagChange.bind(this)}
                        />
                    </Sider>
                    {content}
                </Layout>
            </Layout>
        </>
    }
}

UserProfile.contextType = GlobalContext

export default UserProfile;