import React,{Component} from 'react';
import {Card,Skeleton  } from 'antd'
import { SplitENCN,TransText } from '../PublicMethods'
import {GlobalContext} from '../../request/context'

const {Consumer} = GlobalContext

class NextTimeInfo extends Component{
    state={
        days:0,
        hours:0,
        minutes:0,
        seconds:0
    }

    componentDidMount(){
        this.handleCountDown(this.context.nextLesson.lesson_time)
    }

    componentWillUnmount(){
        clearInterval(this.timer);
    }

    handleCountDown(time){
        let end_time = new Date(parseInt(time)).getTime(),
        diff_second = (end_time - new Date().getTime());
        this.timer = setInterval(() => {
          if (diff_second > 1000) {
            diff_second -= 1000;
            let days = ~~((diff_second / 1000 / 3600) / 24);
            let hours = ~~((diff_second / 1000 / 3600) % 24);
            let minutes = ~~((diff_second / 1000 / 60) % 60);
            let seconds = ~~(diff_second / 1000 % 60);
            this.setState({
              days,
              hours:(hours+"").padStart(2,"0"),
              minutes:(minutes+"").padStart(2,"0"),
              seconds:(seconds+"").padStart(2,"0")
            })
          } else {
            clearInterval(this.timer);
            //倒计时结束时触发父组件的方法
            this.context.timeEnd(this.handleCountDown.bind(this));
          }
        }, 1000);
    }

    handleDisplayTime(){
        const {
            days,
            hours,
            minutes,
            seconds
        } = this.state
        if(days+hours+minutes+seconds===0) return <Skeleton paragraph={{ rows: 0 }} round />
        return (
        <div>
            <span>{days}</span>{TransText({CN:"天",EN:"D"})}
            <span>{hours}</span>{TransText({CN:"时",EN:"H"})}
            <span>{minutes}</span>{TransText({CN:"分",EN:"M"})}
            <span>{seconds}</span>{TransText({CN:"秒",EN:"S"})}
            </div>
        )
    }

    render(){
        return (
            <Consumer>
                {
                value=>{
                    return <Card title={value.title} className="next-time-card">
                        <div style={{fontSize:'1.3em'}}>
                            {
                                TransText({
                                    CN: SplitENCN(value.nextLesson.name).CN,
                                    EN: SplitENCN(value.nextLesson.name).En
                                })
                            }
                            {`${value.nextLesson.subject_code} for ${value.nextLesson.username}`}
                        </div>
                        <div>
                            {new Date(parseInt(value.nextLesson.lesson_time)).toLocaleString()}
                        </div>
                        <div>
                            {this.handleDisplayTime()}
                        </div>
                    </Card>
                }
                }
            </Consumer>
            
        )
    }
}

NextTimeInfo.contextType = GlobalContext

export default NextTimeInfo