import React, { Component } from 'react';
import '../../style/components/course/course_info.css'
import { AlertMsg, getViewUrl, TransText,DateFormat, deleteOSS, getDownloadUrl } from '../PublicMethods';
import { getTeacherTime, alterLessonTime, cancelLesson,updateZoom } from '../../request/axios'
import { Tabs, Empty, Popconfirm, Typography,Button,Input, message,Row,Col,Tooltip,Modal, Divider  } from 'antd';
import { GlobalContext } from '../../request/context'
import FileUploader from '../FileUploader'
import copy from 'copy-to-clipboard';
import { DeleteOutlined } from '@ant-design/icons';

const { Text, Link, Title } = Typography;

const { TabPane } = Tabs;
const { Provider } = GlobalContext;

class CourseDetail extends Component {
    state = {
        info_confirm: false,
        lessons: [],
        already_take: [],
        upcoming: [],
        sub_id: this.context.sub_id || null,
        s_id: this.context.s_id || null,
        t_id: this.context.t_id || null,
        teacher_info: [],
        teacher: 0,
        time: 0,
        lesson_id: 0,
        lesson_time:0,
        role: '',
        partner_name: '',
        subject_code: '',
        input_zoom:null,
        zoom:null,
        status: -1,
        remarks:""
    }

    componentDidMount() {
        const [...lessons] = this.context.lessons
        if (sessionStorage.getItem('s_id')) {
            this.setState({
                role: 'student',
                partner_name: this.context.partner_name,
                subject_code: this.context.subject_code
            })
        } else {
            this.setState({
                role: 'teacher',
                partner_name: this.context.partner_name,
                subject_code: this.context.subject_code
            })
        }
        this.classify(lessons)
    }

    classify(lessons) {
        let already_take = []
        let upcoming = []
        if (lessons.length) {
            let pro = 0
            lessons.forEach((lesson, index) => {
                if(lesson.state>=4){
                    lesson.lesson_pro = ''
                }else{
                    pro = pro + 1 
                    lesson.lesson_pro = pro
                }
                if (lesson.state >= 2) {
                    already_take.push(lesson)
                } else {
                    upcoming.push(lesson)
                }
            })
            already_take = already_take.reverse()
            this.setState({
                already_take,
                upcoming,
                lesson_id: upcoming[0] ? upcoming[0].lesson_id:0,
                lesson_time: upcoming[0] ? upcoming[0].lesson_time:0,
                status: upcoming[0] ? upcoming[0].state:-1,
            })
        }
    }

    handleRenderNewFile(lesson_id, column, content, type) {
        let list;
        if (type) {
            [...list] = this.state.already_take
        } else {
            [...list] = this.state.upcoming
        }
        list.forEach(item => {
            if (item.lesson_id === lesson_id) {
                item[column] = content
            }
        })
        if (type) {
            this.setState({
                already_take: list
            })
        } else {
            this.setState({
                upcoming: list
            })
        }
    }

    deleteFile(bucket, file_name, lesson_id, column, type){
        deleteOSS(bucket, file_name, lesson_id, column, new_name_array=>{
            message.info(TransText({'CN':'删除成功。','EN':'Deleted.'}))
            this.handleRenderNewFile(lesson_id, column, new_name_array, type)
        })
    }

    generateUrl(string, bucket, lesson_id, column, type) {
        let name_array = JSON.parse(string) || []
        let result = []
        name_array.forEach((name, i) => {
            result.push(
                <React.Fragment key={i}>
                    
                    {bucket==='eemu-recording'&&this.state.role==='student'?
                        <>
                        <Link onClick={() => getViewUrl(bucket, name,url=>{
                            window.open(url, '_blank')
                        })}>
                            {'在线观看 '+(i+1)}
                        </Link>
                        <Divider type="vertical" style={{height:'100%'}}/>
                        <Link onClick={() => getDownloadUrl(bucket, name, url=>{
                            window.open(url, '_blank')
                        })}>
                            {'下载视频 '+(i+1)}
                        </Link>
                        <Divider type="vertical" style={{height:'100%'}}/>
                        </>
                    :
                        <Link onClick={() => getViewUrl(bucket, name,url=>{
                            window.open(url, '_blank')
                        })}>
                            {name}
                        </Link>
                    }
                    {sessionStorage.getItem('t_id')||(column==="handover"&&sessionStorage.getItem('s_id'))?<DeleteOutlined onClick={()=>this.deleteFile(bucket,name,lesson_id,column,type)}/>:<></>}
                    <br />
                </React.Fragment>
            )
        })
        return result
    }

    callback(key) {
        const { already_take, upcoming } = this.state
        if (key === '1') {
            this.setState({
                lesson_id: upcoming.length ? upcoming[0].lesson_id : 0,
                lesson_time: upcoming.length ? upcoming[0].lesson_time : 0,
            })
        } else {
            this.setState({
                lesson_id: already_take.length ? already_take[0].lesson_id : 0,
                lesson_time: already_take.length ? already_take[0].lesson_time : 0,
            })
        }
    }

    onSelectLesson(key){
        let [...upcoming] = this.state.upcoming
        this.setState({
            lesson_id:upcoming[key].lesson_id,
            lesson_time:upcoming[key].lesson_time,
            status:upcoming[key].state
        })
    }

    showAlterTimeWindow(lesson_time) {
        getTeacherTime({ sub_id: this.context.sub_id,t_id:this.context.t_id, lesson_time, }, data => {
            const { data: { result,tips,status } } = data
            if(status!==100){
                message.error(tips)
                return
            }
            this.setState({
                info_confirm: true,
                teacher_info: result,
            })
        }).catch(err => {
            console.log(err)
        })
    }

    handleTimeSubmit() {
        const { teacher_info, teacher, time, lesson_id,lesson_time, role } = this.state
        const time_now = new Date().getTime()
        if(parseInt(lesson_time)-time_now<86400000){
            message.warning(role === 'student'?"请提前一天修改":"Please modify time at least one day in advance")
            return
        }
        alterLessonTime({
            role: sessionStorage.getItem("s_id")?"student":"teacher",
            lesson_id,
            time_stamp:teacher_info[teacher].available_time[time],
        }, data => {
            if (data.data.status === 100) {
                if(sessionStorage.getItem("s_id")){
                    message.success(`申请次数:${data.data.result},修改时间申请提交成功,请等待老师回复`)
                }else{
                    message.success(`Number of applications:${data.data.result},The application is submitted successfully, please wait for the student's response`)
                }
                setTimeout(()=>{
                    this.setState({ info_confirm: false })
                    window.location.reload()
                },2000)
            } else {
                message.warning(data.data.tips)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    handleLessonState = (lesson_id, state, lesson_time) => {
        const stateMap = new Map([
            [0,"Start Lesson"],
            [1,"End Lesson"],
            [2,"Already End"],
            [4,"Lesson Cancel"],
        ])
        return (
            <div style={{ cursor: 'pointer', backgroundColor: state ? "#feffd9" : "#d9ffdb" }}
                onClick={() => {
                    if(state===0){
                        if (lesson_time - new Date().getTime() < 15 * 60000) {
                            if (lesson_time+1000*60*75 >= new Date().getTime()){
                                let [...lessons] = this.context.lessons
                                lessons.forEach(l => {
                                    if (l.lesson_id === lesson_id) l.state = l.state + 1
                                })
                                this.classify(lessons)
                                this.context.lessonState(lesson_id, state + 1, this.context.s_id)
                            }else{
                                message.info("Sorry, the lesson has expired. Please contact admin if you forgot to start this lesson.")
                            }
                        } else {
                            message.info("The lesson can start in 15min earlier than Start Time.")
                        }
                    }else{
                        if(state===1){
                            this.commentInfo(lesson_id,state)
                            return
                        }
                        let [...lessons] = this.context.lessons
                        lessons.forEach(l => {
                            if (l.lesson_id === lesson_id) l.state = l.state + 1
                        })
                        this.classify(lessons)
                        this.context.lessonState(lesson_id, state + 1, this.context.s_id)
                    }
                }}>
                {stateMap.get(state)?<h3>{stateMap.get(state)}</h3>:<h3>Done</h3>}
            </div>
        )
    }

    commentInfo=(lesson_id,state)=>{
        Modal.success({
            title:"Message board",
            content:(
                <div style={{padding:"20px"}}>
                <p style={{fontSize:"1.3em",textAlign:"start"}}>Optional: leave a short evaluation of this lesson (max 200 length).</p>
                <Input.TextArea style={{textAlign:"start"}} rows={6} maxLength={200} onChange={this.handleInputChange} />
                </div>
            ),
            onOk:this.handleOnOk.bind(this,lesson_id,state)
        })
    }

    handleInputChange=(e)=>{
        if(e.target.value.length===200){
            message.warning("Max length: 200")
            return
        }
        this.setState({
            remarks:e.target.value
        })
    }

    handleOnOk=(lesson_id,state)=>{
        let [...lessons] = this.context.lessons
        lessons.forEach(l => {
            if (l.lesson_id === lesson_id) l.state = l.state + 1
        })
        this.classify(lessons)
        this.context.lessonState(lesson_id, state + 1, this.context.s_id,this.state.remarks)
    }

    handleCancelSubmit() {
        const {lesson_time, lesson_id, status, role} = this.state
        const time_now = Date.now()
        //提前24小时
        if(parseInt(lesson_time)-time_now>=43200000 || (time_now >= parseInt(lesson_time)+1000*60*15 && status === 0)){
            cancelLesson({
                role: sessionStorage.getItem("s_id")?"student":"teacher",
                lesson_id,
            }, data => {
                const { data: { status, tips } } = data
                if (status !== 100) {
                    AlertMsg({ message: "取消错误", des: tips, type: 'error' })
                    return
                }
                message.success(role ==='student'?"取消申请提交成功":"Lesson has been cancelled successfully!")
                setTimeout(()=>window.location.reload(),1000)
            })
        }else{
            message.warning(role ==='student'?"课程取消条件：提前一天、课程延误。":"Lesson cancellation condition: one day in advance.")
            return
        }
    }

    displayInputZoom = () =>{
        this.setState({
            input_zoom:<Input onChange={(e)=>this.handleZoomValue(e)} />
        })
    }

    handleZoomValue = (e) =>{
        this.setState({
            zoom:e.target.value
        })
    }

    changeZoom = (index) =>{
        if(index===-1){
            this.setState({
                zoom:null,
                input_zoom:null,
            })
            return 
        }
        const {zoom,lesson_id} = this.state
        const zoomReg = /^[0-9]{8,13}$/
        if(!zoomReg.test(zoom)){
            message.warning("Please inout valid zoom id")
            return 
        }
        updateZoom({zoom,lesson_id,},data=>{
            const {data:{status}} = data
            if(status!==100) {
                message.warning("Something Wrong")
                return
            }
            let [...upcomming] = this.state.upcoming
            upcomming[index].zoom = zoom
            message.success("Update Successfully")
            this.setState({input_zoom:null,upcomming})
        })
    }

    renderTimeFormate(start_time,end_time,updated){
        return (
            <Row>
                {
                    updated?
                    <Col span={12}>
                        <div style={{display:"flex",height:"100%",justifyContent:"center",alignItems:'center',}}>
                            {updated}
                        </div>
                    </Col>
                    :
                    <></>
                }
                <Col span={updated?12:24}>
                <div style={{display:"flex",flexDirection:'column'}}>
                    <span style={{fontSize:"1.7em",fontWeight:"600"}}>
                        {DateFormat("H:m",parseInt(start_time))}
                        {end_time?"-"+DateFormat("H:m",parseInt(end_time)):''}</span>
                    <span style={{fontSize:"0.7em",marginTop:"-6px"}}>{DateFormat("Y/M/D",parseInt(start_time))}</span>
                </div>
                </Col>
            </Row>
        )
    }

    copyZoom(value){
        copy(value)
        message.info(TransText({CN:"已复制",EN:"Copied"}))
    }

    render() {
        const { already_take, upcoming,role,input_zoom,partner_name,subject_code } = this.state
        const stateMap = new Map([
            [0,{EN:"Start Lesson",CN:"待上课"}],
            [1,{EN:"End Lesson",CN:"正在上课"}],
            [2,{EN:"Already End",CN:"已结课"}],
            [4,{EN:"Lesson Cancel",CN:"课程取消"}],
            [5,{EN:"Lesson Cancel",CN:"课程取消"}],
        ])
        return (
            <>
                {this.renderChangeTime()}
                <div className='lesson-page'>
                    <button onClick={() => this.context.back()}>{TransText({ CN: '返回', EN: 'Back' })}</button>
                    <Tabs defaultActiveKey="1" type="card" size="large" onChange={(key) => this.callback(key)}>
                        <TabPane tab={TransText({ CN: '上课准备', EN: 'Upcoming' })} key="1">
                            {
                                upcoming.length?
                                <Tabs defaultActiveKey="0" tabPosition="left" onChange={key => this.onSelectLesson(key)} style={{ minHeight: 500 }}>
                                {
                                    upcoming.map((lesson,index) => (
                                        <TabPane 
                                        tab={lesson.state===1?<b>{TransText({ CN: '正在上课', EN: 'Ongoing' })}</b>:`${TransText({ CN: '课时', EN: 'Lesson#' })} ${lesson.lesson_pro}`} 
                                        key={index}>
                                            <div className='lesson-info'>
                                                <div className='lesson-info-detail'>
                                                    <Title level={2} style={{margin:'auto'}}>{`${subject_code} - ${partner_name}`}</Title>
                                                    <h1>{TransText({ CN: '课程信息-第', EN: 'Lesson Details #' })}{lesson.lesson_pro}{TransText({ CN: '课时', EN: '' })}</h1>
                                                    <div>
                                                        <div>
                                                            {
                                                            lesson.temp_time?
                                                                <>
                                                                <h3>{TransText({ CN: '临时上课时间', EN: 'Temporary Time' })}</h3>
                                                                {this.renderTimeFormate(lesson.temp_time)}
                                                                </>
                                                                :
                                                                <>
                                                                <h3>{TransText({ CN: '上课时间', EN: 'Lesson Time' })}</h3>
                                                                {this.renderTimeFormate(lesson.lesson_time)}
                                                                </>
                                                            }
                                                            {
                                                                <span
                                                                    className='iconfont icon-shijian'
                                                                    onClick={() => this.showAlterTimeWindow(lesson.lesson_time)}
                                                                />
                                                            }
                                                        </div>
                                                        <div>
                                                        <Tooltip placement="top" title={<a rel="noopener noreferrer" href="https://zoom.us/download" target="_Blank" style={{color:"#ffffff"}}>{TransText({CN:"客户端下载",EN:"Download"})}</a>}>
                                                        <h3>ZOOM</h3>
                                                        </Tooltip>
                                                            {
                                                                role === 'student' 
                                                                ?
                                                                <Tooltip placement="top" title={TransText({CN:"点击复制",EN:"Click to copy"})}>
                                                                <span style={{cursor:"pointer"}} onClick={()=>this.copyZoom(lesson.zoom)}>{lesson.zoom}</span>
                                                                </Tooltip>
                                                                :
                                                                <>
                                                                    {input_zoom?
                                                                        <>
                                                                        {input_zoom}
                                                                        <div style={{display:"flex"}}>
                                                                        <Button style={{flex:1}} onClick={()=>this.changeZoom(index)}>Submit</Button>
                                                                        <Button style={{flex:1}} onClick={()=>this.changeZoom(-1)}>Cancel</Button>
                                                                        </div>
                                                                        </> 
                                                                        :
                                                                        <>
                                                                        <Tooltip placement="top" title={TransText({CN:"点击复制",EN:"Click to copy"})}>
                                                                            <span style={{cursor:"pointer"}} onClick={()=>this.copyZoom(lesson.zoom)}>{lesson.zoom}</span>
                                                                        </Tooltip>
                                                                        <Button onClick={this.displayInputZoom}>Update new Zoom ID</Button>
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                        {
                                                            role === 'student' ?
                                                                <div>
                                                                    <h3>最近更新</h3>
                                                                    {this.renderTimeFormate(lesson.update_time,null,"更新时间:")}
                                                                </div>
                                                                :
                                                                this.handleLessonState(
                                                                    lesson.lesson_id, 
                                                                    lesson.state,
                                                                    lesson.temp_time?parseInt(lesson.temp_time):parseInt(lesson.lesson_time))
                                                        }
                                                    </div>
                                                </div>
                                                <div>
                                                    {role === 'student' ?
                                                        <>
                                                            {this.renderFileSection('上课相关的文件。','上课文件',lesson.file,"eemu-file")}
                                                        </>
                                                        :
                                                        <>
                                                            {this.renderFileSection('You can upload relative course documents here.','Lesson File',lesson.file,"eemu-file","file",lesson.lesson_id,1)}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </TabPane>
                                    ))
                                }
                            </Tabs>
                            :
                            <Empty description={TransText({CN:"您的课程已结束",EN:"No upcomming lessons"})} />
                            }
                            
                        </TabPane>
                        <TabPane tab={TransText({ CN: '完成课程', EN: 'Completed' })} key="2">
                            {
                                !already_take.length ?
                                    <div className='lesson-empty'>
                                        <Empty description={TransText({ CN: '您还没有完成的课时', EN: 'No Classes' })} />
                                    </div>
                                    :
                                    <Tabs defaultActiveKey="0" tabPosition="left" >
                                        {
                                            already_take.map((lesson, index) => (
                                                <TabPane tab={lesson.state>3
                                                ?
                                                TransText({ CN: '已取消#'+lesson.lesson_pro, EN: 'Cancelled #'+lesson.lesson_pro })
                                                :
                                                `${TransText({ CN: '课时', EN: 'Lesson#' })} ${lesson.lesson_pro}`} key={index}>
                                                    <div className='lesson-info'>
                                                        <div className='lesson-info-detail'>
                                                            <Title level={2} style={{margin:'auto'}}>{`${subject_code} - ${partner_name}`}</Title>
                                                            <h1>{TransText({ CN: '课程信息-第', EN: 'Lesson Details-#' })}{lesson.lesson_pro}{TransText({ CN: '课时', EN: '' })}</h1>
                                                            <div>
                                                                <div>
                                                                    <h3>{TransText({ CN: '下课时间', EN: 'End Time' })}</h3>
                                                                    {lesson.end_time?
                                                                        this.renderTimeFormate(lesson.lesson_time,lesson.end_time)
                                                                        :
                                                                        this.renderTimeFormate(lesson.lesson_time)
                                                                    }
                                                                </div>
                                                                <div>
                                                                    <h3>ZOOM</h3>
                                                                    <span>{lesson.zoom || 9472138923}</span>
                                                                </div>
                                                                <div>
                                                                    <h3>{TransText({...stateMap.get(lesson.state)})}</h3>
                                                                    {this.renderTimeFormate(lesson.update_time,null,TransText({CN:"更新时间",EN:"Updated at"}))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            role === "student" ?
                                                                <>
                                                                    {lesson.remarks&&lesson.remarks!==""?
                                                                        <div>
                                                                            <h1>老师评价</h1>
                                                                            <p className="commnet-board">{lesson.remarks}</p>
                                                                        </div>
                                                                    :    
                                                                        <></>
                                                                    }
                                                                    {this.renderFileSection('上课相关的文件。','上课文件',lesson.file,"eemu-file")}
                                                                    {this.renderFileSection('老师布置的课后作业。','课后作业',lesson.assessment,"eemu-assessment")}
                                                                    {this.renderFileSection('课程录像文件。','上课视频',lesson.video,"eemu-recording")}
                                                                    {this.renderFileSection('您可以在这里提交作业。','提交作业',lesson.handover,"eemu-assessment","handover",lesson.lesson_id,1)}
                                                                </>
                                                                :
                                                                <>
                                                                    {this.renderFileSection('You can upload relative course documents here.','Lesson File',lesson.file,"eemu-file","file",lesson.lesson_id,1)}
                                                                    {this.renderFileSection('You can upload assessment here for the parents to see.','Assessment',lesson.assessment,"eemu-assessment","assessment",lesson.lesson_id,1)}
                                                                    {this.renderFileSection('You can upload lesson recordings here.','Lesson Video',lesson.video,"eemu-recording","video",lesson.lesson_id,1)}
                                                                    {this.renderFileSection('Assignments submitted by students will be displayed here.','Student Work',lesson.handover,"eemu-assessment")}
                                                                </>
                                                        }
                                                    </div>
                                                </TabPane>
                                            ))
                                        }
                                    </Tabs>
                            }

                        </TabPane>
                    </Tabs>
                </div>
            </>
        )
    }

    renderFileSection(des,title,file,bucket,column,lesson_id,type){
        return  <div>
        <h1>{title}</h1>
        {des?<p style={{color:"gray",fontSize:"0.9em"}}>{des}</p>:<></>}
        <p style={{color:"gray",fontSize:"0.9em"}}><TransText CN="文件14天内有效。" EN="Documents are valid within 14 days." /></p>
        {file ?
            this.generateUrl(file, bucket, lesson_id, column, type).map((item) => {
                return item
            })
            :
            <Empty description={false} />
        }
        {
            column
            ?
                JSON.parse(file || `[]`).length < 4 
                ?
                    <Provider value={{ 
                        bucket, 
                        s_id: sessionStorage.getItem('s_id') || this.state.s_id, 
                        t_id: sessionStorage.getItem('t_id') || this.state.t_id, 
                        sub_id: this.state.sub_id, 
                        column, 
                        lesson_id, 
                        type
                    }}>
                        <FileUploader func={this.handleRenderNewFile.bind(this)} />
                    </Provider>
                :
                    <Text>Maximun file number reached.</Text>
            :
            <></>
        }
    </div>
    }

    renderChangeTime() {
        const { info_confirm, teacher_info, teacher, time, role } = this.state
        return (
            info_confirm ?
                <section id='confirm-window'>
                    {
                        teacher_info.length ?
                            <>
                                <div>
                                    <span className='iconfont icon-warning'></span>
                                    { role === 'student'?
                                        <><p><b>修改时间</b>：您必须在课程开始24小时前提交申请，且新的课时时间限制在前后一周之内。</p>
                                            <p><b>取消课时</b>：如您无法顺利出席该课，您可申请取消这一节课时，系统将自动为您补充一节课时。</p></>
                                    :
                                        <><p style={{margin:0}}><b>Modify lesson time</b>: You must submit an application at least 24 hours before the start of the </p>
                                        <p style={{margin:0}}>lesson, and the new lesson time is limited to one week before and after.</p>
                                        <p style={{margin:0}}><b>Cancel this lesson</b>: If you are unable to attend the lesson, you can apply to cancel it. </p>
                                        <p style={{margin:0}}>The system will automatically allocate a new lesson.</p></>
                                    }
                                </div>
                                <div>
                                    { role === 'student'?'可选老师':'Available Teachers'}
                                    {   
                                        teacher_info.map((teach, index) => {
                                            return <span
                                                onClick={() => this.setState({ teacher: index })}
                                                style={teacher === index ? { backgroundColor: '#c7faa9' } : {}}
                                                key={index}>{teach.username}</span>
                                        })
                                    }
                                    <span>
                                        <Popconfirm
                                            placement="bottom"
                                            title={role === 'student'?<p style={{fontSize:"1.5em"}}>{`${DateFormat("确定取消Y年M月D日H:m的课程?",parseInt(this.state.lesson_time))}`}</p>:"Are you sure to cancel this lesson?"}
                                            onConfirm={() => this.handleCancelSubmit()}
                                            okText={role === 'student'?"确定取消":"Confirm"}
                                            cancelText={role === 'student'?"暂不取消":"Disregard"}
                                            icon={false}
                                        >
                                            {role === 'student'?'取消当节课时':'Cancel this lesson'}
                                </Popconfirm>
                                    </span>
                                </div>
                                <div>
                                    {
                                        teacher_info[teacher].available_time.length ?
                                        <>
                                        <TransText CN="可选的时间" EN="Available times" />
                                        { 
                                            teacher_info[teacher].available_time.map((t, index) => {
                                                return (
                                                    <span className='time-card'
                                                        key={index}
                                                        style={time === index ? { backgroundColor: '#c7faa9' } : {}}
                                                        onClick={() => this.setState({ time: index })}
                                                    >{new Date(parseInt(t)).toLocaleString()}</span>
                                                )
                                            })
                                        }
                                        </>
                                            :
                                            <>
                                                {role === 'student'?'老师没有合适的可选时间':'There is no available time.'}
                                                <span>
                                                        <Popconfirm
                                                            placement="bottom"
                                                            title={role === 'student'?<p style={{fontSize:"1.5em"}}>{`${DateFormat("确定取消Y年M月D日H:m的课程?",parseInt(this.state.lesson_time))}`}</p>:"Are you sure to cancel this lesson?"}
                                                            onConfirm={() => this.handleCancelSubmit()}
                                                            okText={role === 'student'?"确定取消":"Confirm"}
                                                            cancelText={role === 'student'?"暂不取消":"Disregard"}
                                                            icon={false}
                                                        >
                                                            {role === 'student'?'取消当节课时':'Cancel this lesson'}
                                                </Popconfirm>
                                                    </span>
                                            </>
                                    }
                                </div>
                                <div>
                                    <div onClick={() => this.setState({ info_confirm: false })}>
                                        {role === 'student'?'取消':'Cancel'}
                            </div>
                                    <Popconfirm
                                        title={role === 'student'?`确认更换时间到${new Date(parseInt(teacher_info[teacher].available_time[time])).toLocaleString()}`:`Change lesson time to ${new Date(parseInt(teacher_info[teacher].available_time[time])).toLocaleString()}`}
                                        onConfirm={() => this.handleTimeSubmit()}
                                        okText="Yes"
                                        cancelText="No"
                                        icon={false}
                                        disabled={!teacher_info[teacher].available_time[time]}
                                    >
                                        <div>
                                            {role === 'student'?'确认':'Confirm'}
                                </div>
                                    </Popconfirm>

                                </div>
                            </>
                            :
                            <>
                                <div>{role === 'student'?'当前没有合适的可选时间':'There is currently no available time.'}</div>
                                <div></div>
                                <div></div>
                                <div>
                                    <div onClick={() => this.setState({ info_confirm: false })}>
                                        {role === 'student'?'取消':'Cancel'}
                            </div>
                                </div>
                            </>
                    }
                </section>
                :
                <></>
        )
    }
}

CourseDetail.contextType = GlobalContext

export default CourseDetail