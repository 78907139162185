import React from 'react';
import { Form, Input, Button, Select, message, Result } from 'antd';
import { AdminAddAdmin } from '../../request/axios';
import { AlertMsg } from '../PublicMethods';
import { SmileOutlined } from '@ant-design/icons';

const { Option } = Select;

class AddAdmin extends React.Component {

    state = {
        admin_num: null,
    }

    onFinish = (values) => {
        AdminAddAdmin({...values, admin_id:sessionStorage.getItem('admin_id')},request_res=>{
            const {data:{result,status,tips="查询错误"}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"查询员工数据出错"})
            }else{
                this.setState({admin_num: result})
                message.success('添加成功！')
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
    }

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }
    
    render(){
        if (this.state.admin_num != null){
            return <Result
                icon={<SmileOutlined />}
                title={"分配员工账号成功！工号为：" + this.state.admin_num}
                subTitle="员工工号将用于登录。已发送邮件通知该新员工（邮件内包含工号和初始密码）。"
                extra={[
                    <Button type="primary" onClick={() => this.setState({admin_num:null})}>
                        继续添加
                    </Button>
                ]}
            />
        }else{
            const layout = {
                labelCol: {
                    span: 8,
                },
                wrapperCol: {
                    span: 8,
                },
            }
            const tailLayout = {
                wrapperCol: {
                    offset: 6,
                    span: 12,
                },
            }
            let options = null
            if(sessionStorage.getItem('auth_id') === "2" || sessionStorage.getItem('auth_id') === "1"){
                options = [<Option value="2">创始人、学生管理员</Option>,
                    <Option value="3">创始人、教师管理员</Option>,
                    <Option value="4">学生管理员</Option>,
                    <Option value="5">教师管理员</Option>,
                    <Option value="6">代理</Option>]
            }else{
                options = [<Option value="5">教师管理员</Option>,
                    <Option value="6">代理</Option>]
            }
            return <>
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    >
                    <Form.Item
                        label="姓名"
                        name="username"
                        rules={[
                        {
                            required: true,
                            message: '请输入新管理员的姓名！',
                        },
                        ]}
                    >
                        <Input placeholder="宅总"/>
                    </Form.Item>

                    <Form.Item
                        label="邮箱"
                        name="email"
                        rules={[
                        {
                            required: true,
                            message: '请输入新管理员的邮箱！',
                        },
                        ]}
                    >
                        <Input placeholder="123456789@mail.com"/>
                    </Form.Item>

                    <Form.Item
                        label="初始密码"
                        name="password"
                        rules={[
                        {
                            required: true,
                            message: '请输入一个初始密码！',
                        },
                        ]}
                    >
                        <Input.Password placeholder="123456"/>
                    </Form.Item>

                    <Form.Item
                        name="auth_id"
                        label="角色"
                        hasFeedback
                        rules={[{ 
                            required: true, 
                            message: '请选择角色！' 
                        }]}
                    >
                        <Select placeholder="角色">
                            {options.map(item=>{
                                return item
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            新增员工
                        </Button>
                    </Form.Item>
                </Form>
            </>
        }
    }
}

export default AddAdmin;