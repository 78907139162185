import React,{Component} from 'react';
import { Layout } from 'antd';
import TopNav from '../components/TopNav';
import Banner from '../components/about/Banner'
import Teacher from '../components/about/Teacher'
import Footer1 from '../components/Footer1';


const { Header, Content, Footer } = Layout;

class About extends Component{
    constructor(){
        super()
        this.state={
            page: [
                {
                    content: <Banner />,
                },
                {
                    content: <Teacher />,
                },
            ]
        }
    }
    render(){
        const {page} = this.state
        return <>
            <Layout>
                <Header style={{backgroundColor: 'white'}}>
                    <TopNav  menuItem={'3'}/>
                </Header>
                {
                    page.map((contents,index)=>{
                        return (
                            <Content 
                            key={index}>
                                {contents.content}
                            </Content>
                        )
                    })
                }
                <Footer style={{ textAlign: 'center', backgroundColor:'#fafafa' }}>
                    <Footer1 />
                </Footer>
            </Layout>
        </>
    }
}

export default About;