import React from 'react';
import { Table, Input, Button, Space, Tabs, message, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { ProxyGetNewOrder, ConfirmHandleNewOrder } from '../../request/axios';
import { GlobalContext } from '../../request/context';
import { AlertMsg } from '../PublicMethods';
import ArrangeOrder from './ArrangeOrder';
import ViewProxyOrderSheet from './ViewProxyOrderSheet';
const { TabPane } = Tabs;
const { Provider } = GlobalContext;

class SearchableTable extends React.Component {
	state = {
	  searchText: '',
	  searchedColumn: '',
	};
	
	constructor(props){
		super()
	}

	getColumnSearchProps = dataIndex => ({
	  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		<div style={{ padding: 8 }}>
		  <Input
			ref={node => {
			  this.searchInput = node;
			}}
			placeholder={`搜索 ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ width: 188, marginBottom: 8, display: 'block' }}
		  />
		  <Space>
			<Button
			  type="primary"
			  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  icon={<SearchOutlined />}
			  size="small"
			  style={{ width: 90 }}
			>
			  搜索
			</Button>
			<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  重置
			</Button>
		  </Space>
		</div>
	  ),
	  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	  onFilter: (value, record) =>
		record[dataIndex]
		  ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		  : '',
	  onFilterDropdownVisibleChange: visible => {
		if (visible) {
		  setTimeout(() => this.searchInput.select(), 100);
		}
	  },
	  render: text =>
		this.state.searchedColumn === dataIndex ? (
		  <Highlighter
			highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			searchWords={[this.state.searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
		  />
		) : (
		  text
		),
	});
  
	handleSearch = (selectedKeys, confirm, dataIndex) => {
	  confirm();
	  this.setState({
		searchText: selectedKeys[0],
		searchedColumn: dataIndex,
	  });
	};
  
	handleReset = clearFilters => {
	  clearFilters();
	  this.setState({ searchText: '' });
	};
  
	render() {
		const columns = [
            {
				title: '姓名',
				dataIndex: 'username',
				key: 'username',
				...this.getColumnSearchProps('username'),
            },
            {
				title: '学号',
				dataIndex: 'student_num',
				key: 'student_num',
				...this.getColumnSearchProps('student_num'),
            },
			{
				title: '课程名称',
				dataIndex: 'name',
				key: 'name',
				...this.getColumnSearchProps('name'),
            },
			{
				title: '课程代码',
				dataIndex: 'subject_code',
				key: 'subject_code',
				...this.getColumnSearchProps('subject_code'),
			},
			{
				title: '教师姓名',
				dataIndex: 'tusername',
				key: 'tusername',
				...this.getColumnSearchProps('tusername'),
            },
			{
				title: '教师工号',
				dataIndex: 'teacher_num',
				key: 'teacher_num',
				...this.getColumnSearchProps('teacher_num'),
			},
			{
				title: '上课周期时间',
				dataIndex: 'taken_time',
				key: 'taken_time',
				align:'center'
            },
			{
				title: '预约节数',
				dataIndex: 'order_amount',
				key: 'order_amount',
				align:'center'
            },
            {
				title: '状态',
				dataIndex: 'is_handle',
				key: 'is_handle',
				render: (text,record) =>{
                    if(text === 1){
                        return '已处理'
                    }else{
                        return <>
							<Space direction="vertical">
								<Button type='primary' onClick={()=>this.props.func(record)}>
									信息复制
								</Button>
								<Button type='primary' onClick={()=>this.context.setRead(record.order_id)}>
									标为已处理
								</Button>
							</Space>
                        </>
                    }
                },
				align:'center'
			}
        ];
	  	return <Table bordered columns={columns} dataSource={this.context.data} pagination={{ pageSize: 20 }} size="middle"/>;
	}
}

SearchableTable.contextType=GlobalContext

class ViewNewOrder extends React.Component {
	state = {
		record: [],
		data: null,
		loading:true
	}

	componentDidMount(){
		this.loadData()
	}

	changeTab(record){
		this.setState({
			record,
		})
		message.success('信息已复制！请前往“安排课程”页面')
	}
	
	setRead(order_id){
        ConfirmHandleNewOrder({admin_id:sessionStorage.getItem('admin_id'), order_id:order_id},request_res=>{
            const {data:{status,tips}} = request_res
            if(status!==100){
                AlertMsg({message:"查询学生数据出错",des:tips})
            }else{
				message.success('成功设置成已处理！')
				this.loadData()
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
	}
	
	loadData(){
		ProxyGetNewOrder({admin_id:sessionStorage.getItem('admin_id')},request_res=>{
            const {data:{result,status,tips="查询错误"}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"查询预约数据出错"})
            }else{
				result.forEach((el,index)=>{
                    el.key = index
				})
                this.setState({
					data: result,
					loading:false
				})
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
	}

    render(){
		if(this.state.loading){
            return <Spin size="large"/>
        }
        return <>
			<Provider value={{record:this.state.record,setRead:this.setRead.bind(this),data:this.state.data}}>
				<Tabs defaultActiveKey="1" type="card" centered>
					<TabPane tab="预约请求" key="1">
						<SearchableTable func={this.changeTab.bind(this)}/>
					</TabPane>
					<TabPane tab="安排课程" key="2">
						<ArrangeOrder />
					</TabPane>
					<TabPane tab="安排课程记录" key="3">
						<ViewProxyOrderSheet />
					</TabPane>
				</Tabs>
			</Provider>
        </>
    }
}

export default ViewNewOrder;