import React, { Component } from 'react';
import '../../style/components/course/timetable.css'
import { Tooltip,Modal, message } from 'antd';
import { getAssignCourse,getStudentTime,getCourseTime } from '../../request/axios'
import { AlertMsg, SplitENCN, TransText,DateFormat } from '../PublicMethods';
import { GlobalContext } from '../../request/context';

class TimeTable extends Component {
    constructor() {
        super()
        this.state = {
            colorBoard:[],
            sub_id:0,
            // 上方日期刻度
            date: [
                TransText({CN:"周一",EN:"Mon"}),
                TransText({CN:"周二",EN:"Tue"}),
                TransText({CN:"周三",EN:"Wed"}),
                TransText({CN:"周四",EN:"Thu"}),
                TransText({CN:"周五",EN:"Fri"}),
                TransText({CN:"周六",EN:"Sat"}),
                TransText({CN:"周日",EN:"Sun"})],
            // 左侧时间刻度
            time_array: [],
            // 时间表index(84)
            week_time: [],
            // {时间:课程}键值对，在时间表上渲染课程
            time_data:[],
            // 老师的课程信息，可修改
            course_info: [],
            // 检查时间选择冲突
            conflict: [],
            // 学生相应课程可用老师列表
            teachers:[],
            // 选择老师的index
            teacher: -1,
            //选择课程的index
            course: -2,
            //学生预约时间
            order_time:[[],[]],
            isModalVisible:false
        }
    }

    componentDidMount() {
        this.loadData()
    }

    loadData(){
        const { t_id, time_data,s_id } = this.props
        const {sub_id} = this.state
        let t_array = []
        let time_array = new Set()
        let daily = new Date()
        // 调用老师当前课程接口
        if (t_id) {
            getAssignCourse({ t_id, }, (data) => {
                let { data: { result, status } } = data
                if (status !== 100) {
                    AlertMsg({ message: "Get course error", des: "Cannot get the course", type: 'warning' })
                } else {
                    this.setState({
                        course_info: result
                    })
                }
            })
        }
        if(s_id){
            // 调用学生已选课程接口
            getStudentTime({s_id,}, (data) => {
                let { data: { result, status,tips } } = data
                if (status !== 100) {
                    AlertMsg({ message: "Course Error", des: tips })
                    return
                }
                let timedata = {}
                result.forEach(el=>{
                    let {time_index,lesson_time,name,code,username,duration} = el
                    timedata[time_index] = {
                        name:SplitENCN(name).CN,
                        code,
                        time:parseInt(lesson_time),
                        username,
                        duration,
                    }
                })
                for (let i = 0; i < 7 * 16; i++) {
                    time_array.add(DateFormat("H:m",this.setTime(i, daily)))
                    t_array.push({
                        index: i,
                        selected: false,
                        taken:false,
                        element: {
                            first:timedata[i+""] ?  <this.courseBlock {...timedata[i]} /> : <></>,
                            second:timedata[i+".5"] ?  <this.courseBlock {...timedata[i+".5"]} /> : <></>
                        }
                    })
                }
                this.setState({
                    week_time: t_array,
                    time_array,
                })
            }).catch(error=>console.log(error))
        }else{
            if(sub_id){
                getCourseTime({sub_id,},data=>{
                    const {data:{result,tips,status}} = data
                    if(status!==100){
                        AlertMsg({
                            message:'获取课程出错',
                            des:tips,
                            type:'error'
                        })
                        return
                    }
                    this.setState({
                        teachers:result,
                    })
                }).catch(err=>{
                    console.log(err)
                })
            }
            // 渲染时间表
            for (let i = 0; i < 7 * 16; i++) {
                time_array.add(DateFormat("H:m",this.setTime(i, daily)))
                t_array.push({
                    index: i,
                    selected_st: false,
                    selected_nd: false,
                    element: {
                        first:(time_data&&time_data[i+""] 
                        ? 
                        <this.courseBlock {...time_data[i]} /> 
                        : 
                        <></>
                        ),
                        second:(time_data&&time_data[i+".5"] 
                            ? 
                            <this.courseBlock {...time_data[i+".5"]} /> 
                            : 
                            <></>
                            )
                        }
                })
            }
            this.setState({
                week_time: t_array,
                time_array,
            })
        }
    }

    // 课程信息在时间块中的渲染
    courseBlock=(props)=>{
        return (
        <Tooltip 
        placement="topRight"
        title={<div className='tool-tips'><span style={{fontSize:"1.3em",fontWeight:"bolder"}}>{DateFormat("M-D H:m",props.time)}</span><br />{props.name}<br />{props.username}</div>}>
            <div 
                data-duration={props.duration>"30"?`${props.duration}${TransText({CN:" 分钟",EN:" min"})}`:""}
                className={`lesson-block ${props.duration>"30"?"block-two":""}`}>
                <span>{props.code}</span>
            </div>
            </Tooltip>
        )
    }

    setTime(time_index, daily) {
        const oneDay = 24 * 60 * 60 * 1000
        let day = daily.getDay() || 7
        let d = Math.floor(time_index / 16) + 1
        let hours = time_index % 16
        let targetDay = new Date(daily.getTime() + (d - day) * oneDay)
        //设置开始时间
        return new Date(targetDay.setHours(new Date(1607464800000).getHours()+hours, 0, 0, 0))
    }

    // 学生选择老师并显示老师时间
    handleSelectTeacherTime(index){
        let [...week_time] = this.state.week_time
        const {teachers} = this.state
        if(!teachers[index].available_time){
            AlertMsg({message:'空时间',des:'这个老师还未分配时间',type:'info'})
            return
        }
        let timeArray = teachers[index].available_time.split(',')
        let timeTaken = teachers[index].taken_time.split(',') || []
        week_time.forEach((el, index) => {
            el.taken_st = timeTaken.includes(index+"")
            el.taken_nd = timeTaken.includes(index+".5")
            el.selected_st = timeArray.includes(index+"")
            el.selected_nd = timeArray.includes(index+".5")
        })
        this.setState({
            week_time,
            teacher: index
        })
    }

    // 老师选择课程显示相应的时间
    handleSelectCourseTime(index) {
        const [...course_info] = this.state.course_info
        let colorBoard = []
        let [...week_time] = this.state.week_time
        if(index===-1){
            colorBoard = [
                "#1890ff",
                "#389e0d",
                "#13c2c2",
                "#9254de",
                "#eb2f96",
                "#262626",
                "#ffec3d",
                "#ff4d4f",
                "#ffe7ba"
            ]
            let check = true
            week_time.forEach((el, index) => {
                course_info.forEach((course,c_index)=>{
                    if(course.available_time.includes(index+"")){
                        check = false
                        el.element.first = <Tooltip 
                            placement="topRight"
                            title={SplitENCN(course.name).EN}><div 
                            className='lesson-block'>
                            <span style={{color:colorBoard[c_index%colorBoard.length]}}>{course.subject_code}</span>
                        </div>
                        </Tooltip>
                    }
                    if(course.available_time.includes(index+".5")){
                        check = false
                        el.element.second = <Tooltip 
                            placement="topRight"
                            title={SplitENCN(course.name).EN}><div 
                            className='lesson-block'>
                            <span style={{color:colorBoard[c_index%colorBoard.length]}}>{course.subject_code}</span>
                        </div>
                        </Tooltip>
                    }
                })
            })
            if(check) this.setState({isModalVisible:true})
        }else{
            const [...course_time] = course_info[index].available_time
            week_time.forEach((el, index) => {
                el.element = {first:<></>,second:<></>}
                el.selected_st = course_time.includes(index+"")
                el.selected_nd = course_time.includes(index+".5")
            })
        }
        this.setState({
            week_time,
            course: index,
            colorBoard,
        })
    }

    // 点击时间块事件
    handleTimeSelected(index,float) {
        let [...week_time] = this.state.week_time
        if(this.props.type==='teacher'){
            const { course } = this.state
            let [...course_info] = this.state.course_info
            if (course < 0 && !this.props.time_data) {
                this.setState({isModalVisible:true})
                return
            }
            if(!this.props.time_data){
                if(float){
                    week_time[index].selected_nd = !week_time[index].selected_nd
                }else{
                    week_time[index].selected_st = !week_time[index].selected_st
                }
                let array = course_info[course].available_time
                if (array.includes(index+float+"")) {
                    array.splice(array.indexOf(index+float+""), 1)
                } else {
                    array.push(index+float+"")
                }
                this.setState({
                    week_time,
                    course_info,
                })
            }
        }else{
            if(float&&!week_time[index].selected_nd) return
            if(!float&&!week_time[index].selected_st) return
            if((!float&&week_time[index].taken_st) 
                || (float&&week_time[index].taken_nd)){
                message.warning("此时间段已被其他学生选择")
                return
            }
            if(this.props.duration>"30"){
                if((!float&&week_time[index].taken_nd)
                || (float&&week_time[index+1].taken_st)){
                    message.warning("上课时间部分被占用")
                    return
                }
            }
            let [...order_time] = this.state.order_time
            if(order_time[0].includes(index+float+"") || order_time[1].includes(index+float+"") ){
                let delete_index = order_time[0].indexOf(index+float+"")>-1
                ?
                order_time[0].indexOf(index+float+"")
                :
                order_time[1].indexOf(index+float+"")

                order_time[0].splice(delete_index, 1)
                order_time[1].splice(delete_index, 1)
            }else{
                if(this.props.duration>"30"){
                    if((!float&&week_time[index].selected_nd) || (float&&week_time[index+1].selected_st)){
                        if(float&&index%16===15){
                            message.warning("上课时间部分被占用")
                            return
                        }
                        if(order_time[0].includes(index+float+0.5+"")){
                            message.warning("上课时间部分被占用")
                            return
                        }
                        order_time[0].push(index+float+"")
                        order_time[1].push(index+float+0.5+"")
                    }else{
                        message.warning("请选择老师的可用时间")
                    }
                }else{
                    order_time[0].push(index+float+"")
                }
            }
            this.setState({order_time,})
            return
        }
    }

    checkconflict() {
        let conflict = []
        let record = []
        const [...course_info] = this.state.course_info
        course_info.forEach(el => {
            let [...available_time] = el.available_time
            available_time.forEach(t => {
                if(t){
                    if (record.includes(t)) {
                        conflict.push(t)
                    } else {
                        record.push(t)
                    }
                }
            })
        })
        this.setState({
            conflict,
        })
        return conflict.length
    }

    renderProgress(){
        return (
            <div className="progress-bar">
                <div className="progress-bar-content">
                    <div>
                        Step 1
                    </div>
                    <div>
                        Select a subject to edit available time.
                    </div>
                </div>
                <div className="progress-bar-content">
                    <div>
                        Step 2
                    </div>
                    <div>
                        Click on time blocks below to select or de-select time slots.
                    </div>
                </div>
                <div className="progress-bar-content">
                    <div>
                        Step 3
                    </div>
                    <div>
                        Edit other subjects or click on 'Confirm' to submit!
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { week_time, date, time_array,teachers,teacher,isModalVisible,
             course_info, course, conflict,order_time,sub_id,colorBoard } = this.state
        const { type = 'student', width = '100%', getTime } = this.props
        return (
            <div id='timetable' style={{ width, }}>
                <Modal
                    title="Set Avalaible Time"
                    visible={isModalVisible}
                    onOk={()=>this.setState({isModalVisible:false})}
                    onCancel={()=>this.setState({isModalVisible:false})}
                >
                    {this.renderProgress()}
                </Modal>
                {
                    type==='student'?
                        teachers.length ?
                        <div className='setup-area'>
                        <h3>可选老师</h3>
                        <div className='setup-content'>
                        {
                            teachers.map((t,index)=>{
                                return (
                                    <span
                                        style={teacher === index ? { color: '#f34e3a' } : {}}
                                        onClick={() => this.handleSelectTeacherTime(index)}
                                        data-des={t.description}
                                        key={index}>
                                        {t.username}
                                    </span>
                                )
                            })
                        }
                        </div>
                        </div>
                        :
                        sub_id?
                        <h3>该课程没有安排老师,请与客服联系</h3>
                        :
                        <></>
                    :
                    course_info.length?
                        <div className='setup-area'>
                        <h3>Time Select</h3>
                        <div className='setup-content'>
                            <span
                                style={course === -1 ? { color: '#f34e3a'} : {}}
                                onClick={() => this.handleSelectCourseTime(-1)}
                                data-des="Display all avalable time"
                                >
                                    ALL SUBJECT
                            </span>
                        {
                            course_info.map((c, index) => {
                                return (
                                    <span
                                        style={course === index ? { color: '#f34e3a'} : colorBoard.length?{color:colorBoard[index]}:{}}
                                        onClick={() => this.handleSelectCourseTime(index)}
                                        data-des={SplitENCN(c.name).EN}
                                        key={c.sub_id}>
                                        {c.subject_code}
                                    </span>
                                )
                            })
                        }
                        </div>
                        </div>
                        :
                        <></>
                }
                <h1>{
                sub_id
                ?
                "选择预约时间"
                :
                <TransText CN='时间表' EN='Timetable' />
                }</h1>
                <div className='timetable-content' style={{ height: 64 }}>
                    <div style={{ width: 86 }}></div>
                    <div className='timetable-header'>
                        {
                            date.map((d, index) => {
                                return (
                                    <span key={index}>{d}</span>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='timetable-content'>
                    <div className='time-scale'>
                        {
                            Array.from(time_array).map((t, index) => {
                                return <span key={index}>
                                    {t}
                                </span>
                            })
                        }
                    </div>
                    <div className={`timetable-body`}>
                        {
                            week_time.map((time_block, index) => {
                                return (
                                    <div 
                                        // onClick={this.handleTimeSelected.bind(this,index)}
                                        key={index}
                                        className={`time-block ${getTime?`block-hover`:""}
                                            `}>
                                                {
                                                [0,0.5].map((f,i)=>{
                                                    return <div
                                                    key={i}
                                                    className={
                                                        `time-block${(!f?time_block.selected_st:time_block.selected_nd) ? "-selected" : '-none'} ${conflict.includes(index+f+"") ? "time-conflict" : ""} 
                                                        ${order_time[0].includes(index+f+"") || order_time[1].includes(index+f+"")?'time-block-picked':''} ${(!f?time_block.taken_st:time_block.taken_nd)? "time-block-taken":""}`
                                                    }
                                                    onClick={this.handleTimeSelected.bind(this,index,f)}
                                                    >{!f?time_block.element.first:time_block.element.second}</div>
                                                })
                                                }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {
                    type==='student'?
                        teachers.length ?
                        <div className='setup-area'>
                        <div className='setup-content'>
                            <button onClick={() => {
                                getTime({
                                        order_time:order_time[0],
                                        t_id:teachers[teacher] && teachers[teacher].t_id
                                    })
                            }}>确定预约</button>
                            <button onClick={() => {
                                this.setState({
                                    order_time:[],
                                })
                            }}>取消</button>
                        </div>
                        </div>
                        :
                        <></>
                    :
                    course_info.length?
                    <div className='setup-area'>
                        <div className='setup-content'>
                            <button onClick={() => {
                                if (this.checkconflict()) {
                                    AlertMsg({ message: "Conflict Error", des: 'There exist subjects have the same time!' })
                                    return
                                }
                                if (getTime) {
                                    getTime(course_info)
                                }
                            }}>{TransText({CN:"确认",EN:"Confirm"})}</button>
                        </div>
                    </div>
                    :
                    <></>
                }
            </div>
        )
    }
}

TimeTable.contextType = GlobalContext

export default TimeTable