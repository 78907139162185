import React from 'react';
import { Table, Input, Button, Space, Spin, Divider, Tabs, Popconfirm, message } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { AdminGetTeacherSheet, axiosAll, AdminConfirmPayTeacher } from '../../request/axios';
import { AlertMsg, DateFormat } from '../PublicMethods'

const { TabPane } = Tabs

class SearchableTable extends React.Component {
	
	constructor(){
		super()

		this.state = {
			searchText: '',
			searchedColumn: '',
			data: [],
			summary: [],
			loading:true,
			start:null,
			end:null
		};
	}

	loadData(start, end){
		axiosAll([
			AdminGetTeacherSheet({admin_id:sessionStorage.getItem('admin_id'),start,end}),
			AdminGetTeacherSheet({admin_id:sessionStorage.getItem('admin_id'),start,end,state:true})
		],results=>{
			if(results[0].data.status!==100){
				AlertMsg({message:'数据错误1',des:results[0].data.tips})
				return
			}
			results[0].data.result.forEach((el,index)=>{
				el.key = index
			})

			if(results[1].data.status!==100){
				AlertMsg({message:'数据错误2',des:results[1].data.tips})
				return
			}
			let summary_unpaid = new Map()
			let summary_paid = new Map()
			results[1].data.result.forEach((el,index)=>{
				el.key = index
				if(el.state === 2){
					if(!summary_unpaid.has(`${el.teacher_num},${el.s_id}`)){
						summary_unpaid.set(`${el.teacher_num},${el.s_id}`, {'key':index,'salary':el.salary,'amplitude':el.amplitude,'t_id':el.t_id,'teacher_num':el.teacher_num,'username':el.username,'sname':el.sname,'s_id':el.s_id,'payment':(el.salary+el.amplitude)*el.total})
					}else{
						summary_unpaid.get(`${el.teacher_num},${el.s_id}`).payment += (el.salary+el.amplitude)*el.total
					}
				}else{
					if(!summary_paid.has(el.teacher_num)){
						summary_paid.set(el.teacher_num, {'key':index,'t_id':el.t_id,'teacher_num':el.teacher_num,'username':el.username,'payment':(el.salary+el.amplitude)*el.total})
					}else{
						summary_paid.get(el.teacher_num).payment += (el.salary+el.amplitude)*el.total
					}
				}
			})
			this.setState({
				data:results[0].data.result,
				start,
				end,
				summary_unpaid:[...summary_unpaid.values()],
				summary_paid:[...summary_paid.values()],
				loading:false,
			})
		},err=>{
			AlertMsg({message:'网络错误',des:'网络连接出错'})
			console.log(err)
		})
	}

	componentDidMount(){
		var now = new Date()
		now.setHours(0,0,0,0)
		var d = now.getDay() - 1
		if(d<0){
			d = 6
		}
		var start = now - d*1000*60*60*24
		var end = start + 7*1000*60*60*24 - 1
		this.loadData(start,end)
	}
    
	move(day){
		this.setState({
			loading:true
		})
		const {start, end} = this.state
		this.loadData(start + day*1000*60*60*24, end + day*1000*60*60*24)
	}

	confirm = (t_id, payment,am,sa,s_id) => {
		const {start, end} = this.state
		let pay_teacher = sa + am
		AdminConfirmPayTeacher({admin_id:sessionStorage.getItem('admin_id'),t_id,start,end,payment,pay_teacher,s_id}).then(data=>{
			const {data:{status,tips}} = data
			if(status!==100){
				AlertMsg({message:'数据错误3',des:tips})
				return
			}else{
                message.success('成功标为已结算！')
                this.loadData(start, end)
            }
		}).catch(err=>{
			AlertMsg({message:'网络错误',des:'网络连接出错'})
			console.log(err)
		})
    }

	getColumnSearchProps = dataIndex => ({
	  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		<div style={{ padding: 8 }}>
		  <Input
			ref={node => {
			  this.searchInput = node;
			}}
			placeholder={`搜索 ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ width: 188, marginBottom: 8, display: 'block' }}
		  />
		  <Space>
			<Button
			  type="primary"
			  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  icon={<SearchOutlined />}
			  size="small"
			  style={{ width: 90 }}
			>
			  搜索
			</Button>
			<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  重置
			</Button>
		  </Space>
		</div>
	  ),
	  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	  onFilter: (value, record) =>
		record[dataIndex]
		  ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		  : '',
	  onFilterDropdownVisibleChange: visible => {
		if (visible) {
		  setTimeout(() => this.searchInput.select(), 100);
		}
	  },
	  render: text =>
		this.state.searchedColumn === dataIndex ? (
		  <Highlighter
			highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			searchWords={[this.state.searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
		  />
		) : (
		  text
		),
	});
  
	handleSearch = (selectedKeys, confirm, dataIndex) => {
	  confirm();
	  this.setState({
		searchText: selectedKeys[0],
		searchedColumn: dataIndex,
	  });
	};
  
	handleReset = clearFilters => {
	  clearFilters();
	  this.setState({ searchText: '' });
	};
  
	render() {
		if(this.state.loading){
            return <Spin size="large"/>
        }
		const columns = [
            {
				title: '教师工号',
				dataIndex: 'teacher_num',
                key: 'teacher_num',
                width: 120,
				...this.getColumnSearchProps('teacher_num'),
            },
            {
				title: '教师姓名',
				dataIndex: 'username',
                key: 'username',
                width: 200,
				...this.getColumnSearchProps('username'),
            },
            {
				title: '学生姓名',
				dataIndex: 'sname',
                key: 'sname',
                width: 120,
				...this.getColumnSearchProps('sname'),
            },
            {
				title: '课程代码',
				dataIndex: 'subject_code',
                key: 'subject_code',
                width: 120,
				...this.getColumnSearchProps('subject_code'),
            },
            {
				title: '名称',
				dataIndex: 'name',
                key: 'name',
                width: 200,
				...this.getColumnSearchProps('name'),
            },
            {
				title: '课时数',
				dataIndex: 'total',
                key: 'total',
                width: 100,
				align:'center'
            },
            {
				title: '节薪/aud',
				dataIndex: 'salary',
                key: 'salary',
                width: 80,
				align:'center'
            },
            {
				title: '学生振幅(aud)',
				dataIndex: 'amplitude',
                key: 'amplitude',
                width: 80,
				align:'center'
            },
            {
				title: '小计',
				dataIndex: 'sum',
                key: 'sum',
                width: 80,
				align:'center',
				render: (text, record) =>{
					return `${(record.amplitude+record.salary)*record.total} aud`
				}
            },
        ];
		const columns2 = [
            {
				title: '教师工号',
				dataIndex: 'teacher_num',
                key: 'teacher_num',
				...this.getColumnSearchProps('teacher_num'),
            },
            {
				title: '教师姓名',
				dataIndex: 'username',
                key: 'username',
				...this.getColumnSearchProps('username'),
            },
            {
				title: '学生姓名',
				dataIndex: 'sname',
                key: 'sname',
				...this.getColumnSearchProps('sname'),
            },
            {
				title: '总计(aud)',
				dataIndex: 'payment',
                key: 'payment',
				align:'center',
				render: text=>{
					return `${text} aud`
				}
            },
            {
                title: '操作',
                render: (text, record) =>{
					return <>
						<Popconfirm
							title="你确定要标记为已结算吗？谨慎操作！"
							onConfirm={()=>this.confirm(record.t_id, record.payment,record.amplitude,record.salary,record.s_id)}
							onCancel={this.cancel}
							okText="Yes"
							cancelText="No"
							style={{textAlign:'left'}}
							icon={null}
						>
							<a href="/admin">标为已结算</a>
						</Popconfirm>
					</>
                },
				align:'center',
				width:100
            },
        ];
		const columns3 = [
            {
				title: '教师工号',
				dataIndex: 'teacher_num',
                key: 'teacher_num',
				...this.getColumnSearchProps('teacher_num'),
            },
            {
				title: '教师姓名',
				dataIndex: 'username',
                key: 'username',
				...this.getColumnSearchProps('username'),
            },
            {
				title: '总计(aud)',
				dataIndex: 'payment',
                key: 'payment',
				align:'center',
				render: text=>{
					return `${text} aud`
				}
            },
        ];
	  	return <>
		  	<h2><b>教师结算</b></h2>
		  	<h2>
				<Button type='primary' onClick={()=>this.move(-7)}>上一周</Button>
					<Divider type="vertical" style={{height:'20px'}}/>
				<b>{DateFormat("Y/M/D",this.state.start)}--{DateFormat("Y/M/D",this.state.end)}</b>
					<Divider type="vertical" style={{height:'20px'}}/>
				<Button type='primary' onClick={()=>this.move(7)}>下一周</Button>
			</h2>
			<Divider/>
		  	<h2><b>总计</b></h2>
			<Tabs defaultActiveKey="1" type="card" centered>
				<TabPane tab="未结算" key="1">
					<Table bordered columns={columns2} dataSource={this.state.summary_unpaid} 
						pagination={{ pageSize: 20 }}
						size="middle"
					/>
				</TabPane>
				<TabPane tab="已结算" key="2">
					<Table bordered columns={columns3} dataSource={this.state.summary_paid} 
						pagination={{ pageSize: 20 }}
						size="middle"
					/>
				</TabPane>
			</Tabs>
			<Divider/>
		  	<h2><b>明细</b></h2>
			<Table bordered columns={columns} dataSource={this.state.data} 
				pagination={{ pageSize: 20 }}
				size="middle"
			/>
		</>;
	}
}

class ViewTeacherSheet extends React.Component {
    render(){
        return <>
            <SearchableTable />
        </>
    }
}

export default ViewTeacherSheet;