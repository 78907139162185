import React from 'react';
import { Drawer,Tooltip,Badge  } from 'antd';
import {GlobalContext} from '../../request/context'

class MessageNotifier extends React.Component {
  state = { 
      visible: false, 
      childrenDrawer: false,
      select:0,
    };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  showChildrenDrawer = (index) => {
    this.setState({
      childrenDrawer: true,
      select:index
    });
  };

  onChildrenDrawerClose = () => {
    this.setState({
      childrenDrawer: false,
    });
  };

  render() {
      const {visible,childrenDrawer,select} = this.state
      const messageList = this.context.message_list
      const nextList = messageList[select].children
      const cateInfo = new Map([
        [0,"新的学生"],
        [1,"新的课程预定"],
        [2,"新的课程动态"],
        [3,"新的问询"],
      ])
      const q_typename = new Map([
        ["0","调整课程进度"],
        ["1","课程教学建议"],
        ["2","课程续费"],
      ])
    return (
      <>
        <svg onClick={this.showDrawer} viewBox="0 0 1024 1024" id='message-notifier'>
          <path d="M560.64 867.328c-13.824 12.288-29.696 18.432-48.128 18.432s-34.816-6.144-48.64-17.92c-13.824-12.288-20.992-28.672-20.992-50.688 0-18.944 6.656-34.816 19.968-48.128 13.312-13.312 29.696-19.968 48.64-19.968 19.456 0 35.84 6.656 49.152 19.968 13.824 13.312 20.48 29.184 20.48 48.128 0 20.992-6.656 37.888-20.48 50.176zM445.44 605.696l-34.304-269.312c0-51.2 44.032-93.184 97.28-93.184 53.76 0 97.28 41.984 97.28 93.184l-34.304 269.312c-17.408 138.752-113.152 132.096-125.952 0z" fill="#fff768"></path>
        </svg>
        <Drawer
          title="您有新的消息"
          width={320}
          closable={false}
          onClose={this.onClose}
          visible={visible}
        >
        <div style={{display:"flex",flexFlow:'column nowrap'}}>
            {
            messageList.map((msg,index)=>{
                return <Badge count={msg.children.length}>
                  <div className={`info-bar${msg.new?" info-bar-new":''}`} 
                  key={index} onClick={()=>this.showChildrenDrawer(index)}>
                        {cateInfo.get(msg.type)}
                    </div></Badge>
            })
            }
        </div>
          <Drawer
          title={cateInfo.get(messageList[select].type)}
          width={320}
          closable={false}
          onClose={this.onChildrenDrawerClose}
          visible={childrenDrawer}
          >
          {
              nextList.map((el,index)=>{
                  return <Tooltip placement="topLeft" title="点击删除"><div key={index} 
                  className = 'message-card'
                  onClick={()=>this.context.remove(select,index)}>
                    {el.username&&<span>学生: {el.username}</span>}
                    {el.student_num&&<span>学号: {el.student_num}</span>}
                    {el.message&&<span>留言: {el.message}</span>}
                    {el.q_type&&<span>问题类型: {q_typename.get(el.q_type+"")}</span>}
                    {el.remark&&<span>用户备注: {el.remark}</span>}
                  </div>
                  </Tooltip>
              })
          }
          </Drawer>
        </Drawer>
      </>
    );
  }
}
MessageNotifier.contextType = GlobalContext

export default MessageNotifier;