import React from 'react';
import { Carousel } from 'antd';

const contentStyle1 = {
  background:'url(images/static/banner/banner1.jpg) no-repeat center',
  backgroundSize:"100%"
}

const contentStyle2 = {
  background:'url(images/static/banner/banner2.jpg) no-repeat center',
  backgroundSize:"100%"
}

const contentStyle3 = {
  background:'url(images/static/banner/banner3.jpg) no-repeat center',
  backgroundSize:"100%"
}

class Content1 extends React.Component {
  render() {
    return <>
      <Carousel 
				autoplay 
        autoplaySpeed = "50"
        easing='easy-in'
        style={{paddingTop:2}}
			>
        <div>
          <div style={contentStyle1} className='carousel-content' onClick={()=>window.location.href="/register"}></div>
        </div>
        <div>
          <div style={contentStyle2} className='carousel-content' onClick={()=>window.location.href="/register"}></div>
        </div>
        <div>
          <div style={contentStyle3} className='carousel-content' onClick={()=>window.location.href="/register"}></div>
        </div>
      </Carousel>
		</>;
  }
}

export default Content1;