import React from 'react';
import { Table, Input, Button, Space, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { AdminGetDashboard } from '../../request/axios';
import { AlertMsg, DateFormat } from '../PublicMethods';

class SearchableTable extends React.Component {
	state = {
	  searchText: '',
	  searchedColumn: '',
	  data: [],
	  loading:true
	};

	loadData(){
		AdminGetDashboard({admin_id:sessionStorage.getItem('admin_id')},request_res=>{
            const {data:{result,status,tips="查询错误"}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"查询课程数据出错"})
            }else{
                let new_data = []
                let new_map = new Map([
                    // [
                    //     'student_username || teacher_username',
                    //     {
                    //         'student_username':null,
                    //         'teacher_username':null,
                    //         'mon':[],
                    //         'tues':[],
                    //         'wed':[],
                    //         'thu':[],
                    //         'fri':[],
                    //         'sat':[],
                    //         'sun':[],
                    //         'zoom':null,
                    //     }
                    // ],
				])
                result.forEach(row=>{
					let key = row.student_username+'||'+row.teacher_username
                    if(!new_map.has(key)){
                        new_map.set(key,
                            {
								'sun':[],
								'mon':[],
                                'tues':[],
                                'wed':[],
                                'thu':[],
                                'fri':[],
                                'sat':[],
                                'student_username':row.student_username,
                                'teacher_username':row.teacher_username,
                                'zoom':row.zoom,
                            }
                        )
                    }
					let day = new Date(parseInt(row.lesson_time)).getDay()
					let display_time = DateFormat('H:m',parseInt(row.lesson_time))+' - '+DateFormat('H:m',parseInt(row.lesson_time)+1000*60*60)
					new_map.get(key)[Object.keys(new_map.get(key))[day]].push(display_time)
				})
                new_map.forEach((row,index)=>{
                    row.key = index
					new_data.push(row)
				})
                this.setState({
                    loading:false,
                    data: new_data
				})
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
	}

	componentDidMount(){
		this.loadData()
	}
    
	getColumnSearchProps = dataIndex => ({
	  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		<div style={{ padding: 8 }}>
		  <Input
			ref={node => {
			  this.searchInput = node;
			}}
			placeholder={`搜索 ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ width: 188, marginBottom: 8, display: 'block' }}
		  />
		  <Space>
			<Button
			  type="primary"
			  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  icon={<SearchOutlined />}
			  size="small"
			  style={{ width: 90 }}
			>
			  搜索
			</Button>
			<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  重置
			</Button>
		  </Space>
		</div>
	  ),
	  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	  onFilter: (value, record) =>
		record[dataIndex]
		  ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		  : '',
	  onFilterDropdownVisibleChange: visible => {
		if (visible) {
		  setTimeout(() => this.searchInput.select(), 100);
		}
	  },
	  render: text =>
		this.state.searchedColumn === dataIndex ? (
		  <Highlighter
			highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			searchWords={[this.state.searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
		  />
		) : (
		  text
		),
	});
  
	handleSearch = (selectedKeys, confirm, dataIndex) => {
	  confirm();
	  this.setState({
		searchText: selectedKeys[0],
		searchedColumn: dataIndex,
	  });
	};
  
	handleReset = clearFilters => {
	  clearFilters();
	  this.setState({ searchText: '' });
	};
  
	render() {
		if(this.state.loading){
            return <Spin size="large"/>
		}
		const renderTimePeriod=(text)=>{
			return text.map((period,index)=>{
				return <div key={index} style={{
					whiteSpace:"nowrap",
					borderBottom:text.length>1?"1px solid #dddddd":"none"
				}}>{period}</div>
			})
		}
		const columns = [
            {
				title: '学生',
				dataIndex: 'student_username',
				key: 'student_username',
				...this.getColumnSearchProps('username'),
            },
            {
				title: '教师',
				dataIndex: 'teacher_username',
				key: 'teacher_username',
				...this.getColumnSearchProps('teacher_username'),
            },
			{
				title: 'Mon',
				dataIndex: 'mon',
				key: 'mon',
                width: 150,
				align:'center',
				render:renderTimePeriod
            },
			{
				title: 'Tues',
				dataIndex: 'tues',
				key: 'tues',
				width: 150,
                align:'center',
				render:renderTimePeriod
            },
			{
				title: 'Wed',
				dataIndex: 'wed',
				key: 'wed',
				width: 150,
                align:'center',
				render:renderTimePeriod
            },
			{
				title: 'Thu',
				dataIndex: 'thu',
				key: 'thu',
				width: 150,
                align:'center',
				render:renderTimePeriod
            },
			{
				title: 'Fri',
				dataIndex: 'fri',
				key: 'fri',
				width: 150,
                align:'center',
				render:renderTimePeriod
            },
			{
				title: 'Sat',
				dataIndex: 'sat',
				key: 'sat',
				width: 150,
                align:'center',
				render:renderTimePeriod
            },
			{
				title: 'Sun',
				dataIndex: 'sun',
				key: 'sun',
				width: 150,
                align:'center',
				render:renderTimePeriod
            },
			{
				title: 'zoom',
				dataIndex: 'zoom',
				key: 'zoom',
				...this.getColumnSearchProps('zoom'),
				width:100
            },

        ];
	  	return <Table bordered columns={columns} dataSource={this.state.data} pagination={{ pageSize: 50 }} size="middle"/>;
	}
}

class Dashboard extends React.Component {

    getWeekNumber(d) {
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
        // Get first day of year
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
        // Return array of year and week number
        return [d.getUTCFullYear(), weekNo];
	}
	
	getFirstDayOfWeek (date) {
		let weekday = date.getDay() || 7
		date.setDate(date.getDate()-weekday+1)
		return date
	}

	getLastDayOfWeek (date) {
		let weekday = date.getDay() || 7
		date.setDate(date.getDate()+7-weekday)
		return date
	}

    render(){
        return <>
            <h2><b>第{this.getWeekNumber(new Date())[1]}周({DateFormat("Y/M/D",this.getFirstDayOfWeek(new Date()))}--{DateFormat("Y/M/D",this.getLastDayOfWeek(new Date()))})</b></h2>
            <SearchableTable />
        </>
    }
}

export default Dashboard;