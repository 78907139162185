import {DateFormat} from './components/PublicMethods'

let days = ["周一","周二","周三","周四","周五","周六","周日"]
const startTime = 1614290400000
const timeListArray = new Array(32).fill(1)
const halfHour = 1800000

export const WeekTimeTree = days.map((item,index)=>{
    return {
        title: item,
        value: `0-${index}`,
        key: `0-${index}`,
        children:timeListArray.map((e,i)=>{
            return {
                title: DateFormat(`${item}H:m`,startTime+i*1800000),
                value: index*16+i/2,
                // key: `0-${index}-${i}`,
            }
        })
    }
})

export const DelayTimeList = timeListArray.map((e,index)=>{
    let num = index + 1
    return {
        tag:`${halfHour*num>=3600000?~~(halfHour*num/3600000)+"小时":""}${num%2===0?"":"30分钟"}`,
        value:halfHour*num
    }
})

export const SubjectDes = new Map([
    ["course_code",{name:"course_code",span:8,order:1}],
    ["subject_code",{name:"subject_code",span:8,order:0}],
    ["duration",{name:"duration",span:8,order:2}],
    ["grade",{name:"grade",span:8,order:3}],
    ["skill",{name:"skill",span:16,order:4}],
    ["total_hour",{name:"total_hour",span:8,order:5}],
    ["difficulty",{name:"difficulty",span:16,order:6}],
    ["description",{name:"description",span:24,order:7}],
])