import React from 'react';
import { Typography, Row, Col, Divider} from 'antd';
import CountUp from 'react-countup'
import { TransText } from '../PublicMethods';

const { Title } = Typography;
const blue9 = '#003a8c';

class Banner extends React.Component {
    render() {
        return <>
            <div style={{ height:400, background:'url("images/static/about2.jpg") repeat bottom', backgroundSize:'auto'}}>
                <div style={{ backgroundColor:'rgba(0, 0, 0, 0.3)', height:'100%', width:'100%' }}>
                    <Title style={{ color:'white', paddingTop:180 }}>{TransText({'CN':'eEmu教育','EN':'eEmu Education'})}</Title>
                </div>
            </div>
            <div style={{ padding:'50px 50px', minHeight:560, backgroundColor: 'white' }}>
                <div style={{maxWidth: 1200, margin: 'auto'}}>
                <Row>
                    <Col span={5}>
                        <Title style={{ color: blue9}}><CountUp end={200} duration={5} />+</Title>
                        <h2 style={{ color: blue9 }}>{TransText({'CN':'在读学生','EN':'Students'})}</h2>
                    </Col>
                    <Col span={1}><Divider type="vertical" style={{height:'100%'}}/></Col>
                    <Col span={5}>
                        <Title style={{ color: blue9}}><CountUp end={120} duration={5} />+</Title>
                        <h2 style={{ color: blue9 }}>{TransText({'CN':'教师资源','EN':'Teachers'})}</h2>
                    </Col>
                    <Col span={1}><Divider type="vertical" style={{height:'100%'}}/></Col>
                    <Col span={5}>
                        <Title style={{ color: blue9}}><CountUp end={5} duration={5} /></Title>
                        <h2 style={{ color: blue9 }}>{TransText({'CN':'分类设置','EN':'Programmes'})}</h2>
                    </Col>
                    <Col span={1}><Divider type="vertical" style={{height:'100%'}}/></Col>
                    <Col span={5}>
                        <Title style={{ color: blue9}}><CountUp end={10} duration={5} /></Title>
                        <h2 style={{ color: blue9 }}>{TransText({'CN':'课程安排','EN':'Courses'})}</h2>
                    </Col>
                </Row>
                <Divider style={{width:'100%'}}/>
                <Row>
                    <Title style={{ color: blue9, marginTop: 50, textAlign: 'left' }}>eEmu教育</Title>
                    <Title style={{ fontSize:23, textAlign: 'left', lineHeight:2}}>澳洲eEmu教育，和澳洲教育局深度合作，始终遵循“因材施教”的教学理念,不仅在澳洲当地孩子提供k12的全科补习,也为IB课程学校的学生提供从小学到初高中的一系列教育补习服务。在这里，有很多英语非母语的亚洲学生,多年的服务沉淀，使我们积累了有益的经验，形成了独特的教育模式。随着全球化的深入对教育提出了新的要求，中国的教育也越发国际化和现代化。普通的线上平台教育已经不能适应新的要求，家长们也更渴望国外老师能深入了解自己的孩子，了解中国的学习机制和文化背景，能跟踪式教育孩子在阅读和写作上更加深入全面的补习，而不仅仅只是外教陪孩子说英语的阶段。国际化、系统化的一对一教育成为很多拥有国际视野家长的首选。于是，eEmu应运而生。</Title>
                    <Title style={{ fontSize:23, textAlign: 'left', lineHeight:2}}>澳大利亚eEmu教育以澳洲本地全科补习K12教育和IB课程为基础。重点从事线上教育,在IB课程补习和澳洲k12教育补习之外，我们还引进了美国wonders小学语文课程、中国人教版英语课程、剑桥IELTS课程、托福TOEFL考试课程、美国大学入读SAT考试教程等热门课程。同时,针对每位学生和家长的不同需求实施量身定制个性化、系统化的课程包,用实际行动践行我们的教育方针“因材施教 ，中西结合”!</Title>
                    
                </Row>
                <Divider style={{width:'100%'}}/>
                </div>
            </div>
        </>;
    }
}

export default Banner;