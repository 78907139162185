import React from 'react';
import { Form, Input, Button, message, Result } from 'antd';
import { AdminAddTeacher } from '../../request/axios';
import { AlertMsg } from '../PublicMethods';
import { SmileOutlined } from '@ant-design/icons';

class AddTeacher extends React.Component {

    state = {
        teacher_num: null,
    }

    onFinish = (values) => {
        AdminAddTeacher({...values, admin_id:sessionStorage.getItem('admin_id')},request_res=>{
            const {data:{result,status,tips="查询错误"}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"查询课程数据出错"})
            }else{
                this.setState({teacher_num:result})
                message.success('添加成功！');
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
    }

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }
    
    render(){
        if (this.state.teacher_num != null){
            return <Result
                icon={<SmileOutlined />}
                title={"添加教师成功！新教师工号为：" + this.state.teacher_num}
                subTitle="教师工号将用于登录。已发送邮件通知新教师（包含工号和初始密码）。"
                extra={[
                    <Button type="primary" onClick={() => this.setState({teacher_num:null})}>
                        继续添加
                    </Button>
                ]}
            />
        }else{
            const layout = {
                labelCol: {
                    span: 8,
                },
                wrapperCol: {
                    span: 8,
                },
            }
            const tailLayout = {
                wrapperCol: {
                    offset: 6,
                    span: 12,
                },
            }
            return <>
                <Form
                    {...layout}
                    name="basic"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    >
                    <Form.Item
                        label="教师全名"
                        name="username"
                        rules={[
                        {
                            required: true,
                            message: '请输入新教师的全名！',
                        },
                        ]}
                    >
                        <Input placeholder="Andrew Atkinson"/>
                    </Form.Item>

                    <Form.Item
                        label="初始密码"
                        name="password"
                        rules={[
                        {
                            required: true,
                            message: '请输入一个初始密码！',
                        },
                        ]}
                    >
                        <Input.Password placeholder="123456"/>
                    </Form.Item>

                    <Form.Item
                        label="邮箱地址"
                        name="email"
                        rules={[
                        {
                            required: true,
                            message: '请输入新教师的邮箱！',
                        },
                        ]}
                    >
                        <Input placeholder="andrewatkinson@gmail.com"/>
                    </Form.Item>

                    <Form.Item
                        label="ZOOM ID"
                        name="zoom"
                        rules={[
                        {
                            required: true,
                            message: '请输入上课Zoom id！',
                        },
                        ]}
                    >
                        <Input placeholder="zoom id"/>
                    </Form.Item>

                    <Form.Item
                        label="教师简介"
                        name="description"
                        rules={[
                        {
                            required: true,
                            message: '请输入新教师的简介！',
                        },
                        ]}
                    >
                        <Input.TextArea placeholder="教师简介"/>
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            新建教师
                        </Button>
                    </Form.Item>
                </Form>
            </>
        }
    }
}

export default AddTeacher;