import React from 'react';
import { Table, Input, Button, Space, Select, message, Spin,DatePicker } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { AdminGetStudent,getStudentSummary  } from '../../request/axios';
import { AlertMsg, DateFormat } from '../PublicMethods';

const { Option } = Select;

class SearchableTable extends React.Component {
	state = {
	  data: [],
	  loading:true,
      lesosn_list:[]
	};
	
	loadData(){
		AdminGetStudent({admin_id:sessionStorage.getItem('admin_id'),all:true},request_res=>{
            const {data:{result,status,tips="查询错误"}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"查询课程数据出错"})
            }else{
				result.forEach((el,index)=>{
					el.key = index
				})
                this.setState({
					data: result,
					loading:false
				})
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
		})
	}
	
	componentDidMount(){
		this.loadData()
	}

	getColumnSearchProps = dataIndex => ({
	  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		<div style={{ padding: 8 }}>
		  <Input
			ref={node => {
			  this.searchInput = node;
			}}
			placeholder={`搜索 ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ width: 188, marginBottom: 8, display: 'block' }}
		  />
		  <Space>
			<Button
			  type="primary"
			  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  icon={<SearchOutlined />}
			  size="small"
			  style={{ width: 90 }}
			>
			  搜索
			</Button>
			<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  重置
			</Button>
		  </Space>
		</div>
	  ),
	  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	  onFilter: (value, record) =>
		record[dataIndex]
		  ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		  : '',
	  onFilterDropdownVisibleChange: visible => {
		if (visible) {
		  setTimeout(() => this.searchInput.select(), 100);
		}
	  },
	  render: text =>
		this.state.searchedColumn === dataIndex ? (
		  <Highlighter
			highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			searchWords={[this.state.searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
		  />
		) : (
		  text
		),
	});
  
	handleSearch = (selectedKeys, confirm, dataIndex) => {
	  confirm();
	  this.setState({
		searchText: selectedKeys[0],
		searchedColumn: dataIndex,
	  });
	};
  
	handleReset = clearFilters => {
	  clearFilters();
	  this.setState({ searchText: '' });
	};

    onStudentsChange(key){
        this.setState({
            loading:true
        })
        getStudentSummary({s_id:key},data=>{
            const {data:{result,status,tips,total,
                total_teacher,
                total_proxy,
                benefit}} = data
            if(status!==100){
                message.warning(tips)
                return 
            }
            result.forEach((el,index)=>{
                el.key = index
            })

            this.setState({
                lesosn_list: result,
                loading:false,
                total,
                total_teacher,
                total_proxy,
                benefit,
				s_id:key
            })
            
        }).catch(e=>{
            console.log(e)
            message.error("网络错误")
        })
    }

	onDateChange = (time,index) =>{
		const {s_id,start_time,end_time} = this.state
		if(!s_id){
			message.warning("请选择学生")
			return
		}
		this.setState({
            loading:true
        })
		let timeobj = {
			start_time:index===0?time?new Date(time).setHours(0,0,0,0):null:start_time?new Date(start_time).setHours(0,0,0,0):null,
			end_time:index===1?time?new Date(time).setHours(0,0,0,0):null:end_time?new Date(end_time).setHours(0,0,0,0):null,
		}
		getStudentSummary({s_id,
			...timeobj
		},data=>{
            const {data:{result,status,tips,total,
                total_teacher,
                total_proxy,
                benefit}} = data
            if(status!==100){
                message.warning(tips)
                return 
            }
            result.forEach((el,index)=>{
                el.key = index
            })

            this.setState({
                lesosn_list: result,
                loading:false,
                total,
                total_teacher,
                total_proxy,
                benefit,
				start_time:index===0?time:start_time,
				end_time:index===1?time:end_time,
            })
            
        }).catch(e=>{
            console.log(e)
            message.error("网络错误")
        })
	}
  
	render() {
		if(this.state.loading){
            return <Spin size="large"/>
		}
		const { data,lesosn_list,total,start_time,end_time,s_id,
            total_teacher,
            total_proxy,
            benefit } = this.state;
        const columns = [
            {
				title: '学生姓名',
                align:"center",
				dataIndex: 'username',
				key: 'username',
            },
            {
				title: '学号',
                align:"center",
				dataIndex: 'student_num',
				key: 'student_num',
            },
            {
				title: '上课时间',
                align:"center",
				dataIndex: 'lesson_time',
				key: 'lesson_time',
                render:text=>{
                    return DateFormat("Y/M/D",parseInt(text))
                }
            },
            {
				title: '课程单价(rmb)',
                align:"center",
				dataIndex: 'unit_price',
				key: 'unit_price',
            },
            {
				title: '老师',
                align:"center",
				dataIndex: 't_name',
				key: 't_name',
                ...this.getColumnSearchProps('t_name'),
			},
            {
				title: '老师薪水(aud)',
                align:"center",
				dataIndex: 'pay_teacher',
				key: 'pay_teacher',
			},
			{
				title: '代理分成',
                align:"center",
				dataIndex: 'pay_proxy',
				key: 'pay_proxy'
			},
        ]
        const summaryTable = [
            {
				title: '课程价格合计',
                align:"center",
				dataIndex: 'total',
				key: 'total',
            },
            {
				title: '老师价格合计',
                align:"center",
				dataIndex: 'total_teacher',
				key: 'total_teacher',
				
            },
            {
				title: '代理分成合计',
                align:"center",
				dataIndex: 'total_proxy',
				key: 'total_proxy',
				
            },
            {
				title: '股东盈余合计',
                align:"center",
				dataIndex: 'benefit',
				key: 'benefit',
            },
        ]

		

	  	return <><h2><b>选择学生</b></h2>
          <Select
              showSearch
              style={{minWidth:200,marginBottom:20}}
              placeholder="选择学生"
			  value={s_id}
              onChange={this.onStudentsChange.bind(this)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
          >
              {
                  data.map((student, index) => {
                      return <Option key={index} value={student.s_id}>{`${student.username} : ${student.student_num}`}</Option>
                  })
              }
          </Select>
			<br />
		  <Space style={{display:'flex',flexFlow:"row nowrap",justifyContent:"center",marginBottom:20}}>
			起始日期
			<DatePicker value={start_time} style={{margin:20}} onChange={(e)=>this.onDateChange(e,0)} />
			
			结束日期
			<DatePicker value={end_time} style={{margin:20}} onChange={(e)=>this.onDateChange(e,1)} />
			</Space>

          <Table bordered columns={columns} dataSource={lesosn_list} pagination={{ pageSize: 10 }} size="small"/>
          <h3>价格合计(RMB)</h3>
          <Table bordered 
          columns={summaryTable} dataSource={[
              {
				key:"1",
                total,
                total_teacher,
                total_proxy,
                benefit,
              }
          ]} pagination={false} size="small"/>
          </>;
	}
}

class ViewStudent extends React.Component {
    render(){
        return <>
            <SearchableTable />
        </>
    }
}

export default ViewStudent;