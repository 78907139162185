import React from 'react';
import { Typography, Row, Col, Carousel } from 'antd';
import {TransText} from '../PublicMethods'
const { Title } = Typography;

class Teacher extends React.Component {
	constructor(){
		super()
		this.state = {
			responsive: false
		}
	}
	componentDidMount(){
		window.addEventListener('resize', ()=>{
			if(document.body.clientWidth <= 995){
				this.setState({
					responsive : true
				})
			}else{
				this.setState({
					responsive : false
				})
			}	
		})
	}
  render(){
	const {
		responsive
	} = this.state

	const imgStyle = { width:180, height:180, marginBottom:20, borderRadius: '25px', display:'inline'};
	const descripStyle = { width:180, margin:'auto' };
    return <>
		<div style={{ height:400, background:'url("images/static/about3.jpg") center top', backgroundSize:'auto'}}>
	        <div style={{ backgroundColor:'rgba(0, 0, 0, 0.3)', height:'100%', width:'100%' }}>
                <Title style={{ color:'white', paddingTop:180 }}>{TransText({'CN':'教师团队','EN':'Teaching Team'})}</Title>
            </div>
		</div>
		{
			responsive?
			<div style={{maxWidth: 1300, margin: 'auto'}}>
			<Carousel 
				autoplay 
				autoplaySpeed = "20"
			>
				<div style={{ minHeight:560}}>
					<div id="innerConent" style={{ height:'100%', padding:'72px 24px', margin:'auto' }}>
						<div id="title-wrapper" style={{ marginBottom:40 }}>
							<Title level={2}><TransText CN='明星教师团队' EN='Teaching Staff' /></Title>
						</div>
						<div style = {{textAlign:'center'}}>
							<img style={ imgStyle } src="images/static/emily.jpg" alt="emily's img" />
							<Title level={3}>Emily</Title>
							<Title level={5} style={ descripStyle }><TransText CN='澳洲教育家，作家，时事新闻特邀撰稿者' EN='Australian educator, writer and guest writer of current affairs news' /></Title>
						</div>
					</div>
				</div>
				<div style={{ minHeight:560}}>
					<div id="innerConent" style={{ height:'100%', padding:'72px 24px', margin:'auto' }}>
						<div id="title-wrapper" style={{ marginBottom:40 }}>
							<Title level={2}><TransText CN='明星教师团队' EN='Teaching Staff' /></Title>
						</div>
						<div style = {{textAlign:'center'}}>
							<img style={ imgStyle } src="images/static/michelle.jpg" alt="michelle's img" />
							<Title level={3}>Michelle</Title>
							<Title level={5} style={ descripStyle }><TransText CN='澳洲教育家，作家，时事新闻特邀撰稿者' EN='Australian educator, writer and guest writer of current affairs news' /></Title>
						</div>
					</div>
				</div>
				<div style={{ minHeight:560}}>
					<div id="innerConent" style={{ height:'100%', padding:'72px 24px', margin:'auto' }}>
						<div id="title-wrapper" style={{ marginBottom:40 }}>
							<Title level={2}><TransText CN='明星教师团队' EN='Teaching Staff' /></Title>
						</div>
						<div style = {{textAlign:'center'}}>
							<img style={ imgStyle } src="images/static/sherryl.jpg" alt="sherryl's img" />
							<Title level={3}>Sherryl</Title>
							<Title level={5} style={ descripStyle }><TransText CN='澳洲教育家，作家，时事新闻特邀撰稿者' EN='Australian educator, writer and guest writer of current affairs news' /></Title>
						</div>
					</div>
				</div>
				<div style={{ minHeight:560}}>
					<div id="innerConent" style={{ height:'100%', padding:'72px 24px', margin:'auto' }}>
						<div id="title-wrapper" style={{ marginBottom:40 }}>
							<Title level={2}><TransText CN='明星教师团队' EN='Teaching Staff' /></Title>
						</div>
						<div style = {{textAlign:'center'}}>
							<img style={ imgStyle } src="images/static/robyn.jpg" alt="robyn's img" />
							<Title level={3}>Robyn</Title>
							<Title level={5} style={ descripStyle }><TransText CN='澳洲教育家，作家，时事新闻特邀撰稿者' EN='Australian educator, writer and guest writer of current affairs news' /></Title>
						</div>
					</div>
				</div>
				<div style={{ minHeight:560}}>
					<div id="innerConent" style={{ height:'100%', padding:'72px 24px', margin:'auto' }}>
						<div id="title-wrapper" style={{ marginBottom:40 }}>
							<Title level={2}><TransText CN='明星教师团队' EN='Teaching Staff' /></Title>
						</div>
						<div style = {{textAlign:'center'}}>
							<img style={ imgStyle } src="images/static/liam.jpg" alt="liam's img" />
							<Title level={3}>Liam</Title>
							<Title level={5} style={ descripStyle }><TransText CN='优秀辅导老师' EN='Professional Tutor' /></Title>
						</div>
					</div>
				</div>
			</Carousel>
			</div> 
			:
			<div style={{maxWidth: 1300, margin: 'auto'}}>
			<Carousel 
				autoplay 
				autoplaySpeed = "20"
			>
				  <div style={{ minHeight:560}}>
					<div id="innerConent" style={{ height:'100%', padding:'72px 24px', margin:'auto' }}>
						<div id="title-wrapper" style={{ marginBottom:40 }}>
							<Title level={2}><TransText CN='明星教师团队' EN='Teaching Staff' /></Title>
						</div>
						<div>
							<Row>
								<Col lg = {{span:6}} style = {{marginTop:20}}>
									<div style = {{textAlign:'center'}}>
										<img style={ imgStyle } src="images/static/emily.jpg" alt="emily's img" />
										<Title level={3}>Emily</Title>
										<Title level={5} style={ descripStyle }><TransText 
										CN='澳洲教育家，作家，时事新闻特邀撰稿者' 
										EN='Australian educator, writer and guest writer of current affairs news' /></Title>
									</div>
								</Col>
								<Col lg = {{span:6}} style = {{marginTop:20}}>
									<div>
										<img style={ imgStyle } src="images/static/michelle.jpg" alt="michelle's img" />
										<Title level={3}>Michelle</Title>
										<Title level={5} style={ descripStyle }><TransText 
										CN='澳大利亚广播电视台播音员，昆士兰大学专业语言老师，快30年教学经验' 
										EN='Australian radio and television announcer, professional language teacher of the University of Queensland, 
										nearly 30 years of teaching experience' /></Title>
									</div>
								</Col>
								<Col lg = {{span:6}} style = {{marginTop:20}}>
									<div>
										<img style={ imgStyle } src="images/static/sherryl.jpg" alt="sherryl's img" />
										<Title level={3}>Sherryl</Title>
										<Title level={5} style={ descripStyle }><TransText 
										CN='多年澳洲教育经验，多元化教学，丰富有趣，提高学习兴趣' 
										EN='Many years of Australian education experience, diversified teaching, rich and interesting, improve learning interest' /></Title>
									</div>
								</Col>
                        	    <Col lg = {{span:6}} style = {{marginTop:20}}>
									<div>
										<img style={ imgStyle } src='images/static/robyn.jpg' alt="robyn's img" />
										<Title level={3}>Robyn</Title>
										<Title level={5} style={ descripStyle }><TransText 
										CN='用英语思维训练写作，说话，和思考，让您的孩子建立语言自信' 
										EN='Train your child to write, speak, and think in English so that your child can build up language confidence' /></Title>
									</div>
								</Col>
							</Row>
						</div>
					</div>
      			</div>
				  <div style={{ minHeight:560}}>
					<div id="innerConent" style={{ height:'100%', padding:'72px 24px', margin:'auto' }}>
						<div id="title-wrapper" style={{ marginBottom:40 }}>
							<Title level={2}><TransText CN='明星教师团队' EN='Teaching Staff' /></Title>
						</div>
						<div>
							<Row>
								<Col lg = {{span:24}} style = {{marginTop:20}}>
									<div style = {{textAlign:'center'}}>
										<img style={ imgStyle } src="images/static/liam.jpg" alt="liam's img" />
										<Title level={3}>Liam</Title>
										<Title level={5} style={ descripStyle }><TransText 
										CN='优秀辅导老师' 
										EN='Professional Tutor' /></Title>
									</div>
								</Col>
							</Row>
						</div>
					</div>
      			</div>
			</Carousel>
			</div>
		}
			
		</>;
  }
}

export default Teacher;