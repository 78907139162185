import React from 'react';
import {
    Layout, Typography, Card, Row, Col, Button 
} from 'antd';
import { TransText, AlertMsg } from '../PublicMethods';
import QuestionServer from "./QuestionServer"
const { Content } = Layout;
const { Title, Text } = Typography;

export const Profile = (props) => {
    const {student_num, username, parent_email, total, parent_phone, holiday}= props.student_info

    let status = ''
    if(holiday){
        let [start, end] = holiday.split(',')
        status=<Text type='warning'>{new Date(parseInt(start)).toLocaleDateString()} - {new Date(parseInt(end)).toLocaleDateString()}</Text>
    }else{
        status=<Text type='success'><TransText CN='正常上课' EN='Active'/></Text>
    }

    function handleLogout(){
        sessionStorage.clear()
        AlertMsg({message:"注意",des:"您已注销！",type:"success", callback:()=>window.location.href = "/login"})
    }
    return (
        <Content style={{ padding: '30px 50px', backgroundImage: 'url(../images/static/white.png)',backgroundRepeat: 'no-repeat',  backgroundSize:'cover'}}>
            {/*<div style={{position:"absolute", backgroundColor:'#ababab', width:'1000px', height:'900px', opacity:'0.2', borderRadius:'25px', padding:'100px',}}></div>*/}
            <Row>
            <Col span={24}>
            <div id="container" style={{ maxWidth: 1000, margin: 'auto', padding: '50px 20px 20px 20px'}}>
                <Card title={<TransText CN='学生信息' EN='Student Information'/>} style={{ width: '100%', borderRadius: '25px' }} >
                    <br />
                    <br />
                    <Row>
                        <Col span={12}><Title level = {3}><TransText CN='学生姓名:' EN='Student Name:'/></Title></Col>
                        <Col span={12}><Title level = {3}>{username}</Title></Col>
                    </Row>
                    <br />
                    <br />
                    <Row>
                        <Col span={12}><Title level = {3}><TransText CN='学号:' EN='Student Number:'/></Title></Col>
                        <Col span={12}><Title level = {3}>{student_num}</Title></Col>
                    </Row>
                    <br />
                    <br />
                    <Row>
                        <Col span={12}><Title level = {3}><TransText CN='电话号码:' EN='Parent Phone:'/></Title></Col>
                        <Col span={12}><Title level = {3}>{parent_phone}</Title></Col>
                    </Row>
                    <br />
                    <br />
                    <Row>
                        <Col span={12}><Title level = {3}><TransText CN='家长邮箱:' EN='Parent Email:'/></Title></Col>
                        <Col span={12}><Title level = {3}>{parent_email}</Title></Col>
                    </Row>
                    <br />
                    <br />
                    <Row>
                        <Col span={12}><Title level = {3}><TransText CN='我的课时:' EN='My Lessons:'/></Title></Col>
                        <Col span={12}><Title level = {3}><Text type="success">{total} <TransText CN='节' EN='Hours'/></Text></Title></Col>
                    </Row>
                    <br />
                    <br />
                    <Row>
                        <Col span={12}><Title level = {3}><TransText CN='状态：' EN='Status:'/></Title></Col>
                        <Col span={12}><Title level = {3}>{status}</Title></Col>
                    </Row>
                    <br />
                    <br />
                    <Button onClick={handleLogout}><TransText CN='注销登录' EN='Logout'/></Button>
                </Card>
                <br />
            </div>
            </Col>
            </Row>
        </Content>
    )
}

export const ClientServer=()=>{
    return (
        <Content style={{ padding: '30px 50px', backgroundImage: 'url(../images/static/white.png)',backgroundRepeat: 'no-repeat',  backgroundSize:'cover'}}>
            <QuestionServer />
        </Content>
    )
}