import React from 'react';
import { Table, Input, Button, Space, Tooltip, Spin, message, Popconfirm } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { GetCourseInfo, AdminUpdateTableRow, AdminDeleteSubject } from '../../request/axios'
import { AlertMsg } from '../PublicMethods';
import { EditableRow, EditableCell } from '../admin/EditableRow'

class SearchableTable extends React.Component {
	state = {
	  searchText: '',
	  searchedColumn: '',
	  data: [],
	  loading:true
	};
	
	constructor(props){
		super(props)
		this.columns = [
            {
				title: '课程代码',
				dataIndex: 'subject_code',
                key: 'subject_code',
				width: 130,
				editable: true,
				...this.getColumnSearchProps('subject_code'),
            },
            {
				title: '名称',
				dataIndex: 'name',
                key: 'name',
                width: 200,
				editable: true,
				editType:"transText",
				...this.getColumnSearchProps('name'),
            },
            {
				title: '分类',
				dataIndex: 'category',
                key: 'category',
                width: 90,
				editable: true,
				editType:"transText",
				...this.getColumnSearchProps('name'),
            },
            {
				title: '描述',
				dataIndex: 'description',
                key: 'description',
				editable: true,
				editType:"description",
                ellipsis: {
                    showTitle: false,
                },
                  render: description => (
                    <Tooltip placement="topLeft" title={description}>
                      {description}
                    </Tooltip>
				),
				align:'center'
			},
			{
				title: '所需总课时',
				dataIndex: 'total_hour',
                key: 'total_hour',
                width: 90,
				editable: true,
				editType:"number",
				align:'center'
            },
			{
				title: '单节时长（分钟）',
				dataIndex: 'duration',
                key: 'duration',
                width: 90,
				editable: true,
				editType:"number",
				align:'center'
            },
			{
				title: '标签',
				dataIndex: 'config',
                key: 'config',
                width: 150,
				editable: true,
				editType:"config",
				align:'center',
				ellipsis: {
                    showTitle: false,
                },
                  render: description => (
                    <Tooltip placement="topLeft" title={description}>
                      {description}
                    </Tooltip>
				),
			},
            {
				title: '是否明星',
				dataIndex: 'star',
                key: 'star',
                width: 60,
				editable: true,
				...this.getColumnSearchProps('star'),
            },
            {
				title: '有多少个老师',
				dataIndex: 'counts',
                key: 'counts',
                width: 60,
				render: text => {
					if(text === null){
						return '无'
					}else{
						return text
					}
				},
				align:'center'
			},
            {
				title: '操作',
				key: 'action',
				width: 150,
				render: (record) => (
					<Space size="small">
						<Button type="link" onClick={()=>this.saveChange(record)}>
							保存
						</Button>
						<Popconfirm
							title="你确定要删除吗？谨慎操作！"
							onConfirm={()=>this.confirm(record.sub_id)}
							onCancel={this.cancel}
							okText="Yes"
							cancelText="No"
							style={{textAlign:'left'}}
							icon={null}
						>
							<Button type="link">
								删除
							</Button>
						</Popconfirm>
					</Space>
				),
				align:'center'
            },
        ];
	}
	loadData(){
		GetCourseInfo({admin_id:sessionStorage.getItem('admin_id')}).then(data=>{
			const {data:{result,status,tips}} = data
			if(status!==100){
				AlertMsg({message:'数据错误',des:tips})
				return
			}
			result.forEach((el,index)=>{
				el.key = index
			})
			this.setState({
				data:result,
				loading:false
			})
		}).catch(err=>{
			AlertMsg({message:'网络错误',des:'网络连接出错'})
			console.log(err)
		})
	}

	componentDidMount(){
		this.loadData()
	}

    confirm = (sub_id) => {
		AdminDeleteSubject({admin_id:sessionStorage.getItem('admin_id'),sub_id},request_res=>{
            const {data:{status,tips="查询错误"}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"删除失败。"})
				this.loadData()
            }else{
				message.success('此课程已删除！');
				this.loadData()
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
    }

  	cancel = (e) =>{
        message.error('点了取消。');
	}

	getColumnSearchProps = dataIndex => ({
	  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		<div style={{ padding: 8 }}>
		  <Input
			ref={node => {
			  this.searchInput = node;
			}}
			placeholder={`搜索 ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ width: 188, marginBottom: 8, display: 'block' }}
		  />
		  <Space>
			<Button
			  type="primary"
			  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  icon={<SearchOutlined />}
			  size="small"
			  style={{ width: 90 }}
			>
			  搜索
			</Button>
			<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  重置
			</Button>
		  </Space>
		</div>
	  ),
	  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	  onFilter: (value, record) =>
		record[dataIndex]
		  ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		  : '',
	  onFilterDropdownVisibleChange: visible => {
		if (visible) {
		  setTimeout(() => this.searchInput.select(), 100);
		}
	  },
	  render: text =>
		this.state.searchedColumn === dataIndex ? (
		  <Highlighter
			highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			searchWords={[this.state.searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
		  />
		) : (
		  text
		),
	});
  
	handleSearch = (selectedKeys, confirm, dataIndex) => {
	  confirm();
	  this.setState({
		searchText: selectedKeys[0],
		searchedColumn: dataIndex,
	  });
	};
  
	handleReset = clearFilters => {
	  clearFilters();
	  this.setState({ searchText: '' });
	};

	handleSave = (row) => {
		const newData = [...this.state.data];
		const index = newData.findIndex((item) => row.key === item.key);
		const item = newData[index];
		newData.splice(index, 1, { ...item, ...row });
		this.setState({
		  data: newData,
		});
	};

	saveChange(record){
		let table_name = 'subject'
		let id_column = 'sub_id'
		let table_id = record.sub_id
		const {key, sub_id, counts, creation_time, update_time, img_src,...submit_data} = record
		AdminUpdateTableRow({
			admin_id:sessionStorage.getItem('admin_id'),
			table_name,
			id_column,
			table_id,
			record:submit_data
		},request_res=>{
            const {data:{status,tips="查询错误"}} = request_res
            if(status!==100){
                message.warning(tips)
            }else{
				message.success('更新保存成功！');
				this.loadData()
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
	}
  
	render() {
		if(this.state.loading){
            return <Spin size="large"/>
		}
		const { data } = this.state;
		const components = {
			body: {
			  row: EditableRow,
			  cell: EditableCell,
			},
		};
		const columns = this.columns.map((col) => {
			if (!col.editable) {
			  return col;
			}
	  
			return {
			  ...col,
			  onCell: (record) => ({
				record,
				editable: col.editable,
				editType: col.editType,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave: this.handleSave,
			  }),
			};
		});
		return <Table bordered components={components} 
		rowClassName={() => 'editable-row'} columns={columns} dataSource={data} pagination={{ pageSize: 20 }} size="middle"/>;
	}
}

class ViewSubject extends React.Component {
    render(){
        return <>
            <SearchableTable />
        </>
    }
}

export default ViewSubject;