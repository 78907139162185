import React, { Component } from 'react';
import { Button, Form, Input, Row, Col, message, Result, Layout, Typography, Card } from 'antd'
import { AlertMsg, TransText } from '../../components/PublicMethods';
import { getUserProfile, getTeacherProfile, getVerificationCode, changeInfo } from '../../request/axios'
import cookie from  'react-cookies'
import { SmileOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Title, Text } = Typography;

class ChangeInfo extends Component {
    constructor() {
        super()
        this.state = {
            s_id: sessionStorage.getItem('s_id'),
            user_info: {},
            confirm: '',
            emailCoolDown: 60,
            success:false,
        }
    }
    componentDidMount() {
        if (this.props.role === "teacher") {
            getTeacherProfile({ t_id: sessionStorage.getItem('t_id') }, data => {
                const { data: { result } } = data
                this.setState({
                    user_info: result,
                })
            }).catch(error => {
                console.log(error)
            })
        } else {
            getUserProfile({ s_id: this.state.s_id }, data => {
                const { data: { result } } = data
                this.setState({
                    user_info: result,
                })
            }).catch(error => {
                console.log(error)
            })
        }
    }

    handleVerification(e){
        this.setState({
            verification_code:e.target.value
        })
    }

    handleSubmit(data) {
        const {inputEmail,verification_code} = this.state
        let isVerified = false
        if(!inputEmail||inputEmail===""||cookie.load(inputEmail)===verification_code){
            isVerified = true
        }
        if(isVerified){
            data.s_id = sessionStorage.getItem("s_id")
            data.t_id = sessionStorage.getItem("t_id")
            changeInfo(data, request_res => {
                const { data: { status, tips } } = request_res
                if (status !== 100) {
                    message.warning(TransText({'CN':tips,'EN':'Error occured when update information.'}))
                } else {
                    this.setState({
                        success:true
                    })
                    message.success(TransText({'CN':'信息更改成功！','EN':'Updated Successfully!'}))
                }
            }).catch(err => {
                console.log(err)
                AlertMsg({ message: "网络错误", des: "请求数据出错" })
            })
        }else{
            message.warning(TransText({CN:"验证码不正确",EN:"Incorrect varification code"}))
        }
    }

    inputEmail = (e) => {
        this.setState({
            inputEmail: e.target.value === "" ? null : e.target.value
        })
    }

    sendEmail() {
        const { inputEmail } = this.state
        // 邮箱正则
        let emailReg = /^([a-zA-Z0-9]+[_]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
        if (emailReg.test(inputEmail)) {
            this.setState({ emailCoolDown: 59 })
            this.timer = setInterval(() => {
                const { emailCoolDown } = this.state
                if (emailCoolDown > 0) {
                    this.setState({
                        emailCoolDown: emailCoolDown - 1
                    })
                } else {
                    clearInterval(this.timer)
                    this.setState({
                        emailCoolDown: 60
                    })
                }
            }, 1000)
            let role = sessionStorage.getItem("s_id")?'s':'t'
            getVerificationCode({ email:inputEmail,change_mail:'yes',role }, data => {
                const { data: { result, status, tips } } = data
                if (status !== 100) {
                    message.error(TransText({'CN':tips,'EN':'Fail to send verification code.'}))
                    return
                }
                cookie.save(inputEmail,result,{maxAge:300})
                message.info(TransText({'CN':'验证码已发送','EN':'Verification code has been sent.'}))
            }).catch(err => {
                console.log(err)
            })
        } else {
            message.warning(TransText({ CN: "邮箱格式不正确", EN: "Invalid Email" }))
        }
    }

    render() {
        if(this.state.success){
            return <>
                <Content style={{ padding: '30px 50px' }}>
                    <div style={{maxWidth:1000, margin:'auto'}}>
                        <Title level={3}><TransText CN='修改信息' EN='Edit Profile'/></Title>
                        <br />
                        <Card style={{ width: '100%' }}>
                            <Result
                                icon={<SmileOutlined />}
                                title={TransText({'CN':'信息修改成功!','EN':'Success!'})}
                                subTitle={TransText({'CN':'返回个人主页。','EN':'Back to "My Profile".'})}
                                extra={[
                                    <Button type="primary" onClick={() => this.props.role === "teacher"?this.props.back():window.location.href="/userProfile"}>
                                        {TransText({'CN':'返回','EN':'Return'})}
                                    </Button>,
                                ]}
                            />
                        </Card>
                    </div>
                </Content>
            </>
        }else{
            const { parent_email, parent_phone, zoom, email } = this.state.user_info
            const { password, inputEmail, emailCoolDown } = this.state
            const layout = {
                labelCol: {
                span: 6,
                },
                wrapperCol: {
                span: 18,
                },
            };
            return (
                <Content style={{ padding: '30px 50px', backgroundImage: 'url(../images/static/white.png)',backgroundRepeat: 'no-repeat',  backgroundSize:'cover'}}>
                <div style={{maxWidth:1000, margin:'auto'}}>
                    <Title level={3}><TransText CN='修改信息' EN='Edit Profile'/></Title>
                    <br />
                    <Card style={{ width: '100%' }}>
                    <Form
                        {...layout}
                        onFinish={(value) => this.handleSubmit(value)}
                        name="register"
                        autoComplete="off"
                        scrollToFirstError
                    >
                        {
                            parent_phone ?
                                <Form.Item
                                    name="parent_phone"
                                    label={TransText({ CN: "家长电话", EN: "Parent Email" })}
                                    rules={[
                                        {
                                            required: false,
                                            message: '请输入新电话号码!',
                                        },
                                    ]}
                                    >
                                    <Input
                                        name="parent_phone"
                                        autoComplete='off'
                                        placeholder={parent_phone}
                                    />
                                </Form.Item>
                                :
                                <></>
                        }
                        {
                            zoom ?
                                <Form.Item
                                    name="zoom"
                                    label="Zoom ID"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please input your new ZOOM id!',
                                        },
                                    ]}
                                    >
                                    <Input
                                        autoComplete='off'
                                        placeholder={zoom}
                                    />
                                </Form.Item>
                                :
                                <></>
                        }
                        <Form.Item
                            name={`${parent_email ? 'parent_email' : 'email'}`}
                            label={TransText({ CN: "邮箱", EN: "Email" })}
                            rules={[
                                {
                                    message: TransText({ CN: "请输入新邮箱地址!", EN: 'Please input your new email!' }),
                                },
                            ]}
                            >
                            <Input
                                autoComplete='off'
                                placeholder={parent_email || email}
                                onChange={(e) => this.inputEmail(e)}
                            />
                        </Form.Item>
                        {
                            inputEmail ?
                                <Form.Item
                                    label={TransText({ CN: "验证码", EN: "Verification Code" })}
                                    rules={[
                                        {
                                            required: true,
                                            message: TransText({ CN: "请输入验证码!", EN: 'Please input the verification code!' }),
                                        },
                                    ]}
                                    >
                                    <Row>
                                        <Col span={18}>
                                            <Input
                                                autoComplete='off'
                                                placeholder={TransText({ CN: "验证码", EN: "Verification Code" })}
                                                onChange={(e) => this.handleVerification(e)}
                                            />
                                        </Col>
                                        <Col span={6}>
                                            <Button
                                                disabled={emailCoolDown !== 60}
                                                onClick={() => this.sendEmail()}
                                                style={{width:"100%"}}>
                                                {
                                                    emailCoolDown !== 60 ?
                                                        `${emailCoolDown}s`
                                                        :
                                                        TransText({ 'CN': '获取验证码', 'EN': 'Send Email' })
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                                :
                                <></>
                        }

                        <Form.Item
                            name="password"
                            label={TransText({ CN: "新密码", EN: "New Password" })}
                            hasFeedback
                            rules={[
                                {
                                    required: false,
                                    message: 'Please input your password!',
                                },
                            ]}
                            >
                            <Input.Password
                                name="password"
                                autoComplete='off'
                            />
                        </Form.Item>
                        {<Form.Item
                            name="confirm"
                            label={TransText({ CN: "确认密码", EN: "Comfirm Password" })}
                            dependencies={['password']}
                            hasFeedback
                            
                            rules={[
                                {
                                    required: password,
                                    message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('两次密码不正确');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password onChange={this.handleInputChange} name="confirm" />
                        </Form.Item>}
                        <Text type="secondary"><TransText CN='没有输入的属性将保持不变。' EN='Attributes not entered will remain unchanged.'/></Text>
                        <Row style={{marginTop:20}}>
                            <Col span={24}>
                                <Button style={{width:80}} type="primary" htmlType="submit">{TransText({ CN: "提交", EN: "Submit" })}</Button>
                                <Button style={{marginLeft:50, width:80}}onClick={() => this.props.back()}>{TransText({ CN: "返回", EN: "Back" })}</Button>
                            </Col>
                        </Row>
                        </Form>
                        </Card>
                    </div>
                    </Content>
            )
        }
    }
}

export default ChangeInfo