import React from 'react';
import { Table, Input, Button, Space, message, Spin, Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { AdminGetSettleSheet,AdminGetLessonsByIds } from '../../request/axios';
import { AlertMsg, DateFormat } from '../PublicMethods';

class SearchableTable extends React.Component {
	state = {
	  searchText: '',
	  searchedColumn: '',
	  data: [],
	  loading:true,
      lesson_info:[]
	};
    
    cancel = (e) =>{
        message.error('Click on No');
	}
	
	loadData(){
        AdminGetSettleSheet({
            admin_id:sessionStorage.getItem('admin_id'),
            role:sessionStorage.getItem("auth_id")==="6"?"proxy":""
        },data=>{
			const {data:{result,status,tips}} = data
			if(status!==100){
				message.warning(tips)
				return
            }
			result.forEach((el,index)=>{
                el.key = index
			})
			this.setState({
				data: result,
				loading:false
			})
		}).catch(err=>{
			AlertMsg({message:'网络错误',des:'网络连接出错'})
			console.log(err)
		})
	}

	componentDidMount(){
		this.loadData()
	}

	getColumnSearchProps = dataIndex => ({
	  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		<div style={{ padding: 8 }}>
		  <Input
			ref={node => {
			  this.searchInput = node;
			}}
			placeholder={`搜索 ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ width: 188, marginBottom: 8, display: 'block' }}
		  />
		  <Space>
			<Button
			  type="primary"
			  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  icon={<SearchOutlined />}
			  size="small"
			  style={{ width: 90 }}
			>
			  搜索
			</Button>
			<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  重置
			</Button>
		  </Space>
		</div>
	  ),
	  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	  onFilter: (value, record) =>
		record[dataIndex]
		  ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		  : '',
	  onFilterDropdownVisibleChange: visible => {
		if (visible) {
		  setTimeout(() => this.searchInput.select(), 100);
		}
	  },
	  render: text =>
		this.state.searchedColumn === dataIndex ? (
		  <Highlighter
			highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			searchWords={[this.state.searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
		  />
		) : (
		  text
		),
	});
  
	handleSearch = (selectedKeys, confirm, dataIndex) => {
	  confirm();
	  this.setState({
		searchText: selectedKeys[0],
		searchedColumn: dataIndex,
	  });
	};
  
	handleReset = clearFilters => {
	  clearFilters();
	  this.setState({ searchText: '' });
	};

    showModal = (lesson_id) => {
        AdminGetLessonsByIds({lesson_id,},data=>{
            const {data:{result,tips,status}} = data
            if(status!==100){
                message.warning(tips)
                return
            }
            result.forEach((e,index)=>{
                e.key = index
            })
            this.setState({
                model:true,
                lesson_info:result
            });
        })
        
    };
    
    handleOk = () => {
        this.setState({
            model:false,
            lesson_info:[]
        });
    };
    
    handleCancel = () => {
        this.setState({
            model:false,
            lesson_info:[]
        });
    };

    renderTenLessons(){
        const {lesson_info} = this.state
        const columns = [
            {
                title: '学生姓名',
                dataIndex: 's_name',
                key: 's_name',
            },
            {
                title: '老师姓名',
                dataIndex: 't_name',
                key: 't_name',
            },
            {
                title: '课程',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '课程单价',
                dataIndex: 'unit_price',
                key: 'unit_price',
            },
            {
                title: '上课时间',
                dataIndex: 'lesson_time',
                key: 'lesson_time',
                render:text=>{
                    return DateFormat("Y/M/D",parseInt(text))
                }
            }
        ]
        return <Table bordered columns={columns} dataSource={lesson_info} pagination={false} size="small"/>
    }
  
	render() {
		if(this.state.loading){
            return <Spin size="large"/>
        }
        const {model} = this.state
        
		let columns;
        if(sessionStorage.getItem("auth_id")==="6"){
            columns = columns = [
                {
                    title: '课程总价',
                    dataIndex: 'total',
                    key: 'total',
                },
                {
                    title: '代理分成比例',
                    dataIndex: 'proxy_persent',
                    key: 'proxy_persent',
                    render : text=>{
                        return text*100 +"%"
                    }
                },
                {
                    title: '代理分成',
                    dataIndex: 'proxy_sum',
                    key: 'proxy_sum',
                },
                {
                    title: '结算课程',
                    dataIndex: 'target_lessons',
                    key: 'target_lessons',
                    render:text=>{
                        return  <>
                        <Button type="primary" onClick={()=>this.showModal(text)}>
                            查看10节课程信息
                        </Button>
                      </>
                    }
                },
                {
                    title: '结算时间',
                    dataIndex: 'creation_time',
                    key: 'creation_time',
                    render : text=>{
                        return DateFormat("Y/M/D",parseInt(text))
                    }
                },
            ];
        }else{
            columns = [
                {
                    title: '代理名称',
                    dataIndex: 'username',
                    key: 'username',
                    ...this.getColumnSearchProps('username'),
                },
                {
                    title: '代理工号',
                    dataIndex: 'admin_num',
                    key: 'admin_num',
                    ...this.getColumnSearchProps('admin_num'),
                },
                {
                    title: '课程总价',
                    dataIndex: 'total',
                    key: 'total',
                },
                {
                    title: '代理分成比例',
                    dataIndex: 'proxy_persent',
                    key: 'proxy_persent',
                    render : text=>{
                        return text*100 +"%"
                    }
                },
                {
                    title: '代理分成',
                    dataIndex: 'proxy_sum',
                    key: 'proxy_sum',
                },
                {
                    title: '老师薪水',
                    dataIndex: 'teacher_paid',
                    key: 'teacher_paid',
                },
                {
                    title: '股东盈余',
                    dataIndex: 'benefit',
                    key: 'benefit',
                },
                {
                    title: '结算课程',
                    dataIndex: 'target_lessons',
                    key: 'target_lessons',
                    render:text=>{
                        return  <>
                        <Button type="primary" onClick={()=>this.showModal(text)}>
                            查看10节课程信息
                        </Button>
                      </>
                    }
                },
                {
                    title: '结算时间',
                    dataIndex: 'creation_time',
                    key: 'creation_time',
                    render : text=>{
                        return DateFormat("Y/M/D",parseInt(text))
                    }
                },
            ];
        }
        
	  	return <>
          <Table bordered columns={columns} dataSource={this.state.data} pagination={{ pageSize: 20 }} size="middle"/>
          <Modal title="Basic Modal" visible={model} onOk={this.handleOk} onCancel={this.handleCancel}>
            {this.renderTenLessons()}
        </Modal>
          </>
          ;
	}
}

class ViewProxySettleSheet extends React.Component {
    render(){
        return <>
            <SearchableTable />
        </>
    }
}

export default ViewProxySettleSheet;