import React,{Component} from 'react';
import { Layout } from 'antd';
import TopNav from '../components/TopNav';
import Category from '../components/course/Categories'
import Footer1 from '../components/Footer1';


const { Header, Content, Footer } = Layout;

class Course extends Component{
    constructor(){
        super()
        this.state={
        }
    }

    componentDidMount(){
    }

    render(){
        return <>
            <Layout>
                <Header style={{backgroundColor: 'white'}}>
                    <TopNav menuItem={'2'}/>
                </Header>
                <Content>
                </Content>
                <Content>
                    <Category />
                </Content>
                <Footer style={{ textAlign: 'center', backgroundColor:'#fafafa' }}>
                    <Footer1 />
                </Footer>
            </Layout>
        </>
    }
}

export default Course;