import React from 'react';
import { Table, Input, Button, Space, message, Spin, Typography,InputNumber  } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { AdminConfirmLessonApplication, AdminGetLessonApplication } from '../../request/axios';
import { AlertMsg, DateFormat } from '../PublicMethods';

const { Text } = Typography

class SearchableTable extends React.Component {
	state = {
	  searchText: '',
	  searchedColumn: '',
	  data: [],
	  loading:true,
	  payment : {}
	};
    
	loadData(){
        AdminGetLessonApplication({admin_id:sessionStorage.getItem('admin_id')},data=>{
			const {data:{result,status,tips}} = data
			if(status!==100){
				AlertMsg({message:'数据错误',des:tips})
				return
			}
			result.forEach((el,index)=>{
				el.key = index
			})
			this.setState({
				data: result,
				loading:false
			})
		}).catch(err=>{
			AlertMsg({message:'网络错误',des:'网络连接出错'})
			console.log(err)
		})
	}
    
	componentDidMount(){
		this.loadData()
	}

    reply(data, reply){
		const {payment} = this.state
		if(reply && !(reply + payment[data.id])){
			message.warning("请输入学生付款数")
			return
		}
		data.student_paid = reply && payment[data.id]
        AdminConfirmLessonApplication({admin_id:sessionStorage.getItem('admin_id'), ...data, reply },request_res=>{
			const {data:{status,tips}} = request_res
			if(status!==100){
				AlertMsg({message:'数据错误',des:tips})
				return
			}
			message.success(`处理成功！可前往'代理账单'处查看。`)
			this.loadData()
		}).catch(err=>{
			AlertMsg({message:'网络错误',des:'网络连接出错或操作频繁，请稍后再试。'})
			console.log(err)
		})
    }

	getColumnSearchProps = dataIndex => ({
	  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		<div style={{ padding: 8 }}>
		  <Input
			ref={node => {
			  this.searchInput = node;
			}}
			placeholder={`搜索 ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ width: 188, marginBottom: 8, display: 'block' }}
		  />
		  <Space>
			<Button
			  type="primary"
			  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  icon={<SearchOutlined />}
			  size="small"
			  style={{ width: 90 }}
			>
			  搜索
			</Button>
			<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  重置
			</Button>
		  </Space>
		</div>
	  ),
	  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	  onFilter: (value, record) =>
		record[dataIndex]
		  ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		  : '',
	  onFilterDropdownVisibleChange: visible => {
		if (visible) {
		  setTimeout(() => this.searchInput.select(), 100);
		}
	  },
	  render: text =>
		this.state.searchedColumn === dataIndex ? (
		  <Highlighter
			highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			searchWords={[this.state.searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
		  />
		) : (
		  text
		),
	});
  
	handleSearch = (selectedKeys, confirm, dataIndex) => {
	  confirm();
	  this.setState({
		searchText: selectedKeys[0],
		searchedColumn: dataIndex,
	  });
	};
  
	handleReset = clearFilters => {
	  clearFilters();
	  this.setState({ searchText: '' });
	};

	inputMoney(record,value){
		let {payment} = this.state
		payment[record.id] = value
		this.setState({
			payment,
		})
	}
  
	render() {
		if(this.state.loading){
            return <Spin size="large"/>
        }
		const columns = [
            {
                title: '代理工号',
                dataIndex: 'admin_num',
				key: 'admin_num',
				...this.getColumnSearchProps('admin_num'),
            },
            {
                title: '代理姓名',
                dataIndex: 'username',
				key: 'admin_num',
				...this.getColumnSearchProps('admin_username'),
            },
            {
                title: '学生学号',
                dataIndex: 'student_num',
				key: 'student_num',
				...this.getColumnSearchProps('student_num'),
            },
            {
                title: '课程代码',
                dataIndex: 'subject_code',
				key: 'subject_code',
				...this.getColumnSearchProps('subject_code'),
            },
            {
                title: '教师工号',
                dataIndex: 'teacher_num',
				key: 'teacher_num',
				...this.getColumnSearchProps('teacher_num'),
            },
            {
                title: '购买课时数',
                dataIndex: 'order_amount',
				key: 'order_amount',
				align:'center'
            },
			{
                title: '输入学生总付款数',
                dataIndex: 'student_paid',
				key: 'student_paid',
				align:'center',
				render:(text,record)=>{
					return text!==null?text:<><InputNumber onChange={(value)=>this.inputMoney(record,value)} /> 元</>
				}
            },
			{
                title: '申请时间',
                dataIndex: 'creation_time',
				key: 'creation_time',
				align:'center',
				render:(text)=>{
					return DateFormat("Y/M/D",parseInt(text))
				}
            },
            {
				title: '操作',
                dataIndex: 'reply',
				key: 'reply',
				render: (text,record) =>{
					if(record.handle === 0){
						const {reply, admin_id,update_time,creation_time,handle, ...data} = record
						data.proxy_id = admin_id
						return <>
						<Space>
							<Button type='primary' onClick={()=>this.reply(data, 1)}>
								确认
							</Button>
							<Button type='secondary' danger onClick={()=>this.reply(data, 0)}>
								拒绝
							</Button>
						</Space>
						</>
					}else{
						if(text === 0){
							return <Text type="warning">已拒绝</Text>
						}else{
							return record.student_paid!==null?<Text type="success">安排成功</Text>:<Text type="success">已处理</Text>
						}
					}
                },
				align:'center'
			}
        ];
	  	return <Table bordered columns={columns} dataSource={this.state.data} pagination={{ pageSize: 20 }} size="middle"/>;
	}
}

class ViewArrangeCourse extends React.Component {
    render(){
        return <>
            <SearchableTable />
        </>
    }
}

export default ViewArrangeCourse;