import React, { useState } from 'react';
import { Table, Input, Button, Space, Select, message, Spin, Typography, Popconfirm, Divider } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { AdminGetStudent, AdminSetNewProxy, getProxy, AdminUpdateTableRow, DeleteStudent  } from '../../request/axios';
import { AlertMsg } from '../PublicMethods';
import { EditableRow, EditableCell } from '../admin/EditableRow'

const { Option } = Select;
const { Text } = Typography

class SearchableTable extends React.Component {
	state = {
	  searchText: '',
	  searchedColumn: '',
	  data: [],
	  proxyList: [],
	  proxy: false,
	  loading:true
	};
	
	constructor(props){
		super(props)
		this.columns = [
            {
				title: '姓名',
				dataIndex: 'username',
				key: 'username',
				editable: true,
				...this.getColumnSearchProps('username'),
            },
            {
				title: '学号',
				dataIndex: 'student_num',
				key: 'student_num',
				...this.getColumnSearchProps('student_num'),
            },
            {
				title: '年龄',
				dataIndex: 'dob',
				key: 'dob',
				...this.getColumnSearchProps('dob'),
            },
            {
				title: '年级',
				dataIndex: 'grade',
				key: 'grade',
				editable: true,
				editType:"number",
				...this.getColumnSearchProps('grade'),
            },
            {
				title: '家长邮箱',
				dataIndex: 'parent_email',
				key: 'parent_email',
				editable: true,
				editType:"email",
				...this.getColumnSearchProps('parent_email'),
			},
			{
				title: '家长电话',
				dataIndex: 'parent_phone',
				key: 'parent_phone',
				editable: true,
				editType:"tel",
				...this.getColumnSearchProps('parent_phone'),
			},
            {
				title: '放假状态',
				dataIndex: 'holiday',
				key: 'holiday',
				width: 100,
				render: text=>{
					if(text){
						let [start, end] = text.split(',')
						if(end){
							return <Text type='warning'>{new Date(parseInt(start)).toLocaleDateString()} - {new Date(parseInt(end)).toLocaleDateString()}</Text>
						}else{
							return <Text type='danger'>{text}</Text>
						}
					}else{
						return <Text type='success'>正常授课</Text>
					}
				},
				align:'center'
            },
			{
				title: '总计消费',
				dataIndex: 'total_payment',
				key: 'total_payment',
				width:100,
				align:'center'
			},
            {
				title: '时薪振幅(aud)',
				dataIndex: 'amplitude',
				key: 'amplitude',
				editable: true,
				editType:"number",
				...this.getColumnSearchProps('amplitude'),
				width: 80
            },
			{
				title: '代理工号',
				dataIndex: 'admin_num',
				key: 'admin_num',
				...this.getColumnSearchProps('admin_num'),
				render: (text, record)=>{
					if(text){
						return <>{text}
							{this.Example(record)}
						</>
					}else{
						return <>
							<Select
								showSearch
								style={{width:200}}
								placeholder="选择代理"
								optionFilterProp="children"
								onChange={this.onSelectChange.bind(this)}
							>
								{
									this.state.proxyList.map((proxy, index) => {
										return <Option key={index} value={proxy.admin_id}>{`${proxy.username} : ${proxy.admin_num}`}</Option>
									})
								}
							</Select>
							<Button type="primary" onClick={()=>this.setProxy(record.s_id, record.student_num)}>提交</Button>
						</>
					}
				},
			},
            {
				title: '操作',
				key: 'action',
				width: 150,
				render: (record) => (
					<Space size="small">
						<Button type="link" onClick={()=>this.saveChange(record)}>
							保存
						</Button>
						<Popconfirm
							title="你确定要删除该学生全部档案吗？谨慎操作！"
							onConfirm={()=>this.confirm(record.s_id)}
							onCancel={this.cancel}
							okText="Yes"
							cancelText="No"
							style={{textAlign:'left'}}
							icon={null}
						>
							<a href="/admin">删除</a>
						</Popconfirm>
					</Space>
				),
				align:'center'
            },
        ];
		// if(sessionStorage.getItem("auth_id")==="3" || sessionStorage.getItem("auth_id")==="5"){
		// 	this.columns = this.columns.filter(e=>{
		// 		return !(e.key==="parent_email" || e.key==="parent_phone")
		// 	})
		// }
	}

	Example(record) {
		const [on, setOn] = useState(false);
		if(on){
			return <>
			<Divider type="vertical" />
			<Select
				showSearch
				style={{width:200}}
				placeholder="选择代理"
				optionFilterProp="children"
				onChange={this.onSelectChange.bind(this)}
			>
				{
					this.state.proxyList.map((proxy, index) => {
						return <Option key={index} value={proxy.admin_id}>{`${proxy.username} : ${proxy.admin_num}`}</Option>
					})
				}
			</Select>
			<Button type="primary" onClick={()=>this.setProxy(record.s_id, record.student_num)}>提交</Button>
			</>
		}else{
			if(sessionStorage.getItem("auth_id")==="3" || sessionStorage.getItem("auth_id")==="5"){
				return <></>
			}
			return <><Divider type="vertical" /><Button type="link" onClick={()=>setOn(true)}>更改代理</Button></>
		}
	}
	
	loadData(){
		AdminGetStudent({admin_id:sessionStorage.getItem('admin_id'),all:true},request_res=>{
            const {data:{result,status,tips="查询错误"}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"查询课程数据出错"})
            }else{
				result.forEach((el,index)=>{
					el.key = index
				})
                this.setState({
					data: result,
					loading:false
				})
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
		})

		getProxy().then(data => {
            const { data: { result, status, tips } } = data
            if (status !== 100) {
                AlertMsg({ message: "注册失败", des: tips })
            } else {
                this.setState({
                    proxyList: result
                })
            }
        }).catch(err => {
            AlertMsg({ message: "网络错误", des: "请求数据出错" })
        })
	}

	cancel = (e) =>{
        console.log(e);
        message.error('Click on No');
	}

	confirm = (s_id) => {
        DeleteStudent({admin_id:sessionStorage.getItem('admin_id'),s_id},request_res=>{
            const {data:{status,tips="查询错误"}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"删除学生出错！"})
            }else{
				// deleteMultipleOSS('eemu-file', result[0])
				// deleteMultipleOSS('eemu-recording', result[1])
				// deleteMultipleOSS('eemu-assessment', result[2])
				message.success('此学生已删除！');
				this.loadData()
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
	}
	
	componentDidMount(){
		this.loadData()
	}

	onSelectChange(value) {
        this.setState({
            proxy:value
        })
	}
	
	setProxy(s_id, student_num){
		AdminSetNewProxy({admin_id:sessionStorage.getItem('admin_id'), s_id:s_id, student_num:student_num, proxy_id:this.state.proxy},request_res=>{
            const {data:{status,tips="查询错误"}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"查询学生数据出错"})
            }else{
                message.success('成功安排代理！')
                this.loadData()
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
	}

	getColumnSearchProps = dataIndex => ({
	  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		<div style={{ padding: 8 }}>
		  <Input
			ref={node => {
			  this.searchInput = node;
			}}
			placeholder={`搜索 ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ width: 188, marginBottom: 8, display: 'block' }}
		  />
		  <Space>
			<Button
			  type="primary"
			  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  icon={<SearchOutlined />}
			  size="small"
			  style={{ width: 90 }}
			>
			  搜索
			</Button>
			<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  重置
			</Button>
		  </Space>
		</div>
	  ),
	  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	  onFilter: (value, record) =>
		record[dataIndex]
		  ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		  : '',
	  onFilterDropdownVisibleChange: visible => {
		if (visible) {
		  setTimeout(() => this.searchInput.select(), 100);
		}
	  },
	  render: text =>
		this.state.searchedColumn === dataIndex ? (
		  <Highlighter
			highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			searchWords={[this.state.searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
		  />
		) : (
		  text
		),
	});
  
	handleSearch = (selectedKeys, confirm, dataIndex) => {
	  confirm();
	  this.setState({
		searchText: selectedKeys[0],
		searchedColumn: dataIndex,
	  });
	};
  
	handleReset = clearFilters => {
	  clearFilters();
	  this.setState({ searchText: '' });
	};

	handleSave = (row) => {
		const newData = [...this.state.data];
		const index = newData.findIndex((item) => row.key === item.key);
		const item = newData[index];
		newData.splice(index, 1, { ...item, ...row });
		this.setState({
		  data: newData,
		});
	  };

	saveChange(record){
		let table_name = 'students'
		let id_column = 's_id'
		let table_id = record.s_id
		const {key, s_id, admin_num, balance, creation_time, update_time, holiday, password, proxy, proxy_notice, student_num, total_progress, ...submit_data} = record
		AdminUpdateTableRow({
			admin_id:sessionStorage.getItem('admin_id'),
			table_name,
			id_column,
			table_id,
			record:submit_data
		},request_res=>{
            const {data:{status,tips="查询错误"}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"保存出错！"})
            }else{
				message.success('更新保存成功！');
				this.loadData()
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
	}
  
	render() {
		if(this.state.loading){
            return <Spin size="large"/>
		}
		const { data } = this.state;
		const components = {
			body: {
			  row: EditableRow,
			  cell: EditableCell,
			},
		};
		const columns = this.columns.map((col) => {
			if (!col.editable) {
			  return col;
			}
	  
			return {
			  ...col,
			  onCell: (record) => ({
				record,
				editable: col.editable,
				editType: col.editType,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave: this.handleSave,
			  }),
			};
		});
	  	return <Table bordered components={components} 
		  rowClassName={() => 'editable-row'} columns={columns} dataSource={data} pagination={{ pageSize: 20 }} size="middle"/>;
	}
}

class ViewStudent extends React.Component {
    render(){
        return <>
            <SearchableTable />
        </>
    }
}

export default ViewStudent;