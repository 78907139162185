import React from 'react';
import { Button, message, Result, Select } from 'antd';
import { AdminAssignCourse, AdminGetTeacher, GetCourseInfo } from '../../request/axios';
import { AlertMsg, SplitENCN } from '../PublicMethods';
import { SmileOutlined } from '@ant-design/icons';
const { Option } = Select;

class Demo extends React.Component {

    state = {
      teacher_list:[],
      children:[],
      selected_teachers:[],
      selected_subjects:[]
    }

    constructor(props){
      super()
    }

    componentDidMount(){
      AdminGetTeacher({admin_id:sessionStorage.getItem('admin_id')},request_res=>{
        const {data:{result,status,tips}} = request_res
        if(status!==100){
            AlertMsg({message:"查询课程数据出错",des:tips})
        }else{
          result.forEach((el,index)=>{
            el.key = index
          })
          this.setState({
            teacher_list:result
          })
        }
      }).catch(err=>{
        AlertMsg({message:"网络错误",des:"请求数据出错"})
        console.log(err.message)
      })

      GetCourseInfo({admin_id:sessionStorage.getItem('admin_id')}).then(data=>{
        const {data:{result,status,tips}} = data
        if(status!==100){
          AlertMsg({message:'数据错误',des:tips})
          return
        }
        result.forEach((el,index)=>{
          el.key = index
        })

        this.setState({
          children:result
        })
      }).catch(err=>{
        AlertMsg({message:'网络错误',des:'网络连接出错'})
        console.log(err)
      })
    }

    onChange(value) {
      this.setState({
        selected_teachers:value
      })
    }

    handleChange(value) {
      this.setState({
        selected_subjects:value
      })
    }

    submit(){
      const selected_teachers = this.state.selected_teachers
      const selected_subjects = this.state.selected_subjects
      if(selected_teachers === 0){
        message.warning('请选择教师');
        return
      }
      if(selected_subjects === 0){
        message.warning('请选择课程');
        return
      }
      AdminAssignCourse({selected_teachers:selected_teachers,selected_subjects:selected_subjects,admin_id:sessionStorage.getItem('admin_id')},request_res=>{
        const {data:{status,tips}} = request_res
        if(status!==100){
          AlertMsg({message:'添加错误',des:tips})
        }else{
          this.props.func(true)
          message.success('添加授课分配成功');
        }
      }).catch(err=>{
        AlertMsg({message:"网络错误",des:"请求数据出错"})
        console.log(err.message)
      })
    }

    render(){
      return (<>
        <Select
          mode="multiple"
          allowClear
          style={{ width: '60%' }}
          placeholder="选择教师"
          onChange={this.onChange.bind(this)}
        >
          {this.state.teacher_list.map((item, index)=>{
            return <Option key={index} value={item.t_id}>{`${item.teacher_num} : ${item.username}`}</Option>
          })}
        </Select>

        <br/><br/>
        
        <Select
          mode="multiple"
          allowClear
          style={{ width: '60%' }}
          placeholder="选择课程"
          onChange={this.handleChange.bind(this)}
        >
          {this.state.children.map((item, index)=>{
            return <Option key={index} value={item.sub_id}>{`${item.subject_code} : ${SplitENCN(item.name)['CN']}`}</Option>
          })}
        </Select>

        <br/><br/>
        
        <Button type="primary" onClick={this.submit.bind(this)}>
          提交授课安排
        </Button>
      </>);
    }
};

class AddAssign extends React.Component {

    state = {
      success: null
    }

    changeSuccess(value){
      this.setState({success:value})
    }

    render(){
      if (this.state.success != null){
        return <Result
            icon={<SmileOutlined />}
            title={"授课安排分配成功！"}
            subTitle="前往'查看授课安排'页面查看。"
            extra={[
                <Button type="primary" onClick={() => this.setState({success:null})}>
                    继续添加
                </Button>
            ]}
        />
      }else{
        return <>
            <Demo func={this.changeSuccess.bind(this)}/>
        </>
      }
    }
}

export default AddAssign;