import React, { Component } from 'react';
import { TransText } from '../PublicMethods'
import '../../style/components/course/categories.css'
import { getCourse } from '../../request/axios'
import { AlertMsg, SplitENCN } from '../PublicMethods'
import { CourseCard } from './CourseCard'
import { Row, Col, Typography, Spin } from 'antd';
import { CSSTransition, SwitchTransition } from 'react-transition-group'

const { Title } = Typography;

class Category extends Component {
    constructor() {
        super()
        this.state = {
            tag_selected: 0,
            change: true,
            course_data: [],
            popular: [],
            loading:true
        }
    }

    componentDidMount() {
        getCourse({}, request_res => {
            const { data: { result, status, tips } } = request_res
            if (status !== 100) {
                AlertMsg({ message: "课程错误", des: tips })
            } else {
                this.setState({
                    course_data: result,
                    loading:false
                })
            }
        }).catch(err => {
            AlertMsg({ message: "网络错误", des: "请求数据出错" })
            console.log(err.message)
        })
    }

    render() {
		if(this.state.loading){
            return <Spin size="large"/>
        }
        const { course_data, tag_selected, change } = this.state
        const course_list = course_data.length ? course_data[tag_selected].subject : []
        const course_des = course_data.length ? course_data[tag_selected].des : ''
        return (
            <div id='categories'>
                <Title style={{color:'#003a8c' }}><TransText CN='全部课程' EN='Course' /></Title>
                <div className='course-tag'>
                    {
                        course_data.map((tag, index) => {
                            return (
                                <span key={index} style={tag_selected === index ?
                                    { backgroundColor: '#5698c3', color: '#FFFFFF' } : {}}
                                    onClick={() => this.setState({ tag_selected: index, change: !change })}>
                                    {TransText({ ...SplitENCN(tag.code) })}
                                </span>
                            )
                        })
                    }
                </div>
                <div id='des'>
                    <SwitchTransition>
                        <CSSTransition
                            key={change}
                            addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                            classNames='transition-des-text'
                        >
                            <p>{TransText({ ...SplitENCN(course_des) })}</p>
                        </CSSTransition>
                    </SwitchTransition>
                </div>
                <div id='course-flow' style={{ flexFlow: 'wrap' }}>
                    {
                        course_list.length ?
                            <SwitchTransition>
                                <CSSTransition
                                    key={change}
                                    addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                                    classNames='transition-course-card'
                                >
                                    <Row style={{ width: '100%',transformOrigin:'top' }}>
                                        {
                                            course_list.map((lesson, index) => {
                                                return (
                                                    <Col key={index} xs={24} sm={12} md={12} lg={8}>
                                                        {CourseCard(lesson)}
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                </CSSTransition>
                            </SwitchTransition>
                            :
                            <></>
                    }
                </div>
            </div>
        )
    }
}

export default Category