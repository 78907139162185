import React from 'react';
import { Table, Input, Button, Space, Popconfirm, message, Tooltip, Spin, Typography } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { AdminGetTeacher, AdminDeleteTeacher, AdminUpdateTableRow } from '../../request/axios';
import { AlertMsg } from '../PublicMethods';
import { EditableRow, EditableCell } from '../admin/EditableRow'

const { Text } = Typography
class SearchableTable extends React.Component {
	state = {
		searchText: '',
		searchedColumn: '',
		data: [],
		loading:true
	};

	constructor(props) {
		super(props);
		this.columns = [
            {
				title: '姓名',
				dataIndex: 'username',
				key: 'username',
				width: 150,
				editable: true,
				...this.getColumnSearchProps('username'),
            },
            {
				title: '工号',
				dataIndex: 'teacher_num',
				key: 'teacher_num',
				width: 100,
				...this.getColumnSearchProps('teacher_num'),
			},
			{
				title: '邮箱',
				dataIndex: 'email',
				key: 'email',
				width: 250,
				editable: true,
				editType:"email",
				...this.getColumnSearchProps('email')
			},
			{
				title: 'zoom',
				dataIndex: 'zoom',
				key: 'zoom',
				width: 150,
				editable: true,
				editType:"number",
				...this.getColumnSearchProps('zoom'),
			},
			{
				title: '简介',
				dataIndex: 'description',
				key: 'description',
				editable: true,
				editType:"textarea",
				ellipsis: {
					showTitle: false,
				},
				render: description => (
					<Tooltip placement="topLeft" title={description}>
					{description}
					</Tooltip>
				),
				align:'center'
			},
			{
				title: '放假状态',
				dataIndex: 'holiday',
				key: 'holiday',
				width: 100,
				render: text=>{
					if(text){
						let [start, end] = text.split(',')
						if(end){
							return <Text type='warning'>{new Date(parseInt(start)).toLocaleDateString()} - {new Date(parseInt(end)).toLocaleDateString()}</Text>
						}else{
							return <Text type='danger'>{text}</Text>
						}
					}else{
						return <Text type='success'>正常授课</Text>
					}
				},
				align:'center'
			},
			{
				title: '累计aud',
				dataIndex: 'total_payment',
				key: 'total_payment',
				width: 80,
				editable: true,
				editType:"number",
				...this.getColumnSearchProps('total_payment'),
			},
			{
				title: '节薪aud',
				dataIndex: 'salary',
				key: 'salary',
				width: 80,
				editable: true,
				editType:"number",
				...this.getColumnSearchProps('salary'),
			},
			{
				title: '操作',
				key: 'action',
				width: 170,
				render: (record) => (
					<>
						<Button type="link" style={{padding:0, margin:0, fontSize:12}} onClick={()=>this.saveChange(record)}>
							保存
						</Button>
						<Popconfirm
							title="你确定要暂停吗？谨慎操作！"
							onConfirm={()=>this.pause(record.t_id)}
							onCancel={this.cancel}
							okText="Yes"
							cancelText="No"
							style={{textAlign:'left', padding:0, margin:0}}
							icon={null}
						>
							<Button type="link" style={{fontSize:12}}>
								暂停
							</Button>
						</Popconfirm>
						<Popconfirm
							title="你确定要删除吗？谨慎操作！"
							onConfirm={()=>this.confirm(record.t_id)}
							onCancel={this.cancel}
							okText="Yes"
							cancelText="No"
							style={{textAlign:'left', padding:0, margin:0}}
							icon={null}
						>
							<Button type="link" style={{fontSize:12}}>
								删除
							</Button>
						</Popconfirm>
					</>
				),
				align:'center'
			}
		];
		// if(sessionStorage.getItem("auth_id")!=="6") this.columns.push(
        //     {
		// 		title: '邮箱',
		// 		dataIndex: 'email',
		// 		key: 'email',
		// 		width: 250,
		// 		editable: true,
		// 		editType:"email",
		// 		...this.getColumnSearchProps('email')
		// 	})
			// this.columns.push(
			// 	{
			// 		title: 'zoom',
			// 		dataIndex: 'zoom',
			// 		key: 'zoom',
			// 		width: 150,
			// 		editable: true,
			// 		editType:"number",
			// 		...this.getColumnSearchProps('zoom'),
			// 	},
			// 	{
			// 		title: '简介',
			// 		dataIndex: 'description',
			// 		key: 'description',
			// 		editable: true,
			// 		editType:"textarea",
			// 		ellipsis: {
			// 			showTitle: false,
			// 		},
			// 		render: description => (
			// 			<Tooltip placement="topLeft" title={description}>
			// 			{description}
			// 			</Tooltip>
			// 		),
			// 		align:'center'
			// 	},
			// 	{
			// 		title: '放假状态',
			// 		dataIndex: 'holiday',
			// 		key: 'holiday',
			// 		width: 180,
			// 		render: text=>{
			// 			if(text){
			// 				let [start, end] = text.split(',')
			// 				if(end){
			// 					return <Text type='warning'>{new Date(parseInt(start)).toLocaleDateString()} - {new Date(parseInt(end)).toLocaleDateString()}</Text>
			// 				}else{
			// 					return <Text type='danger'>{text}</Text>
			// 				}
			// 			}else{
			// 				return <Text type='success'>正常授课</Text>
			// 			}
			// 		},
			// 		align:'center'
			// 	},
			// 	{
			// 		title: '操作',
			// 		key: 'action',
			// 		width: 150,
			// 		render: (record) => (
			// 			<Space size="small">
			// 				<Button type="link" onClick={()=>this.saveChange(record)}>
			// 					保存
			// 				</Button>
			// 				<Popconfirm
			// 					title="你确定要删除吗？谨慎操作！"
			// 					onConfirm={()=>this.confirm(record.t_id)}
			// 					onCancel={this.cancel}
			// 					okText="Yes"
			// 					cancelText="No"
			// 					style={{textAlign:'left'}}
			// 					icon={null}
			// 				>
			// 					<Button type="link">
			// 						删除
			// 					</Button>
			// 				</Popconfirm>
			// 			</Space>
			// 		),
			// 		align:'center'
			// 	})
	}

	loadData(){
		//api读数据
		AdminGetTeacher({admin_id:sessionStorage.getItem('admin_id')},request_res=>{
            const {data:{result,status,tips}} = request_res
            if(status!==100){
                AlertMsg({message:"查询课程数据出错",des:tips})
            }else{
				result.forEach((el,index)=>{
					el.key = index
				})
                this.setState({
					data: result,
					loading:false
				})
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
	}

	componentDidMount(){
		this.loadData()
	}

  	confirm = (t_id) => {
		AdminDeleteTeacher({admin_id:sessionStorage.getItem('admin_id'),t_id,pause:false},request_res=>{
            const {data:{status,tips="删除错误"}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"存在学生需要浏览这个老师的课件，已设置该老师为待删除。"})
				this.loadData()
            }else{
				message.success('此老师已删除！');
				this.loadData()
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
    }

	pause = (t_id) => {
		AdminDeleteTeacher({admin_id:sessionStorage.getItem('admin_id'),t_id,pause:true},request_res=>{
            const {data:{status,tips="暂停错误"}} = request_res
            if(status!==100){
                AlertMsg({message:"暂停错误",des:tips})
				this.loadData()
            }else{
				message.success('此老师已暂停！');
				this.loadData()
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
    }

  	cancel = (e) =>{
        message.error('点了取消。');
	}

	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		  <div style={{ padding: 8 }}>
			<Input
			  ref={node => {
				this.searchInput = node;
			  }}
			  placeholder={`搜索 ${dataIndex}`}
			  value={selectedKeys[0]}
			  onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			  onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  style={{ width: 188, marginBottom: 8, display: 'block' }}
			/>
			<Space>
			  <Button
				type="primary"
				onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
				icon={<SearchOutlined />}
				size="small"
				style={{ width: 90 }}
			  >
				搜索
			  </Button>
			  <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
				重置
			  </Button>
			</Space>
		  </div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
		  record[dataIndex]
			? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
			: '',
		onFilterDropdownVisibleChange: visible => {
		  if (visible) {
			setTimeout(() => this.searchInput.select(), 100);
		  }
		},
		render: text =>
		  this.state.searchedColumn === dataIndex ? (
			<Highlighter
			  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			  searchWords={[this.state.searchText]}
			  autoEscape
			  textToHighlight={text ? text.toString() : ''}
			/>
		  ) : (
			text
		  ),
	  });
	
	  handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
		  searchText: selectedKeys[0],
		  searchedColumn: dataIndex,
		});
	  };
	
	  handleReset = clearFilters => {
		clearFilters();
		this.setState({ searchText: '' });
	  };

	  handleSave = (row) => {
		const newData = [...this.state.data];
		const index = newData.findIndex((item) => row.key === item.key);
		const item = newData[index];
		newData.splice(index, 1, { ...item, ...row });
		this.setState({
		  data: newData,
		});
	  };

	saveChange(record){
		let table_name = 'teachers'
		let id_column = 't_id'
		let table_id = record.t_id
		const {key, t_id, ...submit_data} = record
		AdminUpdateTableRow({
			admin_id:sessionStorage.getItem('admin_id'),
			table_name,
			id_column,
			table_id,
			record:submit_data
		},request_res=>{
            const {data:{status,tips="查询错误"}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"保存出错！"})
            }else{
				message.success('更新保存成功！');
				this.loadData()
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
	}
	
    render(){
		if(this.state.loading){
            return <Spin size="large"/>
        }
		const { data } = this.state;
		const components = {
			body: {
			  row: EditableRow,
			  cell: EditableCell,
			},
		};
		let columns = this.columns.map((col) => {
			if (!col.editable) {
			  return col;
			}
	  
			return {
			  ...col,
			  onCell: (record) => ({
				record,
				editable: col.editable,
				editType:col.editType,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave: this.handleSave,
			  }),
			};
		});
		if(sessionStorage.getItem("auth_id")==="6") columns.splice(2,1)
        return <Table bordered components={components} 
		rowClassName={() => 'editable-row'} columns={columns} dataSource={data} pagination={{ pageSize: 20 }} size="middle"/>
    }
}
class ViewTeacher extends React.Component {
    render(){
        return <>
            <SearchableTable />
        </>
    }
}
export default ViewTeacher;