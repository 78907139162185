import React from 'react';
import { TransText,SplitENCN } from '../PublicMethods'
export const CourseCard = (subject) => {
    return (
        <div key={subject.sub_id} className='course-card' onClick={() => window.location.href = `/subject?${subject.sub_id}`}>
            <div>
                <img src={subject.img_src&&subject.img_src!==''?subject.img_src:`images/static/course/default.jpg`} alt={subject.name} />
            </div>
            <div>
                <div className='course-sub-name'>{TransText({ ...SplitENCN(subject.name) })}</div>
                {subject.code &&
                <div className='course-cate-name'>{TransText({ ...SplitENCN(subject.code) })}</div>
                }
                <div className='course-card-footer'>
                    <div>
                        <span className='iconfont icon-kecheng' />{subject.subject_code}
                    </div>
                    <div><span className='iconfont icon-bfgq-shichang' />{subject.total_hour+' '+ TransText({CN:'课时',EN:'Lessons'})}</div>
                </div>
            </div>
        </div>
    )
}