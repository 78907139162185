import React, { useState } from 'react';
import { 
    Layout, Typography, Col, Row, Card, Divider,Modal,
    Table, Button,DatePicker, message, Switch 
    } from 'antd';
import { TransText, SplitENCN, AlertMsg, DateFormat } from '../PublicMethods';
import {setHoliday,endHoliday,setVisibility} from '../../request/axios'
const { Content } = Layout;
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

export const Summary = (props) => {

    function handleLogout(){
        sessionStorage.clear()
        AlertMsg({message:TransText({'CN':'再见','EN':"See You"}),des:TransText({'CN':'您已注销','EN':'You have logged out'}),type:"success", callback:()=>window.location.href = "/login"})
    }

    function onChange(s_id, checked){
        setVisibility({
            t_id:sessionStorage.getItem("t_id"),
            s_id,
            checked
        },data=>{
            const {data:{status,tips}} = data
            if(status!==100){
                message.warning(tips)
                return
            }
            message.success((checked?'Set visible in Course Resources.':'Set invisible in Course Resources.')+' Please refresh the page.')
        }).catch(err=>{
            console.log(err)
        })
    }

    const {username, teacher_num, total, email, holiday,zoom, verification} = props.teacher_info

    let status = ''
    if(holiday){
        let [start, end] = holiday.split(',')
        status=<Text type='warning'>{new Date(parseInt(start)).toLocaleDateString()} - {new Date(parseInt(end)).toLocaleDateString()}</Text>
    }else{
        status=<Text type='success'><TransText CN='正常接受预约' EN='Active'/></Text>
    }

    const assign = props.assign
    let columns = [
        {
            title: <TransText CN='课程代码' EN='Subject Code'/>,
            dataIndex: 'subjectcode',
            key: 'subjectcode',
            align: 'center'
        },
        {
            title: <TransText CN='课程名称' EN='Subject Name'/>,
            dataIndex: 'subjectname',
            key: 'subjectname',
            align: 'center'
        },
    ];
    let data = [];
    assign.map((item,i)=>{
        data.push({key:i, sub_id:item.sub_id, subjectcode:item.subject_code, subjectname:TransText({...SplitENCN(item.name)})})
        return null
    })

    const my_students = props.my_students
    let columns_students = [
        {
            title: <TransText CN='学生学号' EN='Student Number'/>,
            dataIndex: 'student_num',
            key: 'student_num',
            align: 'center'
        },
        {
            title: <TransText CN='学生姓名' EN='Student Name'/>,
            dataIndex: 'username',
            key: 'username',
            align: 'center'
        },
        {
            title: <TransText CN='学生年龄' EN='Age'/>,
            dataIndex: 'dob',
            key: 'dob',
            align: 'center'
        },
        {
            title: <TransText CN='年级' EN='Grade'/>,
            dataIndex: 'grade',
            key: 'grade',
            align: 'center'
        },
        {
            title: <TransText CN='课程编号' EN='Subject Code'/>,
            dataIndex: 'subject_code',
            key: 'subject_code',
            align: 'center'
        },
        {
            title: <TransText CN='是否可见' EN='Visibility'/>,
            dataIndex: 'visibility',
            key: 'visibility',
            align: 'center',
            render: (visibility, record) => <Switch defaultChecked={visibility} onChange={(checked)=>onChange(record.s_id, checked)}/>
        },
    ];
    let data_student = [];
    my_students.map((item,i)=>{
        let {s_id, student_num,subject_code,username,dob,grade, visibility} = item
        data_student.push({key:i, s_id, student_num, subject_code, username,dob,grade, visibility})
        return null
    })

    return <>
        <Content style={{ padding: '30px 50px' }}>
            <div id="container" style={{ maxWidth: 1000, margin: 'auto' }}>
                <Title level={3}><TransText CN='教师概览' EN='My Profile'/></Title>
                <br />
                <Card title={<TransText CN='教师信息' EN='Profile'/>} id='teacher-summary' style={{ width: '100%' }}>
                    <div></div><div></div><div></div>
                    <Row>
                        <Col span={12}><TransText CN='教师姓名：' EN='Name:'/></Col>
                        <Col span={12}>{username}</Col>
                    </Row>
                    <Row>
                        <Col span={12}><TransText CN='工号：' EN='Teacher Number:'/></Col>
                        <Col span={12}>{teacher_num}</Col>
                    </Row>
                    <Row>
                        <Col span={12}><TransText CN='邮箱：' EN='Email:'/></Col>
                        <Col span={12}>{email}</Col>
                    </Row>
                    <Row>
                        <Col span={12}><TransText CN='ZOOM：' EN='ZOOM:'/></Col>
                        <Col span={12}>{zoom}</Col>
                    </Row>
                    <Row>
                        <Col span={12}><TransText CN='已授课时：' EN='Hours Taught:'/></Col>
                        <Col span={12}><Text type="success">{total} <TransText CN='节' EN='Hour'/></Text></Col>
                    </Row>
                    <Row>
                        <Col span={12}><TransText CN='认证状态：' EN='Verification:'/></Col>
                        <Col span={12}><Text type={verification===1?'success':'warning'}>{verification===1?<TransText CN='已认证' EN='Verified'/>:<TransText CN='尚未认证' EN='Unverified'/>}</Text></Col>
                    </Row>
                    <Row>
                        <Col span={12}><TransText CN='状态：' EN='Status:'/></Col>
                        <Col span={12}>{status}</Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col span={12}><Button onClick={handleLogout}>{TransText({'CN':'退出登录','EN':'Log Out'})}</Button></Col>
                        <Col span={12}><Button onClick={()=>props.displayEditPage()}>{TransText({'CN':'编辑信息','EN':'Edit'})}</Button></Col>
                    </Row>
                </Card>
                <br/>
                <Card title={<TransText CN='任职课程' EN='My Subjects'/>} style={{ width: '100%' }}>
                    <Table columns={columns} dataSource={data} bordered/>
                </Card>
                <br/>
                <Card title={<TransText CN='我的学生' EN='My Students'/>} style={{ width: '100%' }}>
                    <Table columns={columns_students} dataSource={data_student} bordered/>
                </Card>
            </div>
        </Content>
    </>
}

export const AvailableTime = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleOk = (v) => {
        if(!v){
            message.warning("You have not selected a time")
            return
        }
        const period = [...v.map(t=>{
            return new Date(t).setHours(6,0,0,0)
        })]
        setHoliday({holidayPeriod:period,t_id:sessionStorage.getItem("t_id")},data=>{
            const {data:{tips,status,result}} = data
            if(status!==100){
                message.warning(tips)
                return
            }
            message.success(`Holiday has been set successfully${result?","+result+" lessons in holiday have been delayed automatically.":""}`)
            setIsModalVisible(false);
            sessionStorage.setItem("holiday",period.join(","))
            setTimeout(()=>window.location.reload(),1500)
        }).catch(err=>{
            message.error("Network error")
            console.log(err)
        })
    };
    const cancelHoliday = () =>{
        endHoliday({
            t_id:sessionStorage.getItem("t_id"),
            period:sessionStorage.getItem("holiday").split(",")
        },data=>{
            const {data:{status,tips}} = data
            if(status!==100){
                message.warning(tips)
                return
            }
            sessionStorage.setItem("holiday","null")
            message.success("Welcome back from holiday")
            setTimeout(()=>{
                window.location.reload()
            },1500)
        }).catch(err=>{
            console.log(err)
        })
    }
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const [dates, setDates] = useState([]);
    const [hackValue, setHackValue] = useState();
    const [dateValue, setValue] = useState();
    const disabledDate = current => {
        if (!dates || dates.length === 0) {
            //5天内
            let timestamp = new Date(current).getTime()
            return timestamp<new Date().getTime() || timestamp>new Date().getTime()+5*24*3600000
        }
        // 两周假期
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 14;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 14;
        const delay = dates[1] && new Date(current).getTime()<new Date().getTime()+7*24*3600000
        return tooEarly || tooLate || delay;
    };
    const onOpenChange = open => {
        if (open) {
          setHackValue([]);
          setDates([]);
        } else {
          setHackValue(undefined);
        }
    };

    return <>
        <Content style={{ padding: '30px 50px' }}>
            <div id="container" style={{ maxWidth: 1000, margin: 'auto' }}>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                <Title level={3}>{props.title}</Title>
                {
                    sessionStorage.getItem('holiday')==="null"?
                    <Button type="primary" onClick={showModal}>Set Holiday</Button>
                    :
                    <Button type="primary" onClick={cancelHoliday}>Cancel Holiday({sessionStorage.getItem("holiday").split(",").map((t,index)=>DateFormat(`${index===1?"~":""}Y/M/D${index===0?"~":""}`,parseInt(t)))})</Button>
                }
                
                <Modal title="Set your holiday time" visible={isModalVisible} onOk={()=>handleOk(dateValue)} onCancel={handleCancel}>
                    <Text strong>Holiday can be set within 5 days, and the maximum holiday duration is two weeks. You will not receive new student course arrangements before the end of holiday.</Text><br />
                    <RangePicker
                        style={{margin:"20px auto"}}
                        value={hackValue || dateValue}
                        disabledDate={disabledDate}
                        onCalendarChange={val => setDates(val)}
                        onChange={val => setValue(val)}
                        onOpenChange={onOpenChange}
                        />
                </Modal>
                </div>
                <br />
                <Card style={{ width: '100%' }}>
                    {props.node}
                </Card>
            </div>
        </Content>
    </>
}