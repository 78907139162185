import React from 'react';
import { Table, Input, Button, Space, message, Spin, Typography } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { AdminGetStudent, ConfirmNewStudent } from '../../request/axios';
import { AlertMsg } from '../PublicMethods';

const { Text } = Typography

class SearchableTable extends React.Component {
	state = {
	  searchText: '',
	  searchedColumn: '',
	  data: [],
	  loading:true,
	};
	
	loadData(){
		let proxy_id = sessionStorage.getItem('admin_id')
		AdminGetStudent({admin_id:proxy_id},request_res=>{
            const {data:{result,status,tips="查询错误"}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"查询课程数据出错"})
            }else{
				result.forEach((el,index)=>{
					el.key = index
				})
                this.setState({
					data: result,
					loading:false
				})
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
	}

    confirmContact(s_id){
        ConfirmNewStudent({admin_id:sessionStorage.getItem('admin_id'), s_id:s_id},request_res=>{
            const {data:{status,tips="查询错误"}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"查询学生数据出错"})
            }else{
                message.success('成功设置成已联系！')
                this.loadData()
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
    }

	componentDidMount(){
		this.loadData()
	}

	getColumnSearchProps = dataIndex => ({
	  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		<div style={{ padding: 8 }}>
		  <Input
			ref={node => {
			  this.searchInput = node;
			}}
			placeholder={`搜索 ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ width: 188, marginBottom: 8, display: 'block' }}
		  />
		  <Space>
			<Button
			  type="primary"
			  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  icon={<SearchOutlined />}
			  size="small"
			  style={{ width: 90 }}
			>
			  搜索
			</Button>
			<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  重置
			</Button>
		  </Space>
		</div>
	  ),
	  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	  onFilter: (value, record) =>
		record[dataIndex]
		  ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		  : '',
	  onFilterDropdownVisibleChange: visible => {
		if (visible) {
		  setTimeout(() => this.searchInput.select(), 100);
		}
	  },
	  render: text =>
		this.state.searchedColumn === dataIndex ? (
		  <Highlighter
			highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			searchWords={[this.state.searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
		  />
		) : (
		  text
		),
	});
  
	handleSearch = (selectedKeys, confirm, dataIndex) => {
	  confirm();
	  this.setState({
		searchText: selectedKeys[0],
		searchedColumn: dataIndex,
	  });
	};
  
	handleReset = clearFilters => {
	  clearFilters();
	  this.setState({ searchText: '' });
	};
  
	render() {
		if(this.state.loading){
            return <Spin size="large"/>
        }
		const columns = [
            {
				title: '姓名',
				dataIndex: 'username',
				key: 'username',
				...this.getColumnSearchProps('username'),
            },
            {
				title: '学号',
				dataIndex: 'student_num',
				key: 'student_num',
				...this.getColumnSearchProps('student_num'),
            },
            {
				title: '家长邮箱',
				dataIndex: 'parent_email',
				key: 'parent_email',
				...this.getColumnSearchProps('parent_email'),
			},
			{
				title: '家长电话',
				dataIndex: 'parent_phone',
				key: 'parent_phone',
				...this.getColumnSearchProps('parent_phone'),
			},
            {
				title: '放假状态',
				dataIndex: 'holiday',
				key: 'holiday',
				width: 100,
				render: text=>{
					if(text){
						return <Text type="success">正在放假</Text>
					}else{
						return <Text type="success">正常上课</Text>
					}
				}
            },
            {
				title: '状态',
				dataIndex: 'proxy_notice',
				key: 'proxy_notice',
				render: (text,record) =>{
                    if(text === 1){
                        return '已联系'
                    }else{
                        return <>
                            <Button type='primary' onClick={()=>this.confirmContact(record.s_id)}>
                                设为已联系
                            </Button>
                        </>
                    }
                },
				align:'center'
			}
        ];
	  	return <Table bordered columns={columns} dataSource={this.state.data} pagination={{ pageSize: 20 }} size="middle"/>;
	}
}

class ViewProxyStudent extends React.Component {
    render(){
        return <>
            <SearchableTable />
        </>
    }
}

export default ViewProxyStudent;