import React, { Component } from 'react';
import { Form,Input,Layout, Checkbox, Select,Row,Col,Button,Result, message,DatePicker, InputNumber } from 'antd';
import TopNav from '../components/TopNav';
import { studentSignup,getVerificationCode } from '../request/axios';
import Footer1 from '../components/Footer1';
import { AlertMsg, TransText } from '../components/PublicMethods';
import { SmileOutlined } from '@ant-design/icons';
import { getProxy } from '../request/axios'
import cookie from  'react-cookies'
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';

const { Header, Content, Footer } = Layout;
const { Option } = Select;

class Register extends Component {
    constructor() {
        super()
        this.state = {
            student_num: null,
            username: '',
            parent_email: '',
            password: '',
            confirm: '',
            proxy: false,
            proxyList: [],
            emailCoolDown:60
        }
    }

    componentDidMount() {
        getProxy().then(data => {
            const { data: { result, status, tips } } = data
            if (status !== 100) {
                AlertMsg({ message: "错误", des: tips })
            } else {
                this.setState({
                    proxyList: result
                })
            }
        }).catch(err => {
            AlertMsg({ message: "网络错误", des: "请求数据出错" })
        })
    }

    onSelectChange(value) {
        this.setState({
            proxy:value
        })
    }

    proxyTrue(e) {
        this.setState({
            proxy: e.target.checked
        })
    }

    sendEmail(){
        const email = this.state.parent_email || ""
        this.setState({
            emailCoolDown:59
        })
        this.timer = setInterval(()=>{
            const {emailCoolDown} = this.state
            if(emailCoolDown>0){
                this.setState({
                    emailCoolDown:emailCoolDown-1
                })
            }else{
                clearInterval(this.timer)
                this.setState({
                    emailCoolDown:60
                })
            }
        },1000)
        getVerificationCode({email,},data=>{
            const {data:{result,status}} = data
            if(status!==100){
                message.error("获取验证码错误")
                return
            }
            cookie.save(email,result,{maxAge:300})
            message.info("验证码已发送")
        }).catch(err=>{
            console.log(err)
        })
    }

    handleSubmit(data) {
        let {proxy,confirm,verification_code,dob,...submitDate} = data
        let isVerified = false
        if(cookie.load(submitDate.parent_email)===verification_code){
            isVerified = true
        }
        if(isVerified){
            submitDate.dob = new Date(dob).getTime()
            if(this.state.proxy){
                submitDate.proxy = this.state.proxy
            }
            studentSignup(submitDate, request_res => {
                const { data: { result, status, tips = "查询错误" } } = request_res
                if (status !== 100) {
                    AlertMsg({ message: "注册新学生出错", des: tips })
                } else {
                    this.setState({
                        student_num: result
                    })
                }
            }).catch(err => {
                console.log(err)
                AlertMsg({ message: "网络错误", des: "请求数据出错" })
            })
        }else{
            message.warning("验证码不正确!")
        }
    }

    handleInputChange = (event) => {
        event.preventDefault()
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    render() {
        if (this.state.student_num != null) {
            return <Result
                icon={<SmileOutlined />}
                title={"注册成功！您的学号为：" + this.state.student_num}
                subTitle="您的学号将用于登录，是辨识您学生身份的标识，请保存好。我们会尽快与您联系！"
                extra={[
                    <Button type="primary" onClick={() => window.location.href = "/login"}>
                        去登录
                </Button>,
                    <Button onClick={() => window.location.href = "/"}>回主页</Button>,
                ]}
            />
        } else {
            const { proxyList,emailCoolDown,parent_email } = this.state
            const tailFormItemLayout = {
                wrapperCol: {
                    xs: {
                        span: 24,
                        offset: 0,
                    },
                    sm: {
                        span: 16,
                        offset: 1,
                    },
                },
            };
            const formItemLayout = {
                labelCol: {
                    xs: {
                        span: 24,
                    },
                    sm: {
                        span: 8,
                    },
                },
                wrapperCol: {
                    xs: {
                        span: 24,
                    },
                    sm: {
                        span: 16,
                    },
                },
            };
            return <>
                <Layout>
                    <Header style={{ backgroundColor: 'white' }}>
                        <TopNav />
                    </Header>
                    <Content
                        style={{ padding: '50px 50px' }}>
                        <section className='form-register'>
                            <Form
                                {...formItemLayout}
                                onFinish={(value)=>this.handleSubmit(value)}
                                name="register"
                                scrollToFirstError
                            >
                                <Form.Item
                                    name="parent_email"
                                    label={TransText({'CN':'邮箱', 'EN':'Email'})}
                                    rules={[
                                        {
                                            required: true,
                                            message: TransText({'CN':'请输入邮箱地址！', 'EN':'Please input email address!'}),
                                        },
                                    ]}
                                    {...tailFormItemLayout}
                                >
                                    <Input
                                        name="parent_email"
                                        onChange={this.handleInputChange}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="verification_code"
                                    label={TransText({'CN':'验证码', 'EN':'Verification Code'})}
                                    rules={[
                                        {
                                            required: true,
                                            message: TransText({'CN':'请输入验证码！', 'EN':'Please input verification code!'}),
                                        },
                                    ]}
                                    {...tailFormItemLayout}
                                >
                                    <Row>
                                        <Col span={18}>
                                            <Input
                                                name=""
                                            />
                                        </Col>
                                        <Col span={6}>
                                            <Button 
                                                disabled={emailCoolDown!==60 || parent_email===""}
                                                onClick={()=>this.sendEmail(this.state.parent_email)}
                                            style={{width:"100%"}}>
                                                {
                                                    emailCoolDown!==60?
                                                    `${emailCoolDown}s`
                                                    :
                                                    TransText({'CN':'获取验证码', 'EN':'Send email'})
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item
                                    name="parent_phone"
                                    label={TransText({'CN':'家长电话', 'EN':'Phone Number'})}
                                    rules={[
                                        {
                                            required: true,
                                            message: TransText({'CN':'请输入电话号码！', 'EN':'Please input phone number!'}),
                                        },
                                    ]}
                                    {...tailFormItemLayout}
                                >
                                    <Input
                                        name="parent_phone"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...tailFormItemLayout}
                                    name="username"
                                    label={TransText({'CN':'学生姓名', 'EN':'Student Name'})}
                                    rules={[
                                        {
                                            required: true,
                                            message: TransText({'CN':'请输入学生姓名！', 'EN':'Please input your student name!'}),
                                        },
                                    ]}
                                >
                                    <Input name="username" />
                                </Form.Item>
                                <Form.Item
                                    {...tailFormItemLayout}
                                    name="dob"
                                    label={TransText({'CN':'学生生日', 'EN':'Date of birth'})}
                                    rules={[
                                        {
                                            required: true,
                                            message: TransText({'CN':'请输入学生生日！', 'EN':'Please input student birthday!'}),
                                        },
                                    ]}
                                >
                                    <DatePicker locale={locale} />
                                </Form.Item>
                                <Form.Item
                                    {...tailFormItemLayout}
                                    name="grade"
                                    label={TransText({'CN':'年级', 'EN':'Grade'})}
                                    rules={[
                                        {
                                            required: true,
                                            message: TransText({'CN':'请输入孩子的年级', 'EN':'Please input student grade!'}),
                                        },
                                    ]}
                                >
                                    <InputNumber min={1} max={99} />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label={TransText({'CN':'密码', 'EN':'Password'})}
                                    rules={[
                                        {
                                            required: true,
                                            message: TransText({'CN':'请输入密码！', 'EN':'Please input your password!'}),
                                        },
                                    ]}
                                    hasFeedback
                                    {...tailFormItemLayout}
                                >
                                    <Input.Password name="password" />
                                </Form.Item>
                                <Form.Item
                                    name="confirm"
                                    label={TransText({'CN':'确认密码', 'EN':'Confirm Password'})}
                                    dependencies={['password']}
                                    hasFeedback
                                    {...tailFormItemLayout}
                                    rules={[
                                        {
                                            required: true,
                                            message: TransText({'CN':'请再次输入密码！', 'EN':'Please confirm your password!'}),
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(TransText({'CN':'两次密码不正确!', 'EN':'Password did not match!'}));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password name="confirm" />
                                </Form.Item>
                                <Form.Item
                                    name="proxy"
                                    label={TransText({'CN':'选择代理', 'EN':'Select Proxy'})}
                                    {...tailFormItemLayout}
                                    rules={[
                                        {
                                            required: this.state.proxy?(typeof this.state.proxy==='number'?false:true):false,
                                            message: TransText({'CN':'请选择代理账号！', 'EN':'Please input your proxy!'}),
                                        },
                                    ]}
                                >
                                    <Checkbox style={{}} onChange={(e) => this.proxyTrue(e)}></Checkbox>
                                    <Select
                                        disabled = {!this.state.proxy}
                                        showSearch
                                        style={{ width: 200 }}
                                        placeholder={TransText({'CN':'选择或搜索代理', 'EN':'Select or search'})}
                                        optionFilterProp="children"
                                        onChange={this.onSelectChange.bind(this)}
                                    >
                                        {
                                            proxyList.map((proxy, index) => {
                                                return <Option key={index} value={proxy.admin_id}>{`${proxy.username} : ${proxy.admin_num}`}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Button type="primary" htmlType="submit">{TransText({'CN':'立即注册', 'EN':'Register Now'})}</Button>
                            </Form>
                        </section>
                    </Content>
                    <Footer style={{ textAlign: 'center', backgroundColor: '#fafafa' }}>
                        <Footer1 />
                    </Footer>
                </Layout>
            </>
        }
    }
}

export default Register;