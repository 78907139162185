import React from 'react';
import { Table, Input, Button, Space, Popconfirm, message, Tabs, Tooltip, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { AdminGetAssigninfo, AdminDeleteAssign } from '../../request/axios';
import { AlertMsg } from '../PublicMethods';

const { TabPane } = Tabs;

class SearchableTable extends React.Component {
	state = {
	  searchText: '',
	  searchedColumn: '',
	  data: [],
	  loading:true
	};
  
    confirm = (t_id, sub_id) => {
		AdminDeleteAssign({t_id:t_id, sub_id:sub_id,admin_id:sessionStorage.getItem('admin_id')},request_res=>{
            const {data:{status,tips="查询错误"}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"删除分配出错！"})
            }else{
				message.success('此授课安排已删除！');
				this.loadData()
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
		this.loadData()
    }

    cancel = (e) =>{
        console.log(e);
        message.error('Click on No');
	}
	
	loadData(){
		AdminGetAssigninfo({admin_id:sessionStorage.getItem('admin_id')},request_res=>{
            const {data:{result,status,tips="查询错误"}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"查询课程数据出错"})
            }else{
				result.forEach((el,index)=>{
					el.key = index
				})
				this.setState({
					data: result,
					loading:false
				})
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
	}

	componentDidMount(){
		this.loadData()
	}
	
	getColumnSearchProps = dataIndex => ({
	  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		<div style={{ padding: 8 }}>
		  <Input
			ref={node => {
			  this.searchInput = node;
			}}
			placeholder={`搜索 ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ width: 188, marginBottom: 8, display: 'block' }}
		  />
		  <Space>
			<Button
			  type="primary"
			  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  icon={<SearchOutlined />}
			  size="small"
			  style={{ width: 90 }}
			>
			  搜索
			</Button>
			<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  重置
			</Button>
		  </Space>
		</div>
	  ),
	  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	  onFilter: (value, record) =>
		record[dataIndex]
		  ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		  : '',
	  onFilterDropdownVisibleChange: visible => {
		if (visible) {
		  setTimeout(() => this.searchInput.select(), 100);
		}
	  },
	  render: text =>
		this.state.searchedColumn === dataIndex ? (
		  <Highlighter
			highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			searchWords={[this.state.searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
		  />
		) : (
		  text
		),
	});
  
	handleSearch = (selectedKeys, confirm, dataIndex) => {
	  confirm();
	  this.setState({
		searchText: selectedKeys[0],
		searchedColumn: dataIndex,
	  });
	};
  
	handleReset = clearFilters => {
	  clearFilters();
	  this.setState({ searchText: '' });
	};
  
	render() {
		if(this.state.loading){
            return <Spin size="large"/>
        }
		const columns = [
            {
				title: '姓名',
				dataIndex: 'teacher_name',
				key: 'teacher_name',
				...this.getColumnSearchProps('teacher_name'),
            },
            {
				title: '工号',
				dataIndex: 'teacher_num',
				key: 'teacher_num',
				...this.getColumnSearchProps('teacher_num'),
            },
			{
				title: '课程名称',
				dataIndex: 'name',
				key: 'name',
                width: 200,
				...this.getColumnSearchProps('name'),
            },
			{
				title: '课程代码',
				dataIndex: 'subject_code',
				key: 'subject_num',
				...this.getColumnSearchProps('subject_num'),
			},
			{
				title: '可用上课时间',
				dataIndex: 'available_time',
				key: 'available_time',
				ellipsis: {
                    showTitle: false,
                },
                render: available_time => (
                    <Tooltip placement="topLeft" title={available_time}>
                      {available_time}
                    </Tooltip>
                ),
				align:'center'
			},
			{
				title: '被预约时间',
				dataIndex: 'taken_time',
				key: 'taken_time',
                ellipsis: {
                    showTitle: false,
                },
                  render: taken_time => (
                    <Tooltip placement="topLeft" title={taken_time}>
                      {taken_time}
                    </Tooltip>
                ),
				align:'center'
			},
            {
				title: '操作',
				key: 'action',
				render: (record) => (
					<Space size="middle">
					<Popconfirm
                        title="你确定要删除吗？谨慎操作！"
                        onConfirm={()=>this.confirm(record.t_id, record.sub_id)}
                        onCancel={this.cancel}
                        okText="Yes"
                        cancelText="No"
                        style={{textAlign:'left'}}
                        icon={null}
                    >
                        <a href="/admin">删除</a>
                    </Popconfirm>
					</Space>
				),
				align:'center'
            },
        ];
	  	return <Table bordered columns={columns} dataSource={this.state.data} pagination={{ pageSize: 20 }} size="middle"/>;
	}
}

class ViewAssign extends React.Component {
    render(){
        return <>
			<Tabs defaultActiveKey="1" type="card" centered>
				<TabPane tab="授课安排" key="1">
					<SearchableTable />
				</TabPane>
			</Tabs>
        </>
    }
}

export default ViewAssign;