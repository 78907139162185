import React from 'react';
import { Typography, Row, Col } from 'antd';
import {TransText} from '../PublicMethods'
import { getCourse } from '../../request/axios'
import { AlertMsg} from '../PublicMethods'
import {CourseCard} from '../course/CourseCard'
const { Title } = Typography;

class Content2 extends React.Component {
	constructor(){
        super()
        this.state={
            star_course : [],
        }
    }
	componentDidMount(){
		getCourse({star:1},data=>{
            const {data:{result,status,tips}} = data
            if(status!==100){
                AlertMsg({message:"课程错误",des:tips})
            }else{
                this.setState({
                    star_course:result,
                })
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
	}
	render() {
		const {star_course} = this.state
		return <>
		<div id="content2" style={{ minHeight:560, backgroundColor:'#fafafa' }}>
					<div id="innerConent" style={{ height:'100%', padding:'96px 24px', margin:'auto' }}>
						<div id="title-wrapper" style={{ marginBottom:70 }}>
							<Title level={2}><TransText CN='明星课程' EN='Star Courses' /></Title>
						</div>
						<div id="point-wrapper">
							<Row>
								{
									star_course.map((course,index)=>{
										return (
											<Col key={index} xs={24} sm={12} md={12} lg={8}>
												{CourseCard(course)}
											</Col>
										)
									})
								}
							</Row>
						</div>
					</div>
		</div>
			</>;
	}
}

export default Content2;