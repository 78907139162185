import React from 'react';
import { Table, Input, Button, Space, Collapse, Tabs , Badge, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { AdminGetLessonInfo, axiosAll } from '../../request/axios'
import { AlertMsg, DateFormat } from '../PublicMethods';
import {GlobalContext} from "../../request/context"
import '../../style/components/admin/view_lesson.css'

const {Provider,Consumer} = GlobalContext
const { Panel } = Collapse;
const { TabPane } = Tabs;

class SearchableTable extends React.Component {
	state = {
	  searchText: '',
	  searchedColumn: ''
	}

	getColumnSearchProps = dataIndex => ({
	  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		<div style={{ padding: 8 }}>
		  <Input
			ref={node => {
			  this.searchInput = node;
			}}
			placeholder={`搜索 ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ width: 188, marginBottom: 8, display: 'block' }}
		  />
		  <Space>
			<Button
			  type="primary"
			  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  icon={<SearchOutlined />}
			  size="small"
			  style={{ width: 90 }}
			>
			  搜索
			</Button>
			<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  重置
			</Button>
		  </Space>
		</div>
	  ),
	  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	  onFilter: (value, record) =>
		record[dataIndex]
		  ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		  : '',
	  onFilterDropdownVisibleChange: visible => {
		if (visible) {
		  setTimeout(() => this.searchInput.select(), 100);
		}
	  },
	  render: text =>
		this.state.searchedColumn === dataIndex ? (
		  <Highlighter
			highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			searchWords={[this.state.searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
		  />
		) : (
		  text
		),
	});
  
	handleSearch = (selectedKeys, confirm, dataIndex) => {
	  confirm();
	  this.setState({
		searchText: selectedKeys[0],
		searchedColumn: dataIndex,
	  });
	};
  
	handleReset = clearFilters => {
	  clearFilters();
	  this.setState({ searchText: '' });
	};
  
	render() {
		const columns = [
            {
				title: '开课时间',
				dataIndex: 'lesson_time',
                key: 'lesson_time',
                width: 100,
                render: text => (
                    DateFormat('M/D H:m',parseInt(text))
                ),
				align:'center'
            },
            {
				title: '时长',
				dataIndex: 'duration',
                key: 'duration',
                width: 100,
                render: text => (
                    text+'分钟'
                ),
				align:'center'
            },
            {
				title: '学生姓名',
				dataIndex: 's_name',
                key: 's_name',
                ...this.getColumnSearchProps('s_name'),
            },
            {
				title: '学生学号',
				dataIndex: 'student_num',
                key: 'student_num',
                ...this.getColumnSearchProps('student_num'),
            },
            {
				title: '教师姓名',
				dataIndex: 't_name',
                key: 't_name',
                ...this.getColumnSearchProps('t_name'),
            },
            {
				title: '教师工号',
				dataIndex: 'teacher_num',
                key: 'teacher_num',
				...this.getColumnSearchProps('teacher_num'),
            },
            {
				title: '课程代码',
				dataIndex: 'subject_code',
                key: 'subject_code',
				...this.getColumnSearchProps('subject_code'),
            },
            {
				title: 'zoom',
				dataIndex: 'zoom',
                key: 'zoom',
                ...this.getColumnSearchProps('zoom'),
            },
        ];
	return <Consumer>{value=>{
		return <Table bordered columns={columns} dataSource={value} pagination={{ pageSize: 20 }} size="middle"/>
	}}</Consumer>
	}
}

class ViewLesson extends React.Component {
    state = {
		data: [[],[],[],[],[],[],[]],
		data_temp_time: [[],[],[],[],[],[],[]],
		loading:true
    }

    loadData(){
		let new_data = [[],[],[],[],[],[],[]]
		let new_data_temp_time = [[],[],[],[],[],[],[]]
		axiosAll([
			AdminGetLessonInfo({admin_id:sessionStorage.getItem('admin_id')}),
			AdminGetLessonInfo({admin_id:sessionStorage.getItem('admin_id'),if_temp:true})
		],result=>{
			result[0].data.result.forEach((el,index)=>{
                el.key = index
				let time = new Date(parseInt(el.lesson_time))
				if(sessionStorage.getItem('auth_id') === '6'){
					if(el.proxy.toString() === sessionStorage.getItem('admin_id')){
						new_data[time.getDay()].push(el)
					}
				}else{
					new_data[time.getDay()].push(el)
				}	
			})
			result[1].data.result.forEach((el,index)=>{
                el.key = index
				let time = new Date(parseInt(el.lesson_time))
				if(sessionStorage.getItem('auth_id') === '6'){
					if(el.proxy.toString() === sessionStorage.getItem('admin_id')){
						new_data_temp_time[time.getDay()].push(el)
					}
				}else{
					new_data_temp_time[time.getDay()].push(el)
				}
			})
			this.setState({
				data:new_data,
				data_temp_time:new_data_temp_time,
				loading:false
			})
		},err=>{
			AlertMsg({message:'网络错误',des:'网络连接出错'})
			console.log(err)
		})
	}

	componentDidMount(){
		this.loadData()
	}

    render(){
		if(this.state.loading){
            return <Spin size="large"/>
        }
        const day_map = ['日','一','二','三','四','五','六']
        return <>
			<Tabs type="card" centered>
				<TabPane tab="正常课时" key="1">
					<Collapse accordion bordered={false} defaultActiveKey={[new Date().getDay()+'']}>
						{this.state.data.map((item,index)=>{
							return <Panel header={item.length?
							<Badge count={item.length} style={{right:'-50%',top:"50%", backgroundColor: '#40a9ff' }}>
								{'星期'+day_map[index]+`${new Date().getDay()===index?" (今)":""}`}
								</Badge>
							:'星期'+day_map[index]+`${new Date().getDay()===index?" (今)":""}`} key={index}>
								<Provider value = {item}><SearchableTable/></Provider>
							</Panel>
						})}
					</Collapse>
				</TabPane>
				<TabPane tab="临时课时" key="2">
					<Collapse accordion bordered={false} defaultActiveKey={[new Date().getDay()+'']}>
						{this.state.data_temp_time.map((item,index)=>{
							return <Panel header={item.length?<Badge count={item.length} style={{right:'-50%',top:"50%", backgroundColor: '#40a9ff' }}>{'星期'+day_map[index]+`${new Date().getDay()===index?" (今)":""}`}</Badge>:'星期'+day_map[index]+`${new Date().getDay()===index?" (今)":""}`} key={index}>
								<Provider value = {item}><SearchableTable/></Provider>
							</Panel>
						})}
					</Collapse>
				</TabPane>
			</Tabs>
            
        </>
    }
}

export default ViewLesson;