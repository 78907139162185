import React from 'react';
import { Table, Input, Button, Space, Progress, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { AdminGetStudentProgress } from '../../request/axios';
import { AlertMsg } from '../PublicMethods';

class SearchableTable extends React.Component {
	state = {
	  searchText: '',
	  searchedColumn: '',
	  data: [],
	  loading:true
	};

	loadData(){
		AdminGetStudentProgress({admin_id:sessionStorage.getItem('admin_id')},request_res=>{
            const {data:{result,status,tips="查询错误"}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"查询课程数据出错"})
            }else{
				let new_data = []
				result.forEach((el,index)=>{
					el.key = index
					if(sessionStorage.getItem('auth_id') === '6'){
						if(el.proxy.toString() === sessionStorage.getItem('admin_id')){
							new_data.push(el)
						}
					}else{
						new_data.push(el)
					}	
				})
                this.setState({
					data: new_data,
					loading:false
				})
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
	}

	componentDidMount(){
		this.loadData()
	}
    
	getColumnSearchProps = dataIndex => ({
	  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		<div style={{ padding: 8 }}>
		  <Input
			ref={node => {
			  this.searchInput = node;
			}}
			placeholder={`搜索 ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ width: 188, marginBottom: 8, display: 'block' }}
		  />
		  <Space>
			<Button
			  type="primary"
			  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  icon={<SearchOutlined />}
			  size="small"
			  style={{ width: 90 }}
			>
			  搜索
			</Button>
			<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  重置
			</Button>
		  </Space>
		</div>
	  ),
	  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	  onFilter: (value, record) =>
		record[dataIndex]
		  ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		  : '',
	  onFilterDropdownVisibleChange: visible => {
		if (visible) {
		  setTimeout(() => this.searchInput.select(), 100);
		}
	  },
	  render: text =>
		this.state.searchedColumn === dataIndex ? (
		  <Highlighter
			highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			searchWords={[this.state.searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
		  />
		) : (
		  text
		),
	});
  
	handleSearch = (selectedKeys, confirm, dataIndex) => {
	  confirm();
	  this.setState({
		searchText: selectedKeys[0],
		searchedColumn: dataIndex,
	  });
	};
  
	handleReset = clearFilters => {
	  clearFilters();
	  this.setState({ searchText: '' });
	};
  
	render() {
		if(this.state.loading){
            return <Spin size="large"/>
        }
		const columns = [
            {
				title: '姓名',
				dataIndex: 'username',
				key: 'username',
				...this.getColumnSearchProps('username'),
				width:100
            },
            {
				title: '学号',
				dataIndex: 'student_num',
				key: 'student_num',
				...this.getColumnSearchProps('student_num'),
				width:100
            },
			{
				title: '课程名称',
				dataIndex: 'name',
				key: 'name',
				width: 200,
				...this.getColumnSearchProps('name'),
            },
			{
				title: '课程代码',
				dataIndex: 'subject_code',
				key: 'subject_num',
				...this.getColumnSearchProps('subject_num'),
				width:100
            },
            {
				title: '已购买',
				dataIndex: 'total',
				key: 'total',
				width:70,
				align:'center'
            },
            {
				title: '删除/暂停',
				dataIndex: 'suspend',
				key: 'suspend',
				width:100,
				align:'center'
            },
            {
				title: '上课进度',
				render: record=>{
					return <Progress percent={parseInt(record.pathway/record.progress*100)} 
					format={percent => `${record.pathway} / ${record.progress}`} 
					strokeColor={
						record.progress-record.pathway<=5?
						record.progress===record.pathway?
						{
							'100%': '#41ae3c',
							'0%': '#add5a2',
						}
						:
						{
						'0%': '#e6d2d5',
						'100%': '#f03752',
						}:{
							'0%': '#108ee9',
							'100%': '#b1db9f',
						}} size="small" />
				},
				width:200,
				align:'center'
			},
        ];
	  	return <Table bordered columns={columns} dataSource={this.state.data} pagination={{ pageSize: 20 }} size="middle"/>;
	}
}

class ViewProgress extends React.Component {
    render(){
        return <>
            <SearchableTable />
        </>
    }
}

export default ViewProgress;