import React from 'react';
import { Form, Input, Button, Typography,Result,Space,Radio,InputNumber,Upload, message  } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { uploadFileToServer } from '../../request/axios';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { Text } = Typography;

class AddSubject extends React.Component {

    state = {
        success: null,
        fileData:[],
        percent:0,
    }

    onFinish = (values) => {
        const loading = message.loading("正在上传",0)
        const { name_en, name_cn, skill, difficulty,description, ...send_value } = values
        send_value.name = `${name_en}||${name_cn}`
        send_value.config = `{"skill":"${skill}","difficulty":"${difficulty}"}`
        send_value.admin_id = sessionStorage.getItem('admin_id')
        let desObj = {}
        description.forEach(des=>{
            desObj[des.title || "*"] = des.body
        })
        send_value.description = JSON.stringify(desObj)
        const formData = new FormData()
        for(let key in send_value){
            if(key==="img_src"){
                formData.append(key,send_value[key]?send_value[key].file:null)
            }else{
                formData.append(key,send_value[key])
            }
        }
        uploadFileToServer(formData,data=>{
            const {data:{tips,status}} = data
            if(status!==100){
                console.log(tips)
                return
            }
            setTimeout(loading, 1)
            message.success("添加课程成功！")
            this.setState({
                success:true
            })
        })
    }

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    
    beforeUploadHandle=(file)=>{
        this.setState(({fileData})=>({
            fileData:[...fileData,file],
        }),()=>console.log(this.state.fileData))
        return false;
    }

    
    fileChange=(params)=>{
        const {file,fileList}=params;
        if(file.status==='uploading'){
            setTimeout(()=>{
                this.setState({
                    percent:fileList.percent    
                })
            },1000)       
        }
    }
    
    fileRemove=(file)=>{
        this.setState(({fileData})=>{
            const index=fileData.indexOf(file);
            const newFileList=fileData.slice();
            newFileList.splice(index,1);
            return {
                fileData:newFileList
            }
        })
    }

    render() {
        if (this.state.success != null) {
            return <Result
                icon={<SmileOutlined />}
                title={"添加课程成功！"}
                subTitle="前往'全部课程'查看。"
                extra={[
                    <Button type="primary" onClick={() => this.setState({ success: null })}>
                        继续添加
                    </Button>
                ]}
            />
        } else {
            const layout = {
                labelCol: {
                    span: 8,
                },
                wrapperCol: {
                    span: 8,
                },
            }
            const tailLayout = {
                wrapperCol: {
                    offset: 6,
                    span: 12,
                },
            }
            return <>
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        label="课程代码"
                        name="subject_code"
                        rules={[
                            {
                                required: true,
                                message: '请输入新课的课程代码，确保不要重复！',
                            },
                        ]}
                    >
                        <Input placeholder="MATH2001" />
                    </Form.Item>

                    <Form.Item
                        label="课程名称(中)"
                        name="name_cn"
                        rules={[
                            {
                                required: true,
                                message: '请输入一个课程中文名称！',
                            },
                        ]}
                    >
                        <Input placeholder="深度学习" />
                    </Form.Item>

                    <Form.Item
                        label="课程名称(英)"
                        name="name_en"
                        rules={[
                            {
                                required: true,
                                message: '请输入一个课程英文名称！',
                            },
                        ]}
                    >
                        <Input placeholder="Deep Learning" />
                    </Form.Item>
                    <Form.Item
                        label="课程描述"
                        name="description"
                        rules={[
                        {
                            required: true,
                            message: '请输入课程描述！',
                        },
                        ]}
                    >
                        <Form.List name="description">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(field => (
                                        <Space key={field.key} align="baseline">
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'title']}
                                                fieldKey={[field.fieldKey, 'title']}
                                            >
                                                <Input placeholder="Title or Empty" />
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'body']}
                                                fieldKey={[field.fieldKey, 'body']}
                                                rules={[{ required: true, message: 'Missing main description' }]}
                                            >
                                                <Input placeholder="Main Description" />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add field
                                    </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                    <Form.Item
                        label="技能锻炼"
                        name="skill"
                        rules={[
                            {
                                required: true,
                                message: '请输入锻炼技能！',
                            },
                        ]}
                    >
                        <Input placeholder="写作、口语" />
                    </Form.Item>

                    <Text type="secondary">
                        难度指数为1-5之间的整数。
                    </Text>
                    <Form.Item
                        label="难度指数"
                        name="difficulty"
                        rules={[
                            {
                                type:"number",
                                required: true,
                                min: 1,
                                max: 5,
                                message: '请输入难度指数！',
                            },
                        ]}
                    >
                        <InputNumber min={1} max={5} />
                    </Form.Item>
                    <Form.Item
                        label="年级"
                        name="grade"
                        rules={[
                            {
                                type:"number",
                                required: true,
                                min: 1,
                                max: 5,
                                message: '请输入难度指数！',
                            },
                        ]}
                    >
                        <InputNumber min={1} max={5} />
                    </Form.Item>
                    <Form.Item
                        label="分类id"
                        name="category"
                        rules={[
                            {
                                required: true,
                                message: '请输入分类id！',
                            },
                        ]}
                    >
                        <Input placeholder="1" />
                    </Form.Item>
                    <Form.Item
                        label="所需总课时"
                        name="total_hour"
                        rules={[
                            {
                                type:"number",
                                required: true,
                                min: 1,
                                max: 100,
                                message: '请输入完成该课所需的总课时！',
                            },
                        ]}
                    >
                        <InputNumber min={1} max={100} placeholder="30" />
                    </Form.Item>

                    <Form.Item
                        label="单节课时时长"
                        name="duration"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Radio.Group>
                        <Radio.Button value={25} style={{marginRight:5}}>25分钟</Radio.Button>
                        <Radio.Button value={50} style={{marginRight:5}}>50分钟</Radio.Button>
                        <Radio.Button value={60} >1小时</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label="是否是明星课程"
                        name="star"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Radio.Group>
                        <Radio.Button value="1" style={{margin:"0 10px"}}>是</Radio.Button>
                        <Radio.Button value="0">否</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Text type="secondary">
                        图片要求长宽比1280：853<br/>
                    </Text>
                    <Form.Item
                        label="课程图片"
                        name="img_src"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Upload fileList={this.state.fileData} beforeUpload={this.beforeUploadHandle} onChange={this.fileChange} onRemove={this.fileRemove}>
                            <Button>上传文件</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            新增课程
                        </Button>
                    </Form.Item>
                </Form>
            </>
        }
    }
}

export default AddSubject;