import React from 'react';
import { Table, Input, Button, Space, Popconfirm, message, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { GetAllAdmin, DeleteAdmin } from '../../request/axios';
import { AlertMsg } from '../PublicMethods';

class SearchableTable extends React.Component {
	state = {
	  searchText: '',
	  searchedColumn: '',
	  data: [],
	  loading:true
	};
    
    confirm = (admin_id_del) => {
        DeleteAdmin({admin_id:sessionStorage.getItem('admin_id'),admin_id_del:admin_id_del},request_res=>{
            const {data:{status,tips="查询错误"}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"删除管理员出错！"})
            }else{
				message.success('此管理员已删除！');
				this.loadData()
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
    }

    cancel = (e) =>{
        console.log(e);
        message.error('Click on No');
	}
	
	loadData(){
        GetAllAdmin({admin_id:sessionStorage.getItem('admin_id')},data=>{
			const {data:{result,status,tips}} = data
			if(status!==100){
				AlertMsg({message:'数据错误',des:tips})
				return
			}
			result.forEach((el,index)=>{
				el.key = index
			})
			this.setState({
				data: result,
				loading:false
			})
		}).catch(err=>{
			AlertMsg({message:'网络错误',des:'网络连接出错'})
			console.log(err)
		})
	}

	componentDidMount(){
		this.loadData()
	}

	getColumnSearchProps = dataIndex => ({
	  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		<div style={{ padding: 8 }}>
		  <Input
			ref={node => {
			  this.searchInput = node;
			}}
			placeholder={`搜索 ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ width: 188, marginBottom: 8, display: 'block' }}
		  />
		  <Space>
			<Button
			  type="primary"
			  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  icon={<SearchOutlined />}
			  size="small"
			  style={{ width: 90 }}
			>
			  搜索
			</Button>
			<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  重置
			</Button>
		  </Space>
		</div>
	  ),
	  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	  onFilter: (value, record) =>
		record[dataIndex]
		  ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		  : '',
	  onFilterDropdownVisibleChange: visible => {
		if (visible) {
		  setTimeout(() => this.searchInput.select(), 100);
		}
	  },
	  render: text =>
		this.state.searchedColumn === dataIndex ? (
		  <Highlighter
			highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			searchWords={[this.state.searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
		  />
		) : (
		  text
		),
	});
  
	handleSearch = (selectedKeys, confirm, dataIndex) => {
	  confirm();
	  this.setState({
		searchText: selectedKeys[0],
		searchedColumn: dataIndex,
	  });
	};
  
	handleReset = clearFilters => {
	  clearFilters();
	  this.setState({ searchText: '' });
	};
  
	render() {
		if(this.state.loading){
            return <Spin size="large"/>
        }
		const columns = [
            {
                title: '员工名字',
                dataIndex: 'username',
				key: 'username',
				...this.getColumnSearchProps('username'),
            },
            {
                title: '员工工号',
                dataIndex: 'admin_num',
				key: 'admin_num',
				...this.getColumnSearchProps('admin_num'),
            },
            {
                title: '邮箱',
                dataIndex: 'email',
				key: 'email',
				...this.getColumnSearchProps('email'),
			},
            {
                title: '身份',
                dataIndex: 'role_name',
				key: 'role_name',
                ...this.getColumnSearchProps('role_name'),
            },
            {
                title: '身份描述',
                dataIndex: 'description',
				key: 'description',
				align:'center'
            },
            {
                title: '类型',
                dataIndex: 'type',
                key: 'type',
                ...this.getColumnSearchProps('type'),
            },
            {
				title: '操作',
				key: 'action',
				render: (record) => {
					if(record.auth_id===1||record.auth_id===2||record.auth_id===3){
						return <></>
					}else{
						return <>
							<Space size="middle">
								<Popconfirm
									title="你确定要删除吗？谨慎操作！"
									onConfirm={()=>this.confirm(record.admin_id)}
									onCancel={this.cancel}
									okText="Yes"
									cancelText="No"
									style={{textAlign:'left'}}
									icon={null}
								>
									<a href="/admin">删除</a>
								</Popconfirm>
							</Space>
						</>
					}
				},
				align:'center'
            },
        ];
	  	return <Table bordered columns={columns} dataSource={this.state.data} pagination={{ pageSize: 20 }} size="middle"/>;
	}
}

class ViewAdmin extends React.Component {
    render(){
        return <>
            <SearchableTable />
        </>
    }
}

export default ViewAdmin;