import React from 'react'
import { Upload, Button, message,Progress } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { GlobalContext } from '../request/context'
import { getSTSOss, updateFileString } from '../request/axios'
import OSS from 'ali-oss';
import cookie from 'react-cookies'
import { AlertMsg, TransText } from '../components/PublicMethods'

class FileUploader extends React.Component {

  constructor(props) {
    super()
    this.state = {
      fileList: [],
      uploading: false,
      disabled: false,
      progress:[],
    }
  }

  onUploadDone(name_array){
    const {progress} = this.state
    if(name_array && progress.slice(-1)[0]===1){
      //上传成功
      updateFileString({lesson_id:this.context.lesson_id,column:this.context.column,name_array,},request_res=>{
        const {data:{result,status,tips}} = request_res
        if(status!==100){
          AlertMsg({message:tips,des:"查询lesson数据出错"})
        }else{
          //操作result
          this.props.func(this.context.lesson_id, this.context.column, result, this.context.type || 0)
          this.setState({
            uploading: false,
            fileList: [],
            progress:[]
          });
          setTimeout(this.state.loading_message,1)
          message.success(TransText({'CN':'上传成功','EN':'Uploaded!'}))
        }
        }).catch(err=>{
          AlertMsg({message:"网络错误",des:"请求数据出错"})
          console.log(err.message)
        })
    }
  }

  handleUpload = () => {
    const loading = message.loading(TransText({'CN':'正在上传','EN':'Uploading!'}), 0)
    const { fileList } = this.state;

    this.setState({
      uploading: true,
    });

    async function checkCookie(_this){
      let ossConfig;
      if (cookie.load("STS")) {
        ossConfig = cookie.load("STS")
      } else {
        let stsRequest = await getSTSOss()
        const {data:{Expiration,...rest}} = stsRequest
        ossConfig = rest
        cookie.save('STS', ossConfig, { maxAge: 3590 })
      }
      const tempConfig = {
        bucket:_this.context.bucket,
        ...ossConfig
      }
      let client = new OSS(tempConfig);
      async function putObject(object_name,file,index,name_array) {
        await client.multipartUpload(object_name, file,{
          progress: function (p) {
            let [...progress] = _this.state.progress
            progress[index] = p
            _this.setState({progress,},()=>_this.onUploadDone(name_array))
          }
        });
        setTimeout(loading,1)
      }
      let name_array = []
      try {
        let date = new Date().getTime()
        fileList.forEach((file,index) => {
          let object_name = date + '_' + _this.context.s_id + '_' + _this.context.t_id + '_' + _this.context.sub_id + '_' + file.name
          name_array.push(object_name)
          if(index+1===fileList.length){
            putObject(object_name, file,index,name_array)
          }else{
            putObject(object_name, file,index)
          }
        });
      } catch (e) {
        _this.setState({
          uploading: false,
        });
        setTimeout(loading,1)
        message.warning(TransText({'CN':'上传失败!','EN':'Upload failed!'}))
      }
    }
    checkCookie(this)
  };

  render() {
    const { uploading, fileList,progress } = this.state;
    const props = {
      multiple:true,
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
      onChange: file =>{
        let length_err = false
        let regex_err = false
        let progress = []
        var regex = /^[A-z0-9_-\s]+$/g
        file.fileList.forEach(f=>{
          progress.push(0)
          if(f.name.length>50){
            length_err = true
          }
          if(regex.test(f.name)){
            regex_err = true
          }
        })
        this.setState({
          disabled:length_err||regex_err,
          progress,
        })
        if(length_err){
          message.warning(TransText({'CN':'文件名过长！(最大50)','EN':'File name length limitation reached! (max 50)'}))
        }
        if(regex_err){
          message.warning(TransText({'CN':'文件名仅限英文字母、数字、下划线、横线。','EN':'File name should contain only character, number, _ and -'}))
        }
      }
    };
    return (
      <>
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>{TransText({CN:"选择文件",EN:"Select File"})}</Button>
          {
            progress.length?
            progress.map((p,index)=>{
              return (
                <Progress key={index} percent={(p*100).toFixed(0)} status="active" />
              )
            })
            :
            <></>
          }
        </Upload>
        <Button
          type="primary"
          onClick={this.handleUpload}
          disabled={fileList.length === 0 || fileList.length > 2 || this.state.disabled}
          loading={uploading}
          style={{ marginTop: 16 }}
        >
          {uploading ? TransText({CN:"正在上传",EN:'Uploading'}) : TransText({CN:"开始上传",EN:'Start Upload'})}
        </Button>
      </>
    );
  }
}

FileUploader.contextType = GlobalContext
export default FileUploader