import React, { Component } from 'react';
import '../../style/components/course/course_info.css'
import { Empty, message, Spin, Progress } from 'antd'
import { SplitENCN, TransText, AlertMsg } from '../PublicMethods'
import { getTeacherLessons, getNextLesson, axiosAll,handleLessonState, AdminGetStudentProgress } from '../../request/axios'
import CourseDetail from '../userprofile/CourseDetail'
import NextTimeInfo from '../userprofile/NextTimeInfo'
import { GlobalContext } from '../../request/context'

const { Provider } = GlobalContext

class CourseInfo extends Component {
    constructor() {
        super()
        this.state = {
            next_lesson: {
                nextLesson: null,
                nextTemp: null
            },
            course_info: [],
            student_progress: [],
            show: null,
            index:null,
            progress: [],
            loading:true,
        }
    }
    componentDidMount() {
        const t_id = sessionStorage.getItem('t_id')
        this.axiosRequest(t_id)
    }

    axiosRequest(t_id){
        axiosAll([
            getTeacherLessons({ t_id, }),
            getNextLesson({ t_id, }),
            AdminGetStudentProgress({admin_id:1,all:true})
        ], result => {
            if (result[0].data.status + result[1].data.status !== 200) {
                AlertMsg({ message: '课程信息错误', des: (result[0].data.tips || result[1].data.tips) })
                return
            }
            this.setState({
                course_info: result[0].data.result,
                next_lesson: result[1].data.result,
                progress: result[2].data.result,
                loading:false
            })
        })
    }

    getCourseDetail(sub_id,s_id,index) {
        this.setState({
            index,
            show: 
            <Provider value={
                {
                    sub_id,
                    s_id,
                    t_id: sessionStorage.getItem('t_id'),
                    lessonState:this.handleAlterLessonState.bind(this),
                    lessons:this.state.course_info[index].lessons,
                    partner_name:this.state.course_info[index].username,
                    subject_code:this.state.course_info[index].subject_code,
                    back:() => this.setState({ show: null })
                }
            }>
                <CourseDetail />
            </Provider>
        })
    }

    handleAlterLessonState(lesson_id,state,s_id,remarks){
        let t_id = sessionStorage.getItem('t_id')
        handleLessonState({lesson_id,state,s_id,t_id,remarks,},data=>{
            const {data:{status,tips}} = data
            if(status !==100 ){
                AlertMsg({message:"Something Wrong",des:tips,type:'error'})
                return
            }
            if(state===1){
                message.success("Lesson start!")
            }
            if(state===2){
                AlertMsg({message:"End Lesson successfully",des:"Please upload lesson file in time",type:'success',callback:()=>{
                    this.axiosRequest(t_id)
                }})
            }
        })
    }

    render() {
        if(this.state.loading){
            return <Spin size="large"/>
        }
        const { course_info, show, next_lesson, progress } = this.state
        const { nextLesson, nextTemp } = next_lesson
        return (
            <section id='user-course-info'>
                {show ? show
                    :
                    <>
                        <div className='user-course-list'>
                            {
                                course_info.length ?
                                    course_info.map((course, index) => {
                                        const { sub_id, subject_code, name, username,s_id } = course
                                        let prog = null
                                        let pathway = null
                                        let completion = null
                                        progress.forEach(pro=>{
                                            if(pro.s_id === s_id && pro.sub_id === sub_id){
                                                prog = pro.progress
                                                pathway = pro.pathway
                                                completion = ~~(pathway / prog * 100)
                                            }
                                        })
                                        return (
                                            <div
                                                key={index}
                                                onClick={() => this.getCourseDetail(sub_id,s_id,index)}
                                                className='user-course-card'>
                                                <h2>
                                                    {subject_code}
                                                </h2>
                                                <div className='user-course-progress'>
                                                    <div>{TransText({CN:'进度',EN: 'Progress'})}
                                                    <Progress 
                                                        percent={completion} 
                                                        status="active" 
                                                        format={percent => `${pathway} / ${prog}`}
                                                        strokeColor={{
                                                            '0%': '#108ee9',
                                                            '100%': '#b1db9f',
                                                        }} 
                                                    /></div>
                                                </div>
                                                <div>
                                                    <span>{TransText({
                                                        CN: SplitENCN(name).CN,
                                                        EN: SplitENCN(name).EN
                                                    })}</span>
                                                    <span><span style={{ color: "#" }} className="iconfont icon-laoshi" /> {username}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <Empty description='No classes yet' />
                            }
                        </div>
                        <div className='user-course-detail'>
                            {nextLesson && <Provider value={{ title: 'Next Lesson', nextLesson, timeEnd: () =>
                                this.axiosRequest(sessionStorage.getItem('t_id'))
                             }}><NextTimeInfo /></Provider>}
                            {nextTemp && nextTemp.lesson_time && <Provider value={{ title: 'Temporary Lesson', nextLesson: nextTemp, timeEnd: () =>
                             this.axiosRequest(sessionStorage.getItem('t_id')) }}><NextTimeInfo /></Provider>}
                        </div>
                    </>
                }
            </section>
        )
    }
}

export default CourseInfo