import React from 'react'
import { Layout, Typography, Table, Input, Button, Space, message  } from 'antd'
import { SplitENCN, TransText, AlertMsg, DateFormat } from '../../components/PublicMethods';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { GetApplication, ReplyToApplication } from '../../request/axios';
const { Content } = Layout
const { Title } = Typography

class SearchableTable extends React.Component {
	state = {
	  searchText: '',
	  searchedColumn: '',
	  data: [],
	};
	
	loadData(){
		let t_id = sessionStorage.getItem('t_id')
		let s_id = sessionStorage.getItem('s_id')
		GetApplication({t_id,s_id},request_res=>{
      const {data:{result,status,tips="查询错误"}} = request_res
      if(status!==100){
          AlertMsg({message:tips,des:"查询表格出错"})
      }else{
        result.forEach((el,index)=>{
        	el.key = index
		  })
		this.setState({
			data: result,
			role:t_id?"student":"teacher"
		})
      }
    }).catch(err=>{
      AlertMsg({message:"网络错误",des:"请求数据出错"})
      console.log(err.message)
    })
	}

    reply(lesson_id, applicant, temp_time=null, processor, reply=false){
        ReplyToApplication({
			lesson_id,
			applicant,
			temp_time,
			processor,
			reply,
			role:sessionStorage.getItem("s_id")?"student":"teacher"
		},request_res=>{
            const {data:{status,tips="查询错误"}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"查询表格数据出错"})
            }else{
                message.success('Success!')
                this.loadData()
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
    }

	componentDidMount(){
		this.loadData()
	}

	getColumnSearchProps = dataIndex => ({
	  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		<div style={{ padding: 8 }}>
		  <Input
			ref={node => {
			  this.searchInput = node;
			}}
			placeholder={`搜索 ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ width: 188, marginBottom: 8, display: 'block' }}
		  />
		  <Space>
			<Button
			  type="primary"
			  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  icon={<SearchOutlined />}
			  size="small"
			  style={{ width: 90 }}
			>
			  搜索
			</Button>
			<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  重置
			</Button>
		  </Space>
		</div>
	  ),
	  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	  onFilter: (value, record) =>
		record[dataIndex]
		  ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		  : '',
	  onFilterDropdownVisibleChange: visible => {
		if (visible) {
		  setTimeout(() => this.searchInput.select(), 100);
		}
	  },
	  render: text =>
		this.state.searchedColumn === dataIndex ? (
		  <Highlighter
			highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			searchWords={[this.state.searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
		  />
		) : (
		  text
		),
	});
  
	handleSearch = (selectedKeys, confirm, dataIndex) => {
	  confirm();
	  this.setState({
		searchText: selectedKeys[0],
		searchedColumn: dataIndex,
	  });
	};
  
	handleReset = clearFilters => {
	  clearFilters();
	  this.setState({ searchText: '' });
	};
  
	render() {
		const {role} = this.state
		console.log(role)
		const columns = [
            {
                title: role==="teacher"?TransText({CN:"老师编号",EN:"Teacher number"}):'student number',
                dataIndex:  role+'_num',
				key: role+'_num',
				...this.getColumnSearchProps(role+'_num'),
            },
            {
                title: role==="teacher"?TransText({CN:"老师",EN:"Teacher name"}):'student',
                dataIndex: 'username',
				key: 'username',
				...this.getColumnSearchProps('username'),
            },
            {
                title: TransText({CN:"课程代码",EN:'subject code'}),
                dataIndex: 'subject_code',
				key: 'subject_code',
				...this.getColumnSearchProps('subject_code'),
            },
            {
                title: TransText({CN:"课程",EN:'subject name'}),
                dataIndex: 'name',
				key: 'name',
                ...this.getColumnSearchProps('name'),
                render: text=>{
                    return TransText(SplitENCN(text))
                }
            },
            {
                title: TransText({CN:"原时间",EN:'past time'}),
                dataIndex: 'past_time',
				key: 'past_time',
				align:"center",
                render: text=>{
                    return DateFormat("M/D H:m",parseInt(text))
                }
            },
            {
                title: TransText({CN:"修改时间",EN:'new time'}),
                dataIndex: 'new_time',
				key: 'new_time',
				align:"center",
                render: text=>{
                    return DateFormat("M/D H:m",parseInt(text))
                }
            },
            {
				title: TransText({CN:"操作",EN:'operations'}),
                dataIndex: 'response',
				key: 'response',
				align:"center",
				render: (text,record) =>{
					if(record.handle === 0){
						return <>
						<Space>
							<Button type='primary' onClick={()=>this.reply(record.lesson_id,record.applicant,record.new_time,record.processor,true)}>
							{TransText({CN:"同意",EN:'Confirm'})}
							</Button>
							<Button type='secondary' danger onClick={()=>this.reply(record.lesson_id,record.applicant)}>
							{TransText({CN:"拒绝",EN:'Decline'})}
							</Button>
						</Space>
						</>
					}else{
						if(text === 0){
							return TransText({CN:"已拒绝",EN:'Declined'})
						}else{
							return TransText({CN:"已同意",EN:'Confirmed'})
						}
					}
                },
			}
        ];
	  	return <Table bordered columns={columns} dataSource={this.state.data} />;
	}
}

class AlterTimeForm extends React.Component{
    render(){
        return <>
        <Content style={{ padding: '30px 50px' }}>
            <div id="container" style={{ maxWidth: 1000, margin: 'auto' }}>
                <Title level={3}><TransText CN='课程时间修改请求' EN='Time Change Requests'/></Title>
                <br />
                <SearchableTable />
            </div>
        </Content>
        </>
    }
}

export default AlterTimeForm