import React, { Component } from 'react';
import { Layout, Form, Input, Button, Radio, Col, Row, message, Result } from 'antd';
import TopNav from '../components/TopNav';
import Footer1 from '../components/Footer1';
import { TransText, AlertMsg } from '../components/PublicMethods';
import { checkAndGetVerificationCode, resetPassword } from '../request/axios'
import cookie from  'react-cookies'
import { SmileOutlined } from '@ant-design/icons';

const { Header, Content, Footer } = Layout;

class ForgotPassword extends Component {

    state = {
        role:null,
        email:'',
        emailCoolDown: 60,
        success:false
    }

    componentDidMount() {

    }

    sendEmail(){
        this.setState({
            emailCoolDown:59
        })
        this.timer = setInterval(()=>{
            const {emailCoolDown} = this.state
            if(emailCoolDown>0){
                this.setState({
                    emailCoolDown:emailCoolDown-1
                })
            }else{
                clearInterval(this.timer)
                this.setState({
                    emailCoolDown:60
                })
            }
        },1000)
        checkAndGetVerificationCode({role:this.state.role,email:this.state.email},data=>{
            const {data:{result,status,tips}} = data
            if(status!==100){
                message.error(TransText({'CN':tips,'EN':'Fail to send verification code.'}))
                return
            }
            if(result){
                cookie.save(this.state.email,result,{maxAge:300})
                message.info(TransText({'CN':'验证码已发送','EN':'Verification code has been sent.'}))
            }else{
                message.warning(TransText({'CN':'邮箱账号不存在！','EN':'We can not find an account with the provided email.'}))
                this.setState({
                    emailCoolDown:5
                })
            }
        }).catch(err=>{
            console.log(err)
        })
    }
    
    onFinish = (values) => {
        const {confirm,code,...submitData} = values
        let isVerified = false
        if(cookie.load(submitData.email)===code){
            isVerified = true
        }
        if(isVerified){
            resetPassword(submitData, request_res => {
                const { data: { status, tips } } = request_res
                if (status !== 100) {
                    AlertMsg({ message: TransText({'CN':'更改密码出错','EN':'Fail to update passoword'}), des: tips })
                } else {
                    message.success(TransText({'CN':'密码修改成功！','EN':'New password has been set!'}))
                    this.setState({
                        success:true
                    })
                }
            }).catch(err => {
                console.log(err)
                AlertMsg({ message: "网络错误", des: "请求数据出错" })
            })
        }else{
            message.warning(TransText({'CN':'验证码不正确!','EN':'Invalid verification code!'}))
        }
    };

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    onChange = (event) => {
        event.preventDefault()
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    render() {
        if(this.state.success){
            return <Result
                icon={<SmileOutlined />}
                title={TransText({'CN':'密码修改成功!','EN':'Your passowrd has been updated!'})}
                subTitle={TransText({'CN':'请前往登录。','EN':'Proceed to login.'})}
                extra={[
                    <Button type="primary" onClick={() => window.location.href = "/login"}>
                        {TransText({'CN':'去登录','EN':'Login.'})}
                    </Button>,
                ]}
            />
        }else{
            const layout = {
                labelCol: {
                span: 6,
                },
                wrapperCol: {
                span: 18,
                },
            };
            const tailLayout = {
                wrapperCol: {
                offset: 12,
                span: 4,
                },
            };
            return <>
                <Layout>
                    <Header style={{ backgroundColor: 'white' }}>
                        <TopNav />
                    </Header>
                    <Content style={{ padding: '50px 50px' }}>
                    <div style={{maxWidth:600, margin:'auto'}}>
                        <Form
                            {...layout}
                            name="basic"
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                        >
                            <Form.Item
                                label={TransText({'CN':'角色','EN':'Role'})}
                                name="role"
                                rules={[
                                {
                                    required: true,
                                    message: TransText({'CN':'请选择角色!','EN':'Please select a role!'}),
                                },
                                ]}
                            >
                                <Radio.Group name="role" onChange={this.onChange}>
                                    <Radio value={'s'}>{TransText({'CN':'我是学生','EN':'I am a student'})}</Radio>
                                    <Radio value={'t'}>{TransText({'CN':'我是教师','EN':'I am a teacher'})}</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                label={TransText({'CN':'邮箱','EN':'Email'})}
                                name="email"
                                rules={[
                                {
                                    required: true,
                                    message: TransText({'CN':'请输入邮箱地址!','EN':'Please input your email address!'}),
                                },
                                ]}
                            >
                                <Input name="email" disabled={!this.state.role} onChange={this.onChange}/>
                            </Form.Item>

                            <Form.Item
                                label={TransText({'CN':'验证码','EN':'Verification Code'})}
                                name="code"
                                rules={[
                                {
                                    required: true,
                                    message: TransText({'CN':'请输入验证码!','EN':'Please input verification code!'}),
                                },
                                ]}
                            >
                                <Row>
                                    <Col span={18}>
                                        <Input name=""/>
                                    </Col>
                                    <Col span={6}>
                                        <Button 
                                            disabled={this.state.emailCoolDown!==60 || this.state.email===""}
                                            onClick={()=>this.sendEmail()}
                                        style={{width:"100%"}}>
                                            {
                                                this.state.emailCoolDown!==60?
                                                `${this.state.emailCoolDown}s`
                                                :
                                                TransText({'CN':'获取验证码', 'EN':'Send Email'})
                                            }
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>

                            <Form.Item
                                name="password"
                                label={TransText({'CN':'新密码', 'EN':'New Password'})}
                                rules={[
                                    {
                                        required: true,
                                        message: TransText({'CN':'请输入新密码！', 'EN':'Please input your new password!'}),
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password name="password" />
                            </Form.Item>

                            <Form.Item
                                name="confirm"
                                label={TransText({'CN':'确认新密码', 'EN':'Confirm Password'})}
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: TransText({'CN':'请再次输入密码！', 'EN':'Please confirm your password!'}),
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(TransText({'CN':'两次密码不正确!', 'EN':'Password did not match!'}));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password name="confirm" />
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <Button type="primary" htmlType="submit">{TransText({'CN':'提交修改', 'EN':'Submit'})}</Button>
                            </Form.Item>
                        </Form>
                    </div>
                    </Content>
                    <Footer style={{ textAlign: 'center', backgroundColor: '#fafafa' }}>
                        <Footer1 />
                    </Footer>
                </Layout>
            </>
        }
    }
}

export default ForgotPassword;