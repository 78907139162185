import React from 'react';
import { Menu, Row, Col, Dropdown, Avatar, Space, Divider } from 'antd';
import {TransText,responsiveWithWidth} from './PublicMethods'
import {storage} from '../utils/localStorage'
import { UserOutlined } from '@ant-design/icons';

class TopNav extends React.Component {
	languageSHift(L){
		storage.setItem("language",L)
		window.location.reload()
	}
  	render() {
		const menu = (
			<Menu style={{marginTop:20}}>
				{[
					{name:"中文",key:'CN'},
					{name:"English",key:'EN'}
				].map(submenu=>{
					return (
						<Menu.Item key={submenu.key}>
							<span onClick={()=>this.languageSHift(submenu.key)}>
							{submenu.name}
							</span>
						</Menu.Item>
					)
				})
				}
			</Menu>
		);
		const user_menu = (
			<Menu style={{marginTop:20}}>
			  <Menu.Item key="1">
				<a rel="noopener noreferrer" href="/login">
					<TransText CN="教师登录" EN="Teacher Login" />
				</a>
			  </Menu.Item>
			  <Menu.Item key="2">
				<a rel="noopener noreferrer" href="/admin">
					<TransText CN="管理登录" EN="Admin Login" />
				</a>
			  </Menu.Item>
			</Menu>
		)

		let jump = null
		if(sessionStorage.getItem('s_id')){
			jump = <a rel="noopener noreferrer" href="/userprofile"><Space><Avatar size={32} icon={<UserOutlined />} style={{backgroundColor:'#40a9ff'}}/>{sessionStorage.getItem('s_username')}</Space></a>
		}
		if(sessionStorage.getItem('t_id')){
			jump = <a rel="noopener noreferrer" href="/teacherprofile"><Space><Avatar size={32} icon={<UserOutlined />} style={{backgroundColor:'#40a9ff'}}/>{sessionStorage.getItem('t_username')}</Space></a>
		}
		if(sessionStorage.getItem('admin_id')){
			jump = <a rel="noopener noreferrer" href="/admin"><Space><Avatar size={32} icon={<UserOutlined />} style={{backgroundColor:'#40a9ff'}}/>{sessionStorage.getItem('admin_username')}</Space></a>
		}
		
		return <>
			<Row justify="space-between">
				<Col xs={18} sm={18} md={6} lg={6} xl={6} style = {{textAlign:"left"}}>
					<a href = "/" id="logo"><img style = {{height:55,transform:[`scale(${responsiveWithWidth(600)})`]}} src = "images/static/eemulogo.png" alt = "logo"  /></a>
				</Col>
				<Col xs={6} sm={6} md={18} lg={18} xl={18}>
					<Menu theme="light" mode="horizontal" defaultSelectedKeys={[this.props.menuItem]} style={{backgroundColor: 'transparent', display:'flex', justifyContent: 'flex-end'}}>
						<Menu.Item key="1"><a href="/"><TransText CN="主页" EN="HOME" /></a></Menu.Item>
						<Menu.Item key="2"><a href="/course"><TransText CN="课程总览" EN="COURSE" /></a></Menu.Item>
						<Menu.Item key="3"><a href="/about"><TransText CN="关于我们" EN="ABOUT US" /></a></Menu.Item>
						<Menu.Item key="4"><a href="/help"><TransText CN="帮助" EN="HELP" /></a></Menu.Item>
						<Menu.Item key="5">
							<Dropdown overlay={menu} placement="bottomCenter">
								<span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
								<TransText CN="Language" EN="语言" />
								</span>
							</Dropdown>
						</Menu.Item>
						<Menu.Item key="998" disabled><Divider type="vertical" style={{height:'100%'}}/></Menu.Item>
						<Menu.Item key="6">
							{jump?
								jump		
							:
								<Dropdown overlay={user_menu} placement="bottomCenter">
									<a rel="noopener noreferrer" href="/login">
										<Space><Avatar size={32} icon={<UserOutlined />}/><TransText CN="学生登录" EN="Student Login" /></Space>
									</a>
								</Dropdown>
							}
						</Menu.Item>
					</Menu>
				</Col>
			</Row>
		</>;
	  }
}

export default TopNav;