import React from 'react';
import Home from '../pages/Home';
import Course from  '../pages/Course'
import Subject from '../pages/Subject'
import UserProfile from '../pages/UserProfile';
import TeacherProfile from '../pages/TeacherProfile';
import Login from '../pages/Login'
import Register from '../pages/Register'
import About from '../pages/About'
import Admin from '../pages/Admin'
import Help from '../pages/Help'
import ForgotPassword from '../pages/ForgotPassword';

const route = [
  {
    path: "/",
    exact:true,
    component: <Home />
  },
  {
    path: "/help",
    exact:true,
    component: <Help />
  },
  {
    path: "/course",
    exact:true,
    component: <Course />
  },
  {
    path: "/subject",
    exact:true,
    component: <Subject />
  },
  {
    path: "/userprofile",
    exact:true,
    component: <UserProfile />
  },
  {
    path: "/teacherprofile",
    exact:true,
    component: <TeacherProfile />
  },
  {
    path: "/login",
    exact:true,
    component: <Login />
  },
  {
    path: "/register",
    exact:true,
    component: <Register />
  },
  {
    path: "/about",
    exact:true,
    component: <About />
  },
  {
    path: "/admin",
    exact:true,
    component: <Admin />
  },
  {
    path: "/forgotPassword",
    exact:true,
    component: <ForgotPassword />
  },
]

export default route;