import React from 'react';
import { Card, Avatar, Modal, Form, Input, Button, message, Tooltip } from 'antd';
import { EditOutlined, LogoutOutlined, SmileOutlined, HomeOutlined } from '@ant-design/icons';
import { AdminAlterPassword } from '../request/axios';
import { AlertMsg } from './PublicMethods';

const { Meta } = Card;

class AdminProfile extends React.Component{

    state = {
        welcome: '',
        username: '',
        admin_num: '',
        visible: false,
    }

    componentDidMount(){
        var today = new Date()
        var curHr = today.getHours()
        let welcome = ''
        if (curHr < 12) {
            welcome = '早上好，'
        } else if (curHr < 18) {
            welcome = '下午好，'
        } else {
            welcome = '晚上好，'
        }
        this.setState({
            welcome:welcome,
            username: sessionStorage.getItem('admin_username') || '?',
            admin_num: sessionStorage.getItem('admin_num') || '?'
        })
    }

    showModal = () => {
        this.setState({
          visible: true,
        });
    };
    
    onFinish = (values) => {
        AdminAlterPassword({password:values.password, admin_id:sessionStorage.getItem('admin_id')},request_res=>{
            const {data:{status,tips}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"查询数据出错"})
            }else{
                message.success('密码修改成功！')
                this.setState({
                    visible: false,
                });
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
    };
    
    handleCancel = e => {
        console.log(e);
        this.setState({
          visible: false,
        });
    };

    logout(){
        sessionStorage.clear()
        window.location.href="/admin"
    }
    
    render(){
        const formItemLayout = {
            labelCol: {
              sm: {
                span: 6,
              },
            },
            wrapperCol: {
              sm: {
                span: 18,
              },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
              sm: {
                span: 16,
                offset: 4,
              },
            },
        };

        return <>
            <Card
                style={{ width: '100%', backgroundColor:'#f0f5ff' }}
                actions={[
                <Tooltip title="返回主页"><HomeOutlined key="home" onClick={()=>window.location.href = '/'}/></Tooltip>,
                <Tooltip title="修改密码"><EditOutlined key="edit" onClick={this.showModal}/></Tooltip>,
                <Tooltip title="退出登录"><LogoutOutlined key="logout" onClick={this.logout}/></Tooltip>,
                ]}
            >
                <Meta
                avatar={<Avatar style={{ backgroundColor: '#1890ff', marginTop:10 }} icon={<SmileOutlined />} />}
                title={this.state.welcome+this.state.username}
                description={this.state.admin_num}
                />
            </Card>

            <Modal
                title="修改密码"
                visible={this.state.visible}
                onCancel={this.handleCancel}
                footer={null}
            >
                <Form
                name="change-password"
                {...formItemLayout}
                scrollToFirstError
                onFinish={this.onFinish}
                >
                    <Form.Item
                        name="password"
                        label="新密码"
                        rules={[
                        {
                            required: true,
                            message: '请输入新密码!',
                        },
                        ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        label="确认新密码"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                        {
                            required: true,
                            message: '请在输入一次新密码!',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject('两次输入的新密码不匹配!');
                            },
                        }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit">
                            确认修改
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    }
    
}

export default AdminProfile