import React,{Component} from 'react';
import { Layout, Menu, Row, Col, Form, Input, Button, message, BackTop } from 'antd';
import AdminProxy from './AdminProxy';
import AdminStudent from './AdminStudent';
import AdminTeacher from './AdminTeacher';
import AdminFounder from './AdminFounder';
import { AlertMsg,socketMessage } from '../components/PublicMethods';
import MessageNotifier from '../components/admin/MessageNotifier'
import {GlobalContext} from '../request/context'
import { AdminLogin } from '../request/axios';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const {Provider} = GlobalContext

const { Header, Content } = Layout;

class Admin extends Component{

    constructor(){
        super()
        this.state={
            tag_selected:0,
            tag_list:[],
            title_list:[],
            message_list:[
                {
                    type:0,
                    new:false,
                    children:[]
                },
                {
                    type:1,
                    new:false,
                    children:[]
                },
                {
                    type:2,
                    new:false,
                    children:[]
                },
                {
                    type:3,
                    new:false,
                    children:[]
                }
            ],
            admin_id:sessionStorage.getItem('admin_id') || '',
        }
    }

    componentDidMount(){
        if(this.state.admin_id !== ''){
            this.initialize()
        }
    }

    socket(){
        const socket = this.context.socket
        socketMessage(socket,"admin",sessionStorage.getItem("admin_id"),onlineData=>{
            let type = parseInt(onlineData.type)
            let message = ["新的学生","新的课程预定","新的课程动态","学生反馈"][type]
            AlertMsg({message,des:onlineData.username?onlineData.username+"有新的活动":"",type:'info'})
            this.addNewInfo(onlineData)
        },offLineData=>{
            AlertMsg({message:"欢迎回来",des:"您有新的离线通知",type:'info'})
            this.addNewInfo(offLineData)
        })
    }

    initialize(){
        this.socket()
        window.addEventListener('beforeunload',e=>this.checkBeforeLeft(e))
        const auth_id = sessionStorage.getItem('auth_id')
        let pages = new Map([
            ["1",[{title:'学生管理',content:<AdminStudent/>},{title:'教师管理',content:<AdminTeacher/>},{title:'站长',content:<AdminFounder />}]],
            ["2",[{title:'学生管理',content:<AdminStudent/>},{title:'站长',content:<AdminFounder />}]],
            ["3",[{title:'教师管理',content:<AdminTeacher/>},{title:'站长',content:<AdminFounder />}]],
            ["4",[{title:'学生管理',content:<AdminStudent/>}]],
            ["5",[{title:'教师管理',content:<AdminTeacher/>}]],
            ["6",[{title:'代理',content:<AdminProxy />}]],
        ])
        this.setState({
            tag_list: pages.get(auth_id),
        })
    }

    checkBeforeLeft(e){
        const [...message_list] = this.state.message_list
        let check = false
        message_list.forEach(msg=>{
            if(msg.new) check = true
        })
        if(!check) return
        e.returnValue = '刷新或关闭页面之前请确定您的消息是否查看';
        return e;
    }

    componentWillUnmount(){
        window.removeEventListener('beforeunload', e=>this.checkBeforeLeft(e));
    }

    addNewInfo=(data)=>{
        console.log(data)
        let [...message_list] = this.state.message_list
        if(typeof data==="object"&&data.length){
            data.forEach(el=>{
                message_list[el.type].new = true
                message_list[el.type].children.push(el)
            })
        }else{
            message_list[data.type].new = true
            message_list[data.type].children.push(data)
        }
        this.setState({
            message_list,
        })
    }

    handleAddMessage(type,data){
        let [...message_list] = this.state.message_list
        for(let index in message_list){
            if(message_list[index].type===type){
                message_list[index].children.push(data)
            }
        }
    }

    handleRemoveMessage(type_index,child_index){
        let [...message_list] = this.state.message_list
        message_list[type_index].children.splice(child_index,1)
        if(!message_list[type_index].children.length) message_list[type_index].new = false
        this.setState({
            message_list,
        })
    }
    
    handleTagChange = e =>{
        this.setState({
            tag_selected:parseInt(e.key)-1
        })
    }

    onFinish = (values) => {
        AdminLogin({...values},request_res=>{
            const {data:{result,status,tips="查询错误"}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"查询数据出错"})
            }else{
                sessionStorage.clear()
                sessionStorage.setItem('admin_id', result.admin_id)
                sessionStorage.setItem('admin_num', result.admin_num)
                sessionStorage.setItem('auth_id', result.auth_id)
                sessionStorage.setItem('admin_username', result.username)
                this.setState({
                    admin_id:result.admin_id
                })
                this.initialize()
                message.success('登录成功！');
            }
        }).catch(err=>{
            AlertMsg({message:"登陆失败",des:"账号或密码错误"})
            console.log(err.message)
        })
    };
    
    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    render(){
        if(this.state.admin_id === ''){
            const layout = {
                wrapperCol: {
                  span: 24,
                },
            };
            const tailLayout = {
                wrapperCol: {
                  span: 24,
                },
            };
            return <>
                <Layout style={{minHeight:'100vh'}}>
                    <Header className="header">
                        <Row>
                            <Col style={{color:'white', fontSize:'14pt', fontWeight:'bold'}}>
                                eEmu教育管理系统
                            </Col>
                        </Row>
                    </Header>
                    <Content style={{width:320,margin:'auto',paddingTop:100}}>
                    <div className = "center">
                        <Row>
                        <img style = {{width:200,height:200,marginBottom:20}} src = "images/static/eemulogo.png" alt = "logo"/>
                        </Row>
                    </div>
                    <h1>管理员登录</h1>
                    <br/>
                    <Form
                        {...layout}
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        >
                        <Form.Item
                            name="admin_num"
                            rules={[{ required: true, message: '请输入一个员工工号!' }]}
                        >
                            <Input placeholder={'管理员账号'} prefix={<UserOutlined className="site-form-item-icon" />}/>
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: '请输入密码!' }]}
                        >
                            <Input.Password placeholder={'密码'}prefix={<LockOutlined className="site-form-item-icon" />}/>
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button style={{width:'100%'}} type="primary" htmlType="submit">
                                登录
                            </Button>
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                            <Button style={{width:'100%'}} type="secondary" onClick={()=>window.location.href="/"}>
                                返回主页
                            </Button>
                        </Form.Item>
                    </Form>
                    </Content>
                </Layout>
            </>
        }else{
            const {tag_selected,tag_list,message_list} = this.state
            const content = tag_list[tag_selected]?tag_list[tag_selected].content:[]
            let newMessage = false
            if(message_list.length){
                message_list.forEach(msg=>{
                    if(msg.new){
                        newMessage = true
                    }
                })
            }
           
            return (
                <Layout style={{minHeight:'100vh'}}>
                    <Header className="header">
                        <Row>
                            <Col style={{color:'white', fontSize:'14pt', fontWeight:'bold'}}>
                                eEmu教育管理系统
                            </Col>
                            <Col>
                                <Menu theme="dark" mode="horizontal" onClick={this.handleTagChange} defaultSelectedKeys={['1']} style={{marginLeft:'48px'}}>
                                    {this.state.tag_list.map((dic,index)=>{
                                        return <Menu.Item key={(index+1).toString()}>{dic.title}</Menu.Item>
                                    })}
                                </Menu>
                            </Col>
                        </Row>
                    </Header>
                    <Layout>
                    {
                        newMessage?
                        <Provider value={{message_list,remove:this.handleRemoveMessage.bind(this)}}>
                            <MessageNotifier />
                        </Provider>
                        :
                        <></>
                    }
                    {
                        content
                    }
                    </Layout>
                    <BackTop />
                </Layout>
            )
        }
    }
}

Admin.contextType = GlobalContext

export default Admin;