import React from 'react';
import { Layout, Typography, Button, Table, Input, Space, Spin, message } from 'antd'
import { TransText, DateFormat, SplitENCN } from '../../components/PublicMethods';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { GetAllLessonForTeacher } from '../../request/axios';
const { Content } = Layout
const { Title } = Typography

class SearchableTable extends React.Component {
	state = {
	  searchText: '',
	  searchedColumn: '',
	  data: [],
	  loading:true
	};
    
	loadData(){
        GetAllLessonForTeacher({t_id:sessionStorage.getItem('t_id'),s_id:sessionStorage.getItem('s_id')},data=>{
			let {data:{status,tips,result}} = data
			if(status!==100){
				message.warning(tips)
				return
			}
			result.forEach((el,index)=>{
				el.key = index
			})
			this.setState({
				data:result,
				loading:false
			})
		}).catch(err=>{
			console.log(err)
		})
	}
    
	componentDidMount(){
		this.loadData()
	}

	getColumnSearchProps = dataIndex => ({
	  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		<div style={{ padding: 8 }}>
		  <Input
			ref={node => {
			  this.searchInput = node;
			}}
			placeholder={`Search ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ width: 188, marginBottom: 8, display: 'block' }}
		  />
		  <Space>
			<Button
			  type="primary"
			  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  icon={<SearchOutlined />}
			  size="small"
			  style={{ width: 90 }}
			>
			  {TransText({CN:'搜索',EN:'Search'})}
			</Button>
			<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  {TransText({CN:'重置',EN:'Reset'})}
			</Button>
		  </Space>
		</div>
	  ),
	  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	  onFilter: (value, record) =>
		record[dataIndex]
		  ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		  : '',
	  onFilterDropdownVisibleChange: visible => {
		if (visible) {
		  setTimeout(() => this.searchInput.select(), 100);
		}
	  },
	  render: text =>
		this.state.searchedColumn === dataIndex ? (
		  <Highlighter
			highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			searchWords={[this.state.searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
		  />
		) : (
		  text
		),
	});
  
	handleSearch = (selectedKeys, confirm, dataIndex) => {
	  confirm();
	  this.setState({
		searchText: selectedKeys[0],
		searchedColumn: dataIndex,
	  });
	};
  
	handleReset = clearFilters => {
	  clearFilters();
	  this.setState({ searchText: '' });
	};
  
	render() {
		if(this.state.loading){
            return <Spin size="large"/>
        }
		const columns = [
            {
                title: TransText({CN:'上课时间',EN:'Start Time'}),
                dataIndex: 'lesson_time',
                key: 'lesson_time',
                width: 130,
                render: text => (
                    DateFormat('M/D H:m',parseInt(text))
                ),
                align:'center'
            },
            {
                title: TransText({CN:'临时时间',EN:'Temp Time'}),
                dataIndex: 'temp_time',
                key: 'temp_time',
                width: 130,
                render: text => {
                    if(text === null){
                        return TransText({CN:'无',EN:'None'})
                    }else{
                        return DateFormat('M/D H:m',parseInt(text))
                    }
                },
                align:'center'
            },
            {
                title: TransText({CN:'学号',EN:'Student Number'}),
                dataIndex: 'student_num',
                key: 'student_num',
                ...this.getColumnSearchProps('student_num'),
            },
            {
                title: TransText({CN:'学生姓名',EN:'Student Name'}),
                dataIndex: 'student_name',
                key: 'student_name',
                ...this.getColumnSearchProps('student_name'),
            },
            {
                title: TransText({CN:'教师姓名',EN:'Teacher Name'}),
                dataIndex: 'teacher_name',
                key: 'teacher_name',
                ...this.getColumnSearchProps('teacher_name'),
            },
            {
                title: TransText({CN:'课程代码',EN:'Subject Code'}),
                dataIndex: 'subject_code',
                key: 'subject_code',
                ...this.getColumnSearchProps('subject_code'),
            },
            {
                title: TransText({CN:'课程名称',EN:'Subject Name'}),
                dataIndex: 'name',
                key: 'name',
                ...this.getColumnSearchProps('name'),
                render: text => {
                    return TransText({ ...SplitENCN(text) })
                },
            },
            {
                title: 'Zoom',
                dataIndex: 'zoom',
                key: 'zoom',
                ...this.getColumnSearchProps('zoom'),
            },
        ];
	  	return <Table bordered columns={columns} dataSource={this.state.data} pagination={{ pageSize: 20 }} size="middle"/>;
	}
}

class UpcomingLessons extends React.Component{
    render(){
        return <>
        <Content style={{ padding: '30px 50px' }}>
            <div id="container" style={{ maxWidth: 1400, margin: 'auto' }}>
                <Title level={3}><TransText CN='我的课表' EN='My Timetable'/></Title>
                <SearchableTable />
            </div>
        </Content>
        </>
    }
}

export default UpcomingLessons