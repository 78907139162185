import React, { useContext, useState, useRef } from 'react';
import { Input, Typography,Form, Modal, message, InputNumber,Button, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
const { Text } = Typography;
const EditableContext = React.createContext();

export const EditableRow = ({ index, ...props }) => {
	const [form] = Form.useForm();
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	);
};

export const EditableCell = ({
	title,
	editable,
	editType,
	children,
	dataIndex,
	record,
	handleSave,
	...restProps
}) => {
	const [editing, setEditing] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const form = useContext(EditableContext);
	const desForm = useRef()

	const toggleEdit = () => {
		setEditing(!editing);
		showModal()
		if (editType === "description") {
			return
		}
		if (editType === "config") {
			let obj = JSON.parse(record[dataIndex])
			form.setFieldsValue({
				...obj,
			});
			return
		}
		form.setFieldsValue({
			[dataIndex]: record[dataIndex],
		});
	};

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
		if (editType === "description") {
			handleDescriptionForm()
			return
		}
		save()
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		save()
	};

	const save = async (e) => {
		try {
			const values = await form.validateFields();
			let {skill,difficulty,...value} = values
			if(editType==="config"){
				if(!skill){
					message.warning()
					return
				}
				value.config = `{"skill":"${skill}","difficulty":"${difficulty}"}`
			}
			toggleEdit();
			handleSave({ ...record, ...value });
		} catch (errInfo) {
			message.warning(errInfo.errorFields[0].errors[0])
			toggleEdit();
			console.log('Save failed:', errInfo);
		}
	};

	let childNode = children;
	const defaultForm = (dataIndex, title) => {
		return <Form.Item
			style={{
				margin: 0,
			}}
			name={dataIndex}
			rules={[
				{
					required: true,
					message: `${title} 不能为空。`,
				},
			]}
		>
			<Input type={editType || "text"} />
		</Form.Item>
	}

	const textareaForm = (dataIndex, title) => {
		return <Form.Item
			style={{
				margin: 0,
			}}
			name={dataIndex}
			rules={[
				{
					required: true,
					message: `${title} 不能为空。`,
				},
			]}
		>
			<Input.TextArea type="text" />
		</Form.Item>
	}

	const handleDescriptionForm = async () => {
		try {
			const { sub_description } = await desForm.current.validateFields()
			let desObj = {}
			if (!sub_description || !sub_description.length) {
				message.warning("请先添加描述")
				toggleEdit();
				return
			}
			sub_description.forEach(des => {
				desObj[des.title || "*"] = des.body
			})
			let values = { description: JSON.stringify(desObj) }
			toggleEdit();
			handleSave({ ...record, ...values });
		} catch (errInfo) {
			message.warning("输入值不可为空")
			toggleEdit();
			console.log('Save failed:', errInfo);
		}
	}

	const descriptionForm = (dataIndex, title) => {
		return <Form name="description" ref={desForm} >
			<Form.List name="sub_description">
				{(fields, { add, remove }) => (
					<>
						{fields.map(field => (
							<Space key={field.key} align="baseline">
								<Form.Item
									{...field}
									name={[field.name, 'title']}
									fieldKey={[field.fieldKey, 'title']}
								>
									<Input placeholder="小标题或者忽略" />
								</Form.Item>
								<Form.Item
									{...field}
									name={[field.name, 'body']}
									fieldKey={[field.fieldKey, 'body']}
									rules={[{ required: true, message: '您输入的描述不可为空' }]}
								>
									<Input placeholder="描述主体" />
								</Form.Item>
								<MinusCircleOutlined onClick={() => remove(field.name)} />
							</Space>
						))}
						<Form.Item>
							<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
								Add field
					</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
		</Form>
	}

	const configForm = (dataIndex, title) => {
		return <><Form.Item
			label="技能锻炼"
			name="skill"
			rules={[
				{
					required: true,
					message: `${title} 不能为空。`,
				},
			]}
		>
			<Input placeholder="写作、口语" />
		</Form.Item>
			<Text type="secondary">
				难度指数为1-5之间的整数。
	</Text>
			<Form.Item
				label="难度指数"
				name="difficulty"
			>
				<InputNumber min={1} max={5} />
			</Form.Item>
		</>
	}

	const editFormMap = new Map([
		["textarea", textareaForm],
		["description", descriptionForm],
		["config", configForm]
	])

	if (editable) {
		childNode = editing ? (
			<Modal title="修改信息" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
				{editFormMap.get(editType) ? editFormMap.get(editType)(dataIndex, title) : defaultForm(dataIndex, title)}
			</Modal>
		) : (

				children && children[1] && children[1] !== ""
					?
					<div
						className="editable-cell-value-wrap"
						onClick={toggleEdit}
					>
						{children}
					</div>
					:
					<div style={{
						height: "30px",
						width: "100%",
					}}
						onClick={toggleEdit} />
			);
	}

	return <td {...restProps}>{childNode}</td>;
};