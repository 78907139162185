import React from 'react';
import { Typography} from 'antd';
import { TransText, SplitENCN } from '../PublicMethods';
const { Title } = Typography;

const blue9 = '#003a8c';

class Banner extends React.Component {
  render() {
    return <>
            <div id="content1" style={{paddingTop: '80px', height:200,}}>
                <Title style={{ color:blue9, fontWeight:'bold' }}>{TransText({...SplitENCN(this.props.sub_name)})}</Title>
            </div>
		    </>;
    }
}

export default Banner;