import React,{Component} from 'react';
import { Layout, Breadcrumb } from 'antd';
import { SideMenu } from '../components/userprofile/SideMenu';
import ViewTeacher from '../components/admin/ViewTeacher';
import ViewAssign from '../components/admin/ViewAssign';
import ViewStudent from '../components/admin/ViewStudent';
import ViewStudentSummary from '../components/admin/ViewStudentSummary';
import ViewProgress from '../components/admin/ViewProgress';
import ViewSubject from '../components/admin/ViewSubject';
import ViewCategory from '../components/admin/ViewCategory';
import ViewProxy from '../components/admin/ViewProxy';
import ViewOrderSheet from '../components/admin/ViewOrderSheet';
import AdminProfile from '../components/AdminProfile';
import ViewCancelledLesson from '../components/admin/ViewCancelledLesson';
import ViewLesson from '../components/admin/ViewLesson';
import EditLesson from '../components/admin/EditLesson';
import ViewTeacherSheet from '../components/admin/ViewTeacherSheet';
import { UserOutlined, FileAddOutlined, CustomerServiceOutlined, ClearOutlined, DollarOutlined, DashboardOutlined, BranchesOutlined, CheckOutlined, PercentageOutlined, AimOutlined, SolutionOutlined, CalculatorOutlined, CloseCircleOutlined, AccountBookOutlined, AppstoreOutlined, ClusterOutlined } from '@ant-design/icons';
import ViewArrangeCourse from '../components/admin/ViewArrangeCourse';
import AuthArrange from '../components/admin/AuthArrange';
import Dashboard from '../components/admin/Dashboard';
import ViewOutdateLesson from '../components/admin/ViewOutdateLesson';
import ViewProxySettleSheet from '../components/admin/ViewProxySettleSheet';

const { Sider, Content } = Layout;

class AdminStudent extends Component{
    state={
        tag_selected:0,
        tag_list:[
            {
                tag:<><CheckOutlined />{'排课申请'}</>,
                node:<ViewArrangeCourse />
            },
            {
                tag:<><CloseCircleOutlined />{'取消的课时'}</>,
                node:<ViewCancelledLesson />
            },
            {
                tag:<><ClearOutlined />{'过期的课时'}</>,
                node:<ViewOutdateLesson />
            },
            {
                tag:<><DashboardOutlined />{'总览'}</>,
                node:<Dashboard />
            },
            {
                tag:<><AimOutlined />{'最近课程安排'}</>,
                node:<ViewLesson/>
            },
            {
                tag:<><BranchesOutlined />{'查看待上课时'}</>,
                node:<EditLesson />
            },
            {
                tag:<><FileAddOutlined />{'安排课程'}</>,
                node:<AuthArrange />
            },
            {
                tag:<><UserOutlined />{'全部学生'}</>,
                node:<ViewStudent />
            },
            {
                tag:<><PercentageOutlined />{'学生课程进度'}</>,
                node:<ViewProgress />
            },
            {
                tag:<><CalculatorOutlined />{'全部课程'}</>,
                node:<ViewSubject />
            },
            {
                tag:<><ClusterOutlined />{'全部分类'}</>,
                node:<ViewCategory />
            },
            {
                tag:<><SolutionOutlined />{'全部教师'}</>,
                node:<ViewTeacher />
            },
            {
                tag:<><AppstoreOutlined />{'查看授课安排'}</>,
                node:<ViewAssign />
            },
            {
                tag:<><DollarOutlined />{'教师结算'}</>,
                node:<ViewTeacherSheet />
            },
            {
                tag:<><CustomerServiceOutlined />{'全部代理'}</>,
                node:<ViewProxy />
            },
            {
                tag:<><AccountBookOutlined />{'代理账单'}</>,
                node:<ViewOrderSheet />
            },
            {
                tag:<><DollarOutlined />{'结算明细'}</>,
                node:<ViewProxySettleSheet />
            },
            {
                tag:<><UserOutlined />{'学生结算总览'}</>,
                node:<ViewStudentSummary />
            },
        ]
    }

    handleTagChange(index){
        this.setState({
            tag_selected:index
        })
    }

    render(){
        const {tag_selected,tag_list} = this.state
        const content = tag_list[tag_selected].node
        const title = tag_list[tag_selected].tag
        return (
            <>
            <Sider width={260}>
                <AdminProfile />
                <SideMenu 
                menuItem={tag_selected+''} 
                menu={tag_list} 
                divider={new Map([[0,'课时'],[7,'学生'],[9,'课程'],[11,'教师'],[14,'内部']])}
                tagClick = {this.handleTagChange.bind(this)}
                />
            </Sider>
            <Content style={{ padding: '20px 50px' }}>
                <div style={{maxWidth:1450, margin:'auto', marginBottom: 20}}>
                    <Breadcrumb  style={{ textAlign:'left' }}>
                        <Breadcrumb.Item>学生管理</Breadcrumb.Item>
                        <Breadcrumb.Item>{title}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                
                <div id="container" style={{padding:'30px 10px', maxWidth:1450, margin:'auto', backgroundColor:'white'}}>
                    {content}
                </div>
            </Content>
            </>
        )
    }
}

export default AdminStudent;
