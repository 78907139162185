import React, { Component } from 'react';
import '../../style/components/course/course_info.css'
import { Progress, Empty, Layout, Spin } from 'antd'
import { SplitENCN, TransText, AlertMsg } from '../PublicMethods'
import { getAllLesson, getNextLesson, axiosAll,getPresentLesson } from '../../request/axios'
import CourseDetail from './CourseDetail'
import NextTimeInfo from './NextTimeInfo'
import {GlobalContext} from '../../request/context'

const {Provider} = GlobalContext
const {Content} = Layout
class CourseInfo extends Component {
    constructor() {
        super()
        this.state = {
            course_info: [],
            next_lesson: {
                nextLesson: null,
                nextTemp: null,
                totalProgress: null
            },
            present_lesson:null,
            student_progress: [],
            show: null,
            loading:true
        }
    }
    componentDidMount() {
        this.loadData()
    }

    loadData(callback,tempcallback){
        let s_id = sessionStorage.getItem('s_id')
        axiosAll([
            getAllLesson({ s_id, }),
            getNextLesson({ s_id, }),
            getPresentLesson({s_id,})
        ], result => {
            if (result[0].data.status + result[1].data.status !== 200) {
                AlertMsg({ message: '课程信息错误', des: (result[0].data.tips || result[1].data.tips) })
                return
            }
            this.setState({
                course_info: result[0].data.result,
                next_lesson: result[1].data.result,
                present_lesson:result[2].data.result,
                loading:false
            },()=>{
                if(callback) callback(this.state.next_lesson.nextLesson.lesson_time)
                if(this.state.next_lesson.nextTemp&&tempcallback) tempcallback(this.state.next_lesson.nextTemp.lesson_time)
            })
        })
    }

    getCourseDetail(sub_id,t_id,index) {
        this.setState({
            show: <Provider value={
                {
                    sub_id,
                    t_id,
                    lessons:this.state.course_info[index].lessons,
                    partner_name:this.state.course_info[index].tutorname,
                    subject_code:this.state.course_info[index].subject_code,
                    back:() => this.setState({ show: null })
                }
            }>
                <CourseDetail />
            </Provider>
        })
    }

    render() {
        if(this.state.loading){
            return <Spin size="large"/>
        }
        const { course_info, show, next_lesson,present_lesson } = this.state
        const { nextLesson, nextTemp, totalProgress } = next_lesson
        return (
            <Content style={{ backgroundImage: 'url(../images/static/white.png)',backgroundRepeat: 'no-repeat',  backgroundSize:'cover'}}>
            <section id='user-course-info'>
                {show ? show
                    :
                    <>
                        <div className='user-course-list'>
                            {
                                course_info.length ?
                                    course_info.map((course, index) => {
                                        const { sub_id, subject_code, name, tutorname, progress, pathway, t_id } = course
                                        const completion = ~~(pathway / progress * 100)
                                        return (
                                            <div
                                                key={index}
                                                onClick={() => this.getCourseDetail(sub_id, t_id, index)}
                                                className='user-course-card'>
                                                <h2>
                                                    {subject_code}
                                                </h2>
                                                <div className='user-course-progress'>
                                                    <div>{TransText({CN:'已完成的课时',EN: 'Completed Lessons'})}<Progress percent={completion} format={percent => `${pathway} / ${progress}`} status="active" strokeColor={{
                                                        '0%': '#108ee9',
                                                        '100%': '#b1db9f',
                                                    }} /></div>
                                                </div>
                                                <div>
                                                    <span>{TransText({
                                                        CN: SplitENCN(name).CN,
                                                        EN: SplitENCN(name).EN
                                                    })}</span>
                                                    <span><span style={{ color: "#" }} className="iconfont icon-laoshi" /> {tutorname + TransText({CN:' 教师',EN: ''})}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <Empty description='您还没有预定课程' />
                            }
                        </div>
                        <div className='user-course-detail'>
                            {present_lesson && 
                                <div className='course-total'>
                                    <div>
                                        正在上课:<span>{present_lesson.subject_code}</span>
                                    </div>
                                    <div>
                                        <a href={present_lesson.zoom} target="_Blank" rel="noopener noreferrer">前往ZOOM</a>
                                    </div>
                                </div>
                            }
                            {totalProgress&&
                                <div className='course-total'>
                                    <div>
                                        拥有课程:<span>{totalProgress.total_progress}</span>
                                    </div>
                                    <div>
                                        完成课程:<span>{totalProgress.total_pathway}</span>
                                    </div>
                                </div>
                            }
                            {nextLesson && <Provider value={{title:'下一节课',nextLesson,timeEnd:(callback)=>this.loadData(callback)}}><NextTimeInfo /></Provider>}
                            {nextTemp && nextTemp.lesson_time && <Provider value={{title:'下一节临时课程',nextLesson:nextTemp,timeEnd:(callback)=>this.loadData(null,callback)}}><NextTimeInfo /></Provider>}
                        </div>
                    </>
                }
            </section>
            </Content>
        )
    }
}

export default CourseInfo