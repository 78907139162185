import React,{Component} from 'react';
import { Layout, Breadcrumb } from 'antd';
import { SideMenu } from '../components/userprofile/SideMenu';
import AddTeacher from '../components/admin/AddTeacher';
import ViewTeacher from '../components/admin/ViewTeacher';
import ViewStudent from '../components/admin/ViewStudent';
import ViewProgress from '../components/admin/ViewProgress';
import ViewCancelledLesson from '../components/admin/ViewCancelledLesson';
import ViewStudentSummary from '../components/admin/ViewStudentSummary';
import AddAssign from '../components/admin/AddAssign';
import ViewAssign from '../components/admin/ViewAssign';
import ViewSubject from '../components/admin/ViewSubject';
import ViewCategory from '../components/admin/ViewCategory';
import AdminProfile from '../components/AdminProfile';
import ViewTeacherSheet from '../components/admin/ViewTeacherSheet';
import EditLesson from '../components/admin/EditLesson';
import InsertLesson from '../components/admin/InsertLesson';
import ViewProxySettleSheet from '../components/admin/ViewProxySettleSheet';
import { UserOutlined,CalculatorOutlined, SolutionOutlined, BranchesOutlined, DashboardOutlined, ClearOutlined, FileAddOutlined, DollarOutlined, AimOutlined, PercentageOutlined, CloseCircleOutlined, AppstoreAddOutlined, AppstoreOutlined, AuditOutlined, ClusterOutlined } from '@ant-design/icons';
import ViewLesson from '../components/admin/ViewLesson';
import Dashboard from '../components/admin/Dashboard';
import ViewOutdateLesson from '../components/admin/ViewOutdateLesson';
const { Sider, Content } = Layout;

class AdminTeacher extends Component{
    state={
        tag_selected:0,
        tag_list:[
            {
                tag:<><DashboardOutlined />{'总览'}</>,
                node:<Dashboard />
            },
            {
                tag:<><CloseCircleOutlined />{'取消的课时'}</>,
                node:<ViewCancelledLesson />
            },
            {
                tag:<><ClearOutlined />{'过期的课时'}</>,
                node:<ViewOutdateLesson />
            },
            {
                tag:<><AimOutlined />{'最近课程安排'}</>,
                node:<ViewLesson/>
            },
            {
                tag:<><BranchesOutlined />{'查看待上课时'}</>,
                node:<EditLesson />
            },
            {
                tag:<><FileAddOutlined />{'追加课时'}</>,
                node:<InsertLesson />
            },
            {
                tag:<><UserOutlined />{'全部学生'}</>,
                node:<ViewStudent />
            },
            {
                tag:<><PercentageOutlined />{'学生课程进度'}</>,
                node:<ViewProgress />
            },
            {
                tag:<><CalculatorOutlined />{'全部课程'}</>,
                node:<ViewSubject />
            },
            {
                tag:<><ClusterOutlined />{'全部分类'}</>,
                node:<ViewCategory />
            },
            {
                tag:<><SolutionOutlined />{'全部教师'}</>,
                node:<ViewTeacher />
            },
            {
                tag:<><AuditOutlined />{'添加教师'}</>,
                node:<AddTeacher />
            },
            {
                tag:<><AppstoreOutlined />{'查看授课安排'}</>,
                node:<ViewAssign />
            },
            {
                tag:<><AppstoreAddOutlined />{'新增授课安排'}</>,
                node:<AddAssign />
            },
            {
                tag:<><DollarOutlined />{'教师结算'}</>,
                node:<ViewTeacherSheet />
            },
            {
                tag:<><DollarOutlined />{'代理结算明细'}</>,
                node:<ViewProxySettleSheet />
            },
            {
                tag:<><UserOutlined />{'学生结算总览'}</>,
                node:<ViewStudentSummary />
            },
        ]
    }

    handleTagChange(index){
        this.setState({
            tag_selected:index
        })
    }

    render(){
        const {tag_selected,tag_list} = this.state
        const content = tag_list[tag_selected].node
        const title = tag_list[tag_selected].tag
        return (
            <>
            <Sider width={260}>
                <AdminProfile />
                <SideMenu 
                menuItem={tag_selected+''} 
                menu={tag_list} 
                divider={new Map([[0,'课时'],[6,'学生'],[8,'课程'],[10,'教师'],[15,'内部']])}
                tagClick = {this.handleTagChange.bind(this)}
                />
            </Sider>
            <Content style={{ padding: '20px 50px' }}>
                <div style={{maxWidth:1450, margin:'auto', marginBottom: 20}}>
                    <Breadcrumb  style={{ textAlign:'left' }}>
                        <Breadcrumb.Item>教师管理</Breadcrumb.Item>
                        <Breadcrumb.Item>{title}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                
                <div id="container" style={{padding:'30px 10px', maxWidth:1450, margin:'auto', backgroundColor:'white'}}>
                    {content}
                </div>
            </Content>
            </>
        )
    }
}

export default AdminTeacher;
