import React from 'react';
import { Form, Input, Button, message, Result, Divider } from 'antd';
import { sendMail } from '../../request/axios';
import { AlertMsg } from '../PublicMethods';
import { SmileOutlined } from '@ant-design/icons';

class SendEmail extends React.Component {

    state = {
        success: false,
        email:null,
        subject: null,
        text: null,
    }

    onFinish = (values) => {
        let to = values.email
        let subject = values.subject
        let html = `<p>${values.text}</p>`
        sendMail({to:to,subject:subject,html:html},request_res=>{
            const {data:{status,tips}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"发送邮件出错"})
            }else{
                this.setState({success: true})
                message.success('邮件发送成功！')
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
    }

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    handleInputChange = (event) => {
        event.preventDefault()
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    render(){
        if (this.state.success === true){
            return <Result
                icon={<SmileOutlined />}
                title={"邮件发送成功！"}
                subTitle="已发送"
                extra={[
                    <Button type="primary" onClick={() => this.setState({success:false,subject:null,text:null,email:null})}>
                        再写一封
                    </Button>
                ]}
            />
        }else{
            let time = new Date().toDateString()
            const layout = {
                labelCol: {
                    span: 8,
                },
                wrapperCol: {
                    span: 8,
                },
            }
            const tailLayout = {
                wrapperCol: {
                    offset: 6,
                    span: 12,
                },
            }
            return <>
                <p>此邮件将使用'notification@info.eemuedu.com'发送。</p>
                <p>回复此官方邮件将自动转发到'bella_au@hotmail.com'。</p>
                <br/>
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    >
                    <Form.Item
                        label="收件人邮箱"
                        name="email"
                        rules={[
                        {
                            required: true,
                            message: '请输入收件人邮箱！',
                        },
                        ]}
                    >
                        <Input name="email" onChange={this.handleInputChange.bind(this)} placeholder="123456789@qq.com"/>
                    </Form.Item>

                    <Form.Item
                        label="标题"
                        name="subject"
                        rules={[
                        {
                            required: true,
                            message: '请输入邮件标题！',
                        },
                        ]}
                    >
                        <Input name="subject" onChange={this.handleInputChange.bind(this)} placeholder="感谢使用eEmu"/>
                    </Form.Item>

                    <Form.Item
                        label="正文"
                        name="text"
                        rules={[
                        {
                            required: true,
                            message: '请输入正文！',
                        },
                        ]}
                    >
                        <Input.TextArea name="text" onChange={this.handleInputChange.bind(this)} placeholder="正文"/>
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            发送
                        </Button>
                    </Form.Item>
                </Form>
                <div style={{maxWidth:270,minHeight:480, margin:'auto', backgroundColor:'#f7f7f7', padding:'10px 10px'}}>
                    <h1 style={{textAlign:'left', fontSize:12}}><b>{this.state.subject}</b></h1>
                    <p style={{textAlign:'left', fontSize:9}}>From: eEmu Education (notification@info.eemuedu.com)</p>
                    <p style={{textAlign:'left', fontSize:9}}>To: {this.state.email}</p>
                    <p style={{textAlign:'left', fontSize:9}}>Time: {time}</p>
                    <Divider />
                    <h1 style={{textAlign:'left', fontSize:20}}><b>{this.state.subject}</b></h1>
                    <p style={{textAlign:'left', fontSize:11}}>{this.state.text}</p>
                    <br/>
                    <p style={{textAlign:'left', fontSize:11}}>Kind Regards</p>
                    <p style={{textAlign:'left', fontSize:11}}>eEmu Education (<a href="https://eemuedu.com/login">eemuedu.com</a>)</p>
                </div>
            </>
        }
    }
}

export default SendEmail;