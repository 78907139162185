import axios from "axios";
import { backHost } from "../config";

axios.create({
    timeout: 15000 ,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
})

const host = `${backHost.host}:${backHost.port}`
// const host = `${backHost.host}`

/**
 * 
 * @param {Array} array 请求数组 
 * @param {*} callback 
 */
export const axiosAll = (array,callback,handleError)=>{
    axios.all(array).then(axios.spread(function(){
        callback(arguments)
    })).catch(error=>handleError && handleError(error))
}

/**
 * 学生发送反馈问题
 * @param {*} if(callback) callback 回调
 * @param {*} method get
 */
 export const sendQuestion = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/send_question`,
        method: 'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 更新文件String array
 * @param { JSON } param {lesson_id, column, name_array}
 * @param {*} callback 回调
 */
export const updateFileString = (param,callback) => {
    let axiosRequest = axios.post(`${host}/update_file_string`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 删除一个file string
 * @param { JSON } param {lesson_id, column, file_name}
 * @param {*} callback 回调
 */
export const deleteFileString = (param,callback) => {
    let axiosRequest = axios.post(`${host}/delete_file_string`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 
 * @param { JSON } param {username, parent_email, password, date_of_birth}
 * @param {*} callback 回调
 */
export const studentSignup = (param,callback) => {
    let axiosRequest = axios.post(`${host}/signup`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 
 * @param { JSON } param {number, username, parent_email, password}
 * @param {*} callback 回调
 */
export const changeInfo = (param,callback) => {
    return axios.post(
        `${host}/change`,param
    ).then(data=>{
        if(callback) callback(data)
    })
}

/**
 * 
 * @param { JSON } param {username, password}
 * @param {*} callback 回调
 */
export const login = (param,callback) => {
    return axios.post(
        `${host}/login`,param
    ).then(data=>{
        if(callback) callback(data)
    })
}

/**
 * 已读指导文件
 * @param { JSON } param {username, password}
 * @param {*} callback 回调
 */
 export const setRead = (param,callback) => {
    return axios.post(
        `${host}/setread`,param
    ).then(data=>{
        if(callback) callback(data)
    })
}

/**
 * 用户结束假期
 * @param { JSON } param {username, password}
 * @param {*} callback 回调
 */
 export const endHoliday = (param,callback) => {
    return axios.post(
        `${host}/end_holiday`,param
    ).then(data=>{
        if(callback) callback(data)
    })
}

/**
 * 管理员登录
 * @param { JSON } param {admin_num, password}
 * @param {*} callback 回调
 */
export const AdminLogin = (param,callback) => {
    return axios.post(
        `${host}/admin/login`,param
    ).then(data=>{
        if(callback) callback(data)
    })
}

/**
 * 
 * @param {*} param none
 * @param {*} callback 回调
 * @param {*} method post || get
 */
export const getCourse = (param,callback) => {
    return axios({
        url:`${host}/course_category`,
        method: 'get',
        params:param || {},
    }).then(data=>{
        if(callback) callback(data)
    })
}

/**
 * 发邮件
 * @param {*} param to, subject, html
 * @param {*} callback 回调
 */
export const sendMail = (param,callback) => {
    return axios({
        url:`${host}/send_mail`,
        method: 'get',
        params:param || {},
    }).then(data=>{
        if(callback) callback(data)
    })
}


/**
 * 获取验证码并发邮件
 * @param {*} param email
 * @param {*} callback 回调
 */
export const getVerificationCode = (param,callback) => {
    return axios({
        url:`${host}/get_verification_code`,
        method: 'get',
        params:param || {},
    }).then(data=>{
        if(callback) callback(data)
    })
}

/**
 * 改密码时候获取验证码
 * @param {*} param role, email
 * @param {*} callback 回调
 */
export const checkAndGetVerificationCode = (param,callback) => {
    return axios({
        url:`${host}/check_and_get_verification_code`,
        method: 'get',
        params:param || {},
    }).then(data=>{
        if(callback) callback(data)
    })
}

/**
 * 忘记密码中的更改密码
 * @param { JSON } param {role,email,password}
 * @param {*} callback 回调
 */
export const resetPassword = (param,callback) => {
    return axios.post(
        `${host}/reset_password`,param
    ).then(data=>{
        if(callback) callback(data)
    })
}

export const getCourseTime = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/get_course_time`,
        method: 'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

export const AdminGetDashboard= (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/admin/get_dashboard`,
        method: 'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

export const AdminGetLessonInfo= (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/admin/get_lesson_info`,
        method: 'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

export const AdminGetAllLesson= (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/admin/get_all_lesson`,
        method: 'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

export const GetAllLessonForTeacher= (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/get_all_lesson_for_teacher`,
        method: 'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 管理员更改lesson里的t_id
 * @param { JSON } param {new_teacher_id, selected_lesson_id, selected_student_id}
 * @param {*} callback 回调
 */
export const AdminUpdateTidInLessons = (param,callback) => {
    return axios.post(
        `${host}/admin/update_tid_in_lessons`,param
    ).then(data=>{
        if(callback) callback(data)
    })
}

/**
 * 管理员删除lessons
 * @param { JSON } param {selected_lesson_id, selected_student_id}
 * @param {*} callback 回调
 */
export const AdminDeleteLessons = (param,callback) => {
    return axios.post(
        `${host}/admin/delete_lessons`,param
    ).then(data=>{
        if(callback) callback(data)
    })
}

/**
 * 老师修改ZOOM
 * @param { JSON } param {username, password}
 * @param {*} callback 回调
 */
 export const updateZoom = (param,callback) => {
    return axios.post(
        `${host}/update_zoom`,param
    ).then(data=>{
        if(callback) callback(data)
    })
}

/**
 * 老师获取学生修改时间的请求表
 * @param { JSON } param {t_id}}
 * @param {*} callback 回调
 */
export const GetApplication= (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/get_application`,
        method: 'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 学生取消课程申请
 * @param { JSON } param {lesson_id, s_id, reply}
 * @param {*} callback 回调
 */
export const ReplyToApplication = (param,callback) => {
    return axios.post(
        `${host}/reply_to_application`,param
    ).then(data=>{
        if(callback) callback(data)
    })
}

/**
 * 学生取消课程申请
 * @param { JSON } param {username, password}
 * @param {*} callback 回调
 */
 export const cancelLesson = (param,callback) => {
    return axios.post(
        `${host}/cancel_lesson`,param
    ).then(data=>{
        if(callback) callback(data)
    })
}

/**
 * 获取课程信息
 * @param {*} param none
 * @param {*} callback 回调
 * @param {*} method post || get
 */
export const getSubjectInfo = (param,callback,method) => {
    let axiosRequest = axios({
        url:`${host}/get_subject_info`,
        method: method || 'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 获取最近热门课程
 * @param {*} param none
 * @param {*} callback 回调
 * @param {*} method post || get
 */
 export const getPopular = (param,callback,method) => {
    let axiosRequest = axios({
        url:`${host}/get_popular`,
        method: method || 'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 学生预定课程
 * @param { JSON } param {order_amount, order_time,s_id,sub_id,t_id}
 * @param {*} callback 回调
 */
 export const OrderCourse = (param,callback) => {
    return axios.post(
        `${host}/order_course`,param
    ).then(data=>{
        if(callback) callback(data)
    })
}

// /**
//  * 充值
//  * @param {*} param s_id
//  * @param {*} callback 回调
//  * @param {*} method post
//  */
// export const rechargeBalance = (param,callback,method) => {
//     return axios({
//         url:`${host}/recharge`,
//         method: method || 'post',
//         params:param || {},
//     }).then(data=>{
//         if(callback) callback(data)
//     })
// }

/**
 * 老师修改时间
 * @param { JSON } param {username, password}
 * @param {*} callback 回调
 */
 export const UpdateAvailableTime = (param,callback) => {
    return axios.post(
        `${host}/set_course_time`,param
    ).then(data=>{
        if(callback) callback(data)
    })
}

/**
 * 老师处理上课
 * @param { JSON } param {username, password}
 * @param {*} callback 回调
 */
 export const handleLessonState = (param,callback) => {
    return axios.post(
        `${host}/handle_lesson`,param
    ).then(data=>{
        if(callback) callback(data)
    })
}

/**
 * 获取老师信息
 * @param {*} param s_id
 * @param {*} if(callback) callback 回调
 * @param {*} method get
 */
export const getTeacherProfile = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/get_teacher_profile`,
        method: 'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 获取老师的学生
 * @param {*} param t_id
 * @param {*} if(callback) callback 回调
 * @param {*} method get
 */
export const getTeacherStudent = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/get_teacher_student`,
        method: 'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 获取正在上课
 * @param {*} param s_id
 * @param {*} if(callback) callback 回调
 * @param {*} method get
 */
 export const getPresentLesson = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/get_present_lesson`,
        method: 'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 获取老师课程
 * @param {*} param t_id
 * @param {*} if(callback) callback 回调
 * @param {*} method get
 */
 export const getTeacherLessons = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/get_current_course`,
        method: 'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 获取老师课程
 * @param {*} param t_id
 * @param {*} if(callback) callback 回调
 * @param {*} method get
 */
 export const getTeacherLessonsTime = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/lessons_time`,
        method: 'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}


/**
 * 获取学生信息
 * @param {*} param s_id
 * @param {*} if(callback) callback 回调
 * @param {*} method get
 */
export const getUserProfile = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/get_user_profile`,
        method: 'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 获取学生临时课程
 * @param {*} param s_id
 * @param {*} if(callback) callback 回调
 * @param {*} method get
 */
export const getTempLesson = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/get_templesson`,
        method: 'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 
 * @param {*} param s_id
 * @param {*} if(callback) callback 回调
 * @param {*} method get
 */
export const getProgress = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/get_progress`,
        method: 'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

// export const getPurchaseProgress = (param,callback) => {
//     let axiosRequest = axios({
//         url:`${host}/get_purchase_progress`,
//         method: 'get',
//         params:param || {},
//     })
//     if(callback){
//         return axiosRequest.then(data=>{
//             callback(data)
//         })
//     }
//     return axiosRequest
// }

/**
 * 获取临时OSS
 * @param {*} param s_id
 * @param {*} if(callback) callback 回调
 * @param {*} method get
 */
 export const getSTSOss = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/get_sts`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 学生获取全部课程
 * @param {*} param s_id
 * @param {*} if(callback) callback 回调
 * @param {*} method get
 */
export const getAllLesson = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/get_lessons`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 学生获取下一节课
 * @param {*} param s_id
 * @param {*} if(callback) callback 回调
 * @param {*} method get
 */
 export const getNextLesson = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/get_next_lesson`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

export const getAssignCourse = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/get_course_order`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

// export const orderCourse = (param,callback) => {
//     let axiosRequest = axios.post(`${host}/purchase_course`,param)
//     if(callback){
//         return axiosRequest.then(data=>{
//             callback(data)
//         })
//     }
//     return axiosRequest
// }

/**
 * 
 * @param {*} param 
 * @param {*} callback 
 */
export const getStudentTime = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/get_timeinfo`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}


/**
 * 获取代理未结算课程
 * @param {*} if(callback) callback 回调
 * @param {*} method get
 */
export const GetProxyNotSettleLessons = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/admin/get_not_settle_lessons`,
        method: 'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 设置假期
 * @param {*} param {t_id,time_stamp,lesson_id}
 * @param {*} callback 
 */
 export const setHoliday = (param,callback) => {
    let axiosRequest = axios.post(`${host}/set_holiday`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 设置隐藏学生
 * @param {*} param {t_id, s_id, checked}
 * @param {*} callback 
 */
 export const setVisibility = (param,callback) => {
    let axiosRequest = axios.post(`${host}/set_visibility`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 学生获取代理
 * @param {*} param {s_id}
 * @param {*} callback 
 */
 export const getProxy = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/proxy`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 学生获取可用老师信息
 * @param {*} param {s_id}
 * @param {*} callback 
 */
export const getTeacherTime = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/get_teacher_time`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 学生修改单项课程时间
 * @param {*} param {t_id,time_stamp,lesson_id}
 * @param {*} callback 
 */
export const alterLessonTime = (param,callback) => {
    let axiosRequest = axios.post(`${host}/submit_timechange`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

//---------------------------------------------admin

/**
 * 上传文件到服务器
 * @param { JSON } param {}
 * @param {*} callback 回调
 */
 export const uploadFileToServer = (param,callback) => {
    return axios.post(
        `${host}/admin/upload_file_to_server`,param,{headers:{"Content-Type":"multipart/form-data"}}
    ).then(data=>{
        if(callback) callback(data)
    })
}

/**
 * 添加分类
 * @param {*} param 
 * @param {*} callback 
 */
 export const AddCourseCate = (param,callback) => {
    let axiosRequest = axios.post(`${host}/admin/add_new_cate`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 获取员工列表
 * @param {*} param 
 * @param {*} callback 
 */
export const GetAllAdmin = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/admin/get_all_admin`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 管理员修改密码
 * @param { JSON } param password
 * @param {*} callback 回调
 */
export const AdminAlterPassword = (param,callback) => {
    return axios.post(
        `${host}/admin/alter_password`,param
    ).then(data=>{
        if(callback) callback(data)
    })
}

/**
 * 删除管理员
 * @param {*} param admin_id_del
 * @param {*} callback 
 */
export const DeleteAdmin = (param,callback) => {
    let axiosRequest = axios.post(`${host}/admin/delete_admin`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 删除学生
 * @param {*} param s_id
 * @param {*} callback 
 */
export const DeleteStudent = (param,callback) => {
    let axiosRequest = axios.post(`${host}/admin/delete_student`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 获取分类
 * @param {*} param 
 * @param {*} callback 
 */
 export const GetCourseInfo = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/admin/get_courseinfo`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}


/**
 * 管理员修改table的一行
 * @param {*} param { table_name, id_column, table_id, record }
 * @param {*} callback 
 */
export const AdminUpdateTableRow = (param,callback) => {
    let axiosRequest = axios.post(`${host}/admin/update_table_row`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 获取老师列表信息
 * @param {*} param 
 * @param {*} callback 
 */
export const AdminGetTeacher = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/admin/get_teacher`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 删除老师
 * @param {*} param t_id
 * @param {*} callback 
 */
export const AdminDeleteTeacher = (param,callback) => {
    let axiosRequest = axios.post(`${host}/admin/delete_teacher`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 删除课程
 * @param {*} param sub_id
 * @param {*} callback 
 */
 export const AdminDeleteSubject = (param,callback) => {
    let axiosRequest = axios.post(`${host}/admin/delete_subject`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 添加老师
 * @param {*} param username, password, email, description
 * @param {*} callback 
 */
export const AdminAddTeacher = (param,callback) => {
    let axiosRequest = axios.post(`${host}/admin/add_teacher`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 添加管理员
 * @param {*} param username, password, auth_id
 * @param {*} callback 
 */
export const AdminAddAdmin = (param,callback) => {
    let axiosRequest = axios.post(`${host}/admin/add_admin`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 获取老师课程分配
 * @param {*} param 
 * @param {*} callback 
 */
export const AdminGetAssigninfo = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/admin/get_assigninfo`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 删除课程分配
 * @param {*} param t_id, sub_id
 * @param {*} callback 
 */
export const AdminDeleteAssign = (param,callback) => {
    let axiosRequest = axios.post(`${host}/admin/delete_assign`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 添加授课分配
 * @param {*} param array
 * @param {*} callback 
 */
export const AdminAssignCourse = (param,callback) => {
    let axiosRequest = axios.post(`${host}/admin/assign_course`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 获取学生信息
 * @param {*} param 
 * @param {*} callback 
 */
export const AdminGetStudent = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/admin/get_student`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 获取学生进度信息
 * @param {*} param 
 * @param {*} callback 
 */
export const AdminGetStudentProgress = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/admin/get_student_progress`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 获取学生购买总数（从orders sheet）
 * @param {*} param 
 * @param {*} callback 
 */
 export const AdminGetStudentPurchaseProgress = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/admin/get_student_purchase_progress`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 代理获取新的课程预约
 * @param {*} param 
 * @param {*} callback 
 */
export const ProxyGetNewOrder = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/admin/get_student_order`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * bella获取代理安排课程请求
 * @param {*} param 
 * @param {*} callback 
 */
export const AdminGetLessonApplication = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/admin/get_lesson_application`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 获取orders_sheet
 * @param {*} param 
 * @param {*} callback 
 */
export const AdminGetOrderSheet = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/admin/get_order_sheet`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 获取老师结算表
 * @param {*} param 
 * @param {*} callback 
 */
export const AdminGetTeacherSheet = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/admin/get_teacher_sheet`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 获取已取消课时表
 * @param {*} param 
 * @param {*} callback 
 */
export const AdminGetCancelledLesson = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/admin/get_cancelled_lesson`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 获取过期课时
 * @param {*} param 
 * @param {*} callback 
 */
export const AdminGetOutdateLesson = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/admin/get_outdate_lesson`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 获取结算view
 * @param {*} param 
 * @param {*} callback 
 */
export const AdminGetSettleView = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/admin/get_settle_view`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 获取结算单
 * @param {*} param 
 * @param {*} callback 
 */
export const AdminGetSettleSheet = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/admin/get_settle_sheet`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 获取10节课程信息
 * @param {*} param 
 * @param {*} callback 
 */
export const AdminGetLessonsByIds = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/admin/get_lessons_byids`,
        method:'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 管理员确认已读取消的课时
 * @param {*} param lesson_id
 * @param {*} callback 
 */
export const AdminConfirmCancelledLesson = (param,callback) => {
    let axiosRequest = axios.post(`${host}/admin/confirm_cancelled_lesson`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 管理员确认过期的课时
 * @param {*} param lesson_id
 * @param {*} callback 
 */
export const AdminConfirmOutdateLesson = (param,callback) => {
    let axiosRequest = axios.post(`${host}/admin/confirm_outdate_lesson`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 管理员确认过期的课时
 * @param {*} param t_id, start, end, payment
 * @param {*} callback 
 */
 export const AdminConfirmPayTeacher = (param,callback) => {
    let axiosRequest = axios.post(`${host}/admin/confirm_pay_teacher`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * bella确认安排课程
 * @param {*} param id, reply
 * @param {*} callback 
 */
export const AdminConfirmLessonApplication = (param,callback) => {
    let axiosRequest = axios.post(`${host}/admin/handle_lesson_application`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 代理确认已处理新的课程预约
 * @param {*} param order_id
 * @param {*} callback 
 */
export const ConfirmHandleNewOrder = (param,callback) => {
    let axiosRequest = axios.post(`${host}/admin/confirm_handle_new_order`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 代理确认联系新学生
 * @param {*} param s_id
 * @param {*} callback 
 */
export const ConfirmNewStudent = (param,callback) => {
    let axiosRequest = axios.post(`${host}/admin/confirm_new_student`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 学生管理员给没有代理的新学生安排一个代理
 * @param {*} param s_id, proxy_id
 * @param {*} callback 
 */
export const AdminSetNewProxy = (param,callback) => {
    let axiosRequest = axios.post(`${host}/admin/set_new_proxy`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 学生发送反馈问题
 * @param {*} if(callback) callback 回调
 * @param {*} method get
 */
export const getStudentSummary = (param,callback) => {
    let axiosRequest = axios({
        url:`${host}/admin/get_student_summary`,
        method: 'get',
        params:param || {},
    })
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 学生管理员确认结算代理的课程安排账单
 * @param {*} param sheet_id
 * @param {*} callback 
 */
export const AdminConfirmOrderSheetHandle = (param,callback) => {
    let axiosRequest = axios.post(`${host}/admin/confirm_order_sheet_handle`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 代理安排课程
 * @param {*} param student_num, subject_code, teacher_num, order_amount, pay_amount, pay_num
 * @param {*} callback 
 */
export const ProxyArrangeCourse = (param,callback) => {
    let axiosRequest = axios.post(`${host}/admin/apply_lessons`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 管理员推迟或提前课程时间
 * @param {*} param 
 * @param {*} callback 
 */
 export const AdvanceAndPostpone = (param,callback) => {
    let axiosRequest = axios.post(`${host}/admin/advanceAndPostpone`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}

/**
 * 管理员补课程
 * @param {*} param 
 * @param {*} callback 
 */
export const AdminAddLessons = (param,callback) => {
    let axiosRequest = axios.post(`${host}/admin/add_lessons`,param)
    if(callback){
        return axiosRequest.then(data=>{
            callback(data)
        })
    }
    return axiosRequest
}