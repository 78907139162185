import React from 'react';
import { Button, Result, Select, InputNumber, message, Spin,TreeSelect,Radio } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { AlertMsg } from '../PublicMethods';
import { AdminAddLessons, AdminGetStudent, AdminGetStudentProgress, AdminGetStudentPurchaseProgress, axiosAll } from '../../request/axios';
import { WeekTimeTree } from '../../map'

const { Option } = Select

class InsertLesson extends React.Component {

    state = {
        success: false,
        students: [],
        progress: [],
        selected_s_id: null,
        selected_sub_id:null,
        number_of_lessons:1,
        max:0,
        period:[],
        radio:1,
        loading:true,
    }

    LoadData(){
        axiosAll([
			AdminGetStudent({admin_id:sessionStorage.getItem('admin_id'),all:true}),
			AdminGetStudentProgress({admin_id:sessionStorage.getItem('admin_id'),all:true})
		],results=>{
            console.log()
            if(results[0].data.status!==100){
                AlertMsg({message:results[0].data.tips,des:"查询课程数据出错"})
                return
            }
            if(results[1].data.status!==100){
                AlertMsg({message:results[1].data.tips,des:"查询课程数据出错"})
                return
            }
            let result1 = results[0].data.result,result2 = results[1].data.result
            result1.forEach((el,index)=>{
                el.key = index
            })
            result2.forEach((el,index)=>{
                el.key = index
            })
            this.setState({
                students: result1,
                progress: result2,
                loading:false,
                success:false
            })
		},err=>{
			AlertMsg({message:'网络错误',des:'网络连接出错'})
			console.log(err)
		})


        // AdminGetStudent({admin_id:sessionStorage.getItem('admin_id'),all:true},request_res=>{
        //     const {data:{result,status,tips="查询错误"}} = request_res
        //     if(status!==100){
        //         AlertMsg({message:tips,des:"查询课程数据出错"})
        //     }else{
		// 		result.forEach((el,index)=>{
		// 			el.key = index
		// 		})
        //         this.setState({
		// 			students: result
		// 		})
        //     }
        // }).catch(err=>{
        //     AlertMsg({message:"网络错误",des:"请求数据出错"})
        //     console.log(err.message)
        // })

        // AdminGetStudentProgress({admin_id:sessionStorage.getItem('admin_id'),all:true},request_res=>{
        //     const {data:{result,status,tips="查询错误"}} = request_res
        //     if(status!==100){
        //         AlertMsg({message:tips,des:"查询课程数据出错"})
        //     }else{
		// 		result.forEach((el,index)=>{
        //             el.key = index
		// 		})
        //         this.setState({
        //             progress: result,
        //             loading:false,
        //             success:false
		// 		})
        //     }
        // }).catch(err=>{
        //     AlertMsg({message:"网络错误",des:"请求数据出错"})
        //     console.log(err.message)
        // })
    }

    componentDidMount(){
        this.LoadData()
    }
    
    onStudentChange(value){
        this.setState({
            selected_s_id:value,
            selected_sub_id:null,
            number_of_lessons:1,
            max:0,
            period:[],
            radio:1,
        })
    }

    onSubjectChange(value){
        AdminGetStudentPurchaseProgress({admin_id:sessionStorage.getItem('admin_id'),s_id:this.state.selected_s_id,sub_id:value},request_res=>{
            const {data:{result,status,tips="查询错误"}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"查询课程数据出错"})
            }else{
                let sub_progress = null
                this.state.progress.forEach(pro=>{
                    if(pro.s_id === this.state.selected_s_id && pro.sub_id === value){
                        sub_progress = pro.progress
                    }
                })
                this.setState({
                    selected_sub_id:value,
                    max:result['total']-sub_progress,
                    number_of_lessons:1,
                    period:[],
                    radio:1,
				})
                message.info(`这个学生这门课代理账单为${result['total']}节，已有课时为${sub_progress}节。`)
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
    }

    onChange(value) {
        this.setState({
            number_of_lessons:value
        })
    }
    
    onPeriodChange = value => {
        this.setState({ period:value });
    };

    onRadioChange = e => {
        this.setState({ radio:e.target.value,period:[] })
    };

    submit(){
        const { selected_s_id, selected_sub_id, number_of_lessons,period } = this.state
        if(!selected_s_id || !selected_sub_id ){
            message.warning("学生，课程不能为空")
            return
        }
        let values = {
            admin_id:sessionStorage.getItem('admin_id'),
            s_id:selected_s_id, 
            sub_id:selected_sub_id, 
            amount:number_of_lessons,
            period
        }
        AdminAddLessons(values,request_res=>{
			const {data:{status,tips}} = request_res
			if(status!==100){
				AlertMsg({message:'数据错误',des:tips})
				return
            }
            message.success('添加成功！')
			this.setState({
                success:true,
                students: [],
                progress: [],
                selected_s_id: null,
                selected_sub_id:null,
                number_of_lessons:1,
                max:0,
                period:[],
                radio:1,
			})
		}).catch(err=>{
			AlertMsg({message:'网络错误',des:'网络连接出错'})
			console.log(err)
		})
    }

    render(){
        if(this.state.loading){
            return <Spin size="large"/>
        }
        if (this.state.success === true){
            return <Result
                icon={<SmileOutlined />}
                title={"课时补充完成！"}
                subTitle="成功"
                extra={[
                    <Button key="1" type="primary" onClick={() => this.LoadData()}>
                        好的
                    </Button>
                ]}
            />
        }else{
            const {period,students,selected_s_id,progress,selected_sub_id,radio,max} = this.state
            return <>
                <h2><b>选择学生</b></h2>
                <Select
                    showSearch
                    style={{minWidth:200}}
                    placeholder="选择学生"
                    optionFilterProp="children"
                    onChange={this.onStudentChange.bind(this)}
                    value={this.state.selected_s_id}
                >
                    {
                        students.map((student, index) => {
                            return <Option key={index} value={student.s_id}>{`${student.username} : ${student.student_num}`}</Option>
                        })
                    }
				</Select>
                <h2><b>选择课程</b></h2>
                <Select
                    showSearch
                    disabled={!selected_s_id}
                    style={{minWidth:200}}
                    placeholder="选择课程"
                    optionFilterProp="children"
                    onChange={this.onSubjectChange.bind(this)}
                    value={this.state.selected_sub_id}
                >
                    {
                        progress.map((pro, index) => {
                            if(pro.s_id === selected_s_id){
                                return <Option key={index} value={pro.sub_id}>{`${pro.name.split('||')[1]} : ${pro.subject_code}`}</Option>
                            }else{
                                return null
                            }
                            
                        })
                    }
				</Select>
                <br />
                <Radio.Group onChange={this.onRadioChange} value={radio} style={{margin:"15px auto"}}>
                    <Radio value={1}>原周期</Radio>
                    <Radio value={2}>新的周期</Radio>
                </Radio.Group>
                {radio===2?
                    <TreeSelect 
                        allowClear
                        treeData={WeekTimeTree}
                        value= {period}
                        onChange={this.onPeriodChange} 
                        treeCheckable={true}
                        placeholder='Please select'
                        style={{
                            width: '30%',
                        }}
                    />
                    :
                    <p>原周期将按照还未上完的课程周期进行排序</p>
                }
                <h2><b>补充课时节数</b></h2>
                <InputNumber min={1} defaultValue={1} max={max} onChange={(value)=>this.onChange(value)} disabled={!selected_sub_id || max<=0}/>
                {(!selected_sub_id || max<=0)?<p>无需追加</p>:<p>最多追加{max}节</p>}
                <br/>
                <Button type="primary" disabled={!selected_sub_id || max<=0} onClick={()=>this.submit()}>确认添加</Button>
            </>
        }
    }
}

export default InsertLesson;