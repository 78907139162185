import React from 'react';
import { Form, Input, Button, message, Result } from 'antd';
import { AddCourseCate } from '../../request/axios'
import { AlertMsg } from '../PublicMethods';
import { SmileOutlined } from '@ant-design/icons';

class AddCategory extends React.Component {
    
    state = {
        success:null,
    }

    onFinish = (values) => {
        const {course_des_CN,course_des_EN,course_name_CN,course_name_EN,...send_value} = values
        send_value.course_des = `${course_des_EN}||${course_des_CN}`
        send_value.course_name = `${course_name_EN}||${course_name_CN}`
        send_value.admin_id = sessionStorage.getItem('admin_id')
        AddCourseCate(send_value,data=>{
            const {data:{status,tips}} = data
            if(status!==100){
                AlertMsg({message:'错误',des:tips})
            }else{
                this.setState({success:true})
                message.success('添加成功！')
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"数据出错"})
            console.log(err.message)
        })
    }

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }
    
    render(){
        if(this.state.success != null){
            return <Result
                icon={<SmileOutlined />}
                title={"添加新分类成功！"}
                subTitle="前往'全部分类'查看。"
                extra={[
                    <Button type="primary" onClick={() => this.setState({success:null})}>
                        继续添加
                    </Button>
                ]}
            />
        }else{
            const layout = {
                labelCol: {
                    span: 8,
                },
                wrapperCol: {
                    span: 8,
                },
            }
            const tailLayout = {
                wrapperCol: {
                    offset: 6,
                    span: 12,
                },
            }
            const checkCode = (rule, value, callback)=>{
                if (value) {
                if (/[a-z]+/g.test(value)) {
                    callback(new Error('课程编码需要大写'));
                    return
                }
                }
                callback();
            }
            const checkEnglishValue =  (rule, value, callback) => {
                if (value) {
                if (/[\u4E00-\u9FA5]/g.test(value)) {
                    callback(new Error('不能输入汉字!'));
                    return
                }
                }
                callback();
            }
            const checkChineseValue =  (rule, value, callback) => {
                if (value) {
                if (!/[\u4E00-\u9FA5]/g.test(value)) {
                    callback(new Error('请输入中文描述'));
                    return
                }
                }
                callback();
            }      
            return <>
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={this.onFinish}
                    >
                    <Form.Item
                        label="分类代码"
                        name="course_code"
                        rules={[
                        {
                            required: true,
                            message: '请输入新课的分类代码，确保不要重复！',
                        },
                        { validator: checkCode, trigger: 'blur'}
                        ]}
                    >
                        <Input placeholder="IB-KD"/>
                    </Form.Item>

                    <Form.Item
                        label="分类名称(中)"
                        name="course_name_CN"
                        rules={[
                        {
                            required: true,
                            message: '请输入一个分类的中文名称！',
                        },
                        { validator: checkChineseValue, trigger: 'blur'}
                        ]}
                    >
                        <Input placeholder="幼儿园课程"/>
                    </Form.Item>
                    <Form.Item
                        label="分类名称(英)"
                        name="course_name_EN"
                        rules={[
                        {
                            required: true,
                            message: '请输入一个分类的英文名称！',
                        },
                        { validator: checkEnglishValue, trigger: 'blur'}
                        ]}
                    >
                        <Input placeholder="Kindergarten Programme"/>
                    </Form.Item>

                    <Form.Item
                        label="分类描述(中)"
                        name="course_des_CN"
                        rules={[
                        {
                            required: true,
                            message: '请输入中文分类描述！',
                        },
                        { validator: checkChineseValue, trigger: 'blur'}
                        ]}
                    >
                        <Input.TextArea placeholder='中文简介'/>
                    </Form.Item>

                    <Form.Item
                        label="分类描述(英)"
                        name="course_des_EN"
                        rules={[
                        {
                            required: true,
                            message: '请输入英文分类描述！',
                        },
                        { validator: checkEnglishValue, trigger: 'blur'}
                        ]}
                    >
                        <Input.TextArea placeholder='English description'/>
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            新增分类
                        </Button>
                    </Form.Item>
                </Form>
            </>
        }
    }
}

export default AddCategory;