import React from 'react';
import { Typography, Button, Descriptions, Rate } from 'antd';
import {ThunderboltFilled} from '@ant-design/icons'
import { TransText, parseDescription } from '../PublicMethods';
import { GlobalContext } from '../../request/context'

const { Title } = Typography;

const blue9 = '#003a8c';

class Description extends React.Component {
    state = {
        buttonState: true
    }

    getNodeFromKey(key, value) {
        switch (key) {
            case "difficulty":
                return [TransText({'CN':'难度','EN':'Difficulty'}),<Rate style={{fontSize:'1.3em',color:'#0091ff'}} character={<ThunderboltFilled />} allowHalf disabled defaultValue={parseFloat(value)} />]
            case "grade":
                return [TransText({'CN':'课程等级','EN':'Grade'}),<div style={{ fontSize: '1.3em' }}><span style={{ fontSize: '1.2em', color: '#0091ff', marginRight: "10px" }}
                    className="iconfont icon-kecheng"></span>{value}</div>]
            case "course_code":
                return [TransText({'CN':'所属分类','EN':'Category'}),<b style={{ fontSize: '1.3em' }}>{value}</b>]
            case "subject_code":
                return [TransText({'CN':'课程代码','EN':'Course Code'}),<b style={{ fontSize: '1.3em' }}>{value}</b>]
            case "description":
                return [TransText({'CN':'概述','EN':'Description'}),parseDescription(value)]
            case "total_hour":
                return [TransText({'CN':'课时','EN':'Total Hour'}),<i style={{fontWeight:600}}>{value}节</i>]
            case "skill":
                return [TransText({'CN':'能力提升','EN':'Skill'}),value]
            case "duration":
                return [TransText({'CN':'课程时长','EN':'Duration'}),`${value} min`]
            default:
                return [key,value]
        }
    }

    render() {
        const { buttonState } = this.state
        return <>
            <div style={{ padding: 48 }}>
                <div style={{ maxWidth: 1200, margin: 'auto' }}>
                    <div id="text">
                        <Descriptions title={
                            <Title style={{ textAlign: 'left', color: blue9,width:"100%",fontSize:"5vw" }}><TransText CN='科目概观' EN='Course Overview' /></Title>
                        } bordered 
                        extra={sessionStorage.getItem('t_id')?<></>:<Button
                            onClick={() => {
                                let change = this.props.handleShowPay()
                                if(change){
                                    if (buttonState) {
                                        let ancorElement = document.getElementById('pay-layout')
                                        if (ancorElement) {
                                            ancorElement.scrollIntoView({ behavior: 'smooth' });
                                        }
                                    }
                                    this.setState({ buttonState: !buttonState })
                                }
                            }}
                            style={{ marginTop: 20, backgroundColor: blue9, color: 'white', display: 'flex' }}>
                            {buttonState ? <TransText CN='立刻报名' EN='Enrol Now' /> : <TransText CN='收起' EN='Close' />}
                        </Button>
                        }
                            column={{ xs: 8, sm: 16, md: 24 }}
                        >
                            {
                                this.context.map((des, index) => {
                                    return <Descriptions.Item
                                        key={index}
                                        span={des.span || 1}
                                        label={this.getNodeFromKey(des.name, des.value)[0]}>{this.getNodeFromKey(des.name, des.value)[1]}</Descriptions.Item>
                                })
                            }
                        </Descriptions>
                    </div>
                </div>
            </div>
        </>
    }
}
Description.contextType = GlobalContext
export default Description