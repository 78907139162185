import React from 'react';
import { Form, Input, Button, Select, message, Result,TreeSelect,Tooltip,DatePicker } from 'antd';
import { GlobalContext } from '../../request/context';
import { ProxyArrangeCourse, AdminGetStudent, axiosAll, GetCourseInfo, AdminGetTeacher } from '../../request/axios';
import { AlertMsg } from '../PublicMethods';
import { SmileOutlined } from '@ant-design/icons';
import { WeekTimeTree } from '../../map'


class ArrangeOrder extends React.Component {

    constructor(){
        super()
        this.state={
            record: null,
            students: [],
            subjects: [],
            teachers: [],
            success: null,
            periodTime:['0-0-0']
        }
    }

    componentDidMount(){
        axiosAll([
            AdminGetStudent({admin_id:sessionStorage.getItem('admin_id'),all:true}),
            GetCourseInfo({admin_id:sessionStorage.getItem('admin_id')}),
            AdminGetTeacher({admin_id:sessionStorage.getItem('admin_id')})
        ],result=>{
			result[0].data.result.forEach((el,index)=>{
                el.key = index
			})
            result[1].data.result.forEach((el,index)=>{
                el.key = index
			})
            result[2].data.result.forEach((el,index)=>{
                el.key = index
			})
			this.setState({
				students: result[0].data.result,
				subjects: result[1].data.result,
				teachers: result[2].data.result
			})
		},err=>{
			AlertMsg({message:'网络错误',des:'网络连接出错'})
			console.log(err)
		})
        this.setState({
            record:this.props.record
        })
    }

    onFinish = (values) => {
        values.subject_code = values.subject_code.split(",")[0]
        values.taken_time = values.taken_time.join(",")
        values.start_at = this.state.start_at
        let admin_id = sessionStorage.getItem('admin_id')
        ProxyArrangeCourse({...values, admin_id},request_res=>{
            const {data:{status,tips="查询错误"}} = request_res
            if(status!==100){
                AlertMsg({des:tips,message:"查询课程数据出错"})
            }else{
                if(this.context.record && this.context.record.order_id){
                    this.context.setRead(this.context.record.order_id)
                }
                this.setState({
                    success: true
                })
                message.success('已发送给学生管理员！');
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
    }

    onDateChange = (e)=>{
        this.setState({
            start_at:new Date(e).getTime()
        })
    }

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    formRef = React.createRef();
    onFill = () => {
        if(this.formRef.current){
            this.formRef.current.setFieldsValue(
                {...this.context.record}
            );
        }
    };

    onChange = value => {
        this.setState({ periodTime:value });
    };
    
    render(){
        const { success,subjects,periodTime,customize } = this.state
        if (success != null){
            return <Result
                icon={<SmileOutlined />}
                title={"安排请求已发送给管理员！"}
                subTitle="管理员通过后可在'安排课程记录'查看。"
                extra={[
                    <Button type="primary" onClick={() => this.setState({success:null})}>
                        继续添加
                    </Button>
                ]}
            />
        }else{
            this.onFill()
            const layout = {
                labelCol: {
                    span: 8,
                },
                wrapperCol: {
                    span: 8,
                },
            }
            const tailLayout = {
                wrapperCol: {
                    offset: 6,
                    span: 12,
                },
            }
            function disabledDate(cur){
                return cur && cur < Date.now()
            }
            return <>
                <Form
                    ref={this.formRef}
                    {...layout}
                    name="control-ref"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        label="选择学生"
                        name="student_num"
                        rules={[
                            {
                                required: true,
                                message: '请选择一个学生学号！',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            style={{minWidth:200}}
                            placeholder="选择学生"
                            optionFilterProp="children"
                        >
                            {
                                this.state.students.map((student, index) => {
                                    return <Select.Option key={index} value={student.student_num}>{`${student.username} : ${student.student_num}`}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="选择课程"
                        name="subject_code"
                        rules={[
                            {
                                required: true,
                                message: '请选择一个课程代码！',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            style={{minWidth:200}}
                            placeholder="选择课程"
                            optionFilterProp="children"
                            onChange={this.getSubId}
                        >
                            {
                                subjects.map((subject, index) => {
                                    return <Select.Option 
                                    key={index} 
                                    value={`${subject.subject_code},${index}`}>{`${subject.name.split('||')[1]} : ${subject.subject_code}`}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="选择教师"
                        name="teacher_num"
                        rules={[
                            {
                                required: true,
                                message: '请输入一个教师工号！',
                            },
                        ]}
                    >
                       <Select
                            showSearch
                            style={{minWidth:200}}
                            placeholder="选择教师"
                            optionFilterProp="children"
                        >
                            {
                                this.state.teachers.map((teacher, index) => {
                                    return <Select.Option key={index} value={teacher.teacher_num}>{`${teacher.username} : ${teacher.teacher_num}`}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="上课周期时间"
                        name="taken_time"
                        rules={[
                        {
                            required: true,
                            message: '请输入一个上课周期时间！',
                        },
                        ]}
                    >
                        <TreeSelect 
                        allowClear
                        treeData={WeekTimeTree}
                        value= {periodTime}
                        onChange={this.onChange} 
                        treeCheckable={true}
                        placeholder='Please select'
                        style={{
                            width: '100%',
                        }}
                    />
                        
                    </Form.Item>
                    <Form.Item
                        label="起始日期"
                        name="start_at"
                        rules={[
                            {
                                required: false,
                                message: '请输入课时数！',
                            },
                        ]}
                    >
                    {
                        customize
                        ?
                        <>
                        <DatePicker 
                        disabledDate={disabledDate}
                        onChange={this.onDateChange} /><Button onClick={()=>this.setState({customize:false,start_at:null})}>取消</Button>
                        </>
                        :
                        <Tooltip title="点击选择自定义周期开始时间,输入值为空或者不选则默认按照正常时间开始排课">
                            <Button onClick={()=>this.setState({customize:true})}>自定义起始日期</Button>
                        </Tooltip>
                    }
                          
                    </Form.Item>
                    <Form.Item
                        label="课时数"
                        name="order_amount"
                        rules={[
                            {
                                required: true,
                                message: '请输入课时数！',
                            },
                        ]}
                    >
                        <Input placeholder="30" />
                    </Form.Item>
                    
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            新增课程
                        </Button>
                    </Form.Item>
                </Form>
            </>
        }
    }
}

ArrangeOrder.contextType = GlobalContext
export default ArrangeOrder;