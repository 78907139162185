import React from 'react';
import { Table, Input, Button, Space, Typography, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { AdminGetOrderSheet } from '../../request/axios';
import { AlertMsg } from '../PublicMethods';
const { Text } = Typography;

class SearchableTable extends React.Component {
	state = {
	  searchText: '',
	  searchedColumn: '',
	  data: [],
	  loading:true
	};
    
	loadData(){
        AdminGetOrderSheet({admin_id:sessionStorage.getItem('admin_id')},data=>{
			const {data:{result,status,tips}} = data
			if(status!==100){
				AlertMsg({message:'数据错误',des:tips})
				return
            }
			result.forEach((el,index)=>{
				el.key = index
			})
			this.setState({
				data: result,
				loading:false
			})
		}).catch(err=>{
			AlertMsg({message:'网络错误',des:'网络连接出错'})
			console.log(err)
		})
	}
    
	componentDidMount(){
		this.loadData()
	}

	getColumnSearchProps = dataIndex => ({
	  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		<div style={{ padding: 8 }}>
		  <Input
			ref={node => {
			  this.searchInput = node;
			}}
			placeholder={`搜索 ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ width: 188, marginBottom: 8, display: 'block' }}
		  />
		  <Space>
			<Button
			  type="primary"
			  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  icon={<SearchOutlined />}
			  size="small"
			  style={{ width: 90 }}
			>
			  搜索
			</Button>
			<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  重置
			</Button>
		  </Space>
		</div>
	  ),
	  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	  onFilter: (value, record) =>
		record[dataIndex]
		  ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		  : '',
	  onFilterDropdownVisibleChange: visible => {
		if (visible) {
		  setTimeout(() => this.searchInput.select(), 100);
		}
	  },
	  render: text =>
		this.state.searchedColumn === dataIndex ? (
		  <Highlighter
			highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			searchWords={[this.state.searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
		  />
		) : (
		  text
		),
	});
  
	handleSearch = (selectedKeys, confirm, dataIndex) => {
	  confirm();
	  this.setState({
		searchText: selectedKeys[0],
		searchedColumn: dataIndex,
	  });
	};
  
	handleReset = clearFilters => {
	  clearFilters();
	  this.setState({ searchText: '' });
	};
  
	render() {
		if(this.state.loading){
            return <Spin size="large"/>
        }
		const columns = [
            {
                title: '代理工号',
                dataIndex: 'admin_num',
				key: 'admin_num',
				...this.getColumnSearchProps('admin_num'),
            },
            {
                title: '学生学号',
                dataIndex: 'student_num',
				key: 'student_num',
				...this.getColumnSearchProps('student_num'),
            },
            {
                title: '课程代码',
                dataIndex: 'subject_code',
				key: 'subject_code',
				...this.getColumnSearchProps('subject_code'),
            },
            {
                title: '教师工号',
                dataIndex: 'teacher_num',
				key: 'teacher_num',
				...this.getColumnSearchProps('teacher_num'),
            },
            {
                title: '购买课时数',
                dataIndex: 'order_amount',
				key: 'order_amount',
				align:'center'
            },
            {
                title: '结算状态',
                dataIndex: 'pay_salary',
                key: 'pay_salary',
                render: (text) =>{
                    if(text === 1){
                        return <>
                            <Text type="success">已结算</Text>
                        </>
                    }else{
                        return <>
                            <Text type="warning">等待结算</Text>
                        </>
                    }
                },
				align:'center'
            },
        ];
	  	return <Table bordered columns={columns} dataSource={this.state.data} pagination={{ pageSize: 20 }} size="middle"/>;
	}
}

class ViewProxyOrderSheet extends React.Component {
    render(){
        return <>
            <SearchableTable />
        </>
    }
}

export default ViewProxyOrderSheet;