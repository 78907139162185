import React, {Component } from 'react';
import { Layout,notification,Button,message } from 'antd';
import TopNav from '../components/TopNav';
import { SideMenu } from '../components/userprofile/SideMenu';
import { Summary, AvailableTime } from '../components/teacherprofile/Teacher';
import AlterTimeForm from '../components/teacherprofile/AlterTimeForm';
import Timetable  from '../components/userprofile/TimeTable'
import ChangeIngo from '../components/userprofile/ChangeInfo'
import { CheckIfLogIn,SplitENCN,socketMessage,TransText,AlertMsg,DateFormat } from '../components/PublicMethods';
import LessonsInfo from '../components/teacherprofile/LessonsInfo'
import UpcomingLessons from '../components/teacherprofile/UpcomingLessons'
import { getTeacherProfile, getTeacherStudent, axiosAll, getAssignCourse, getTeacherLessonsTime,UpdateAvailableTime,endHoliday } from '../request/axios';
import {GlobalContext} from '../request/context'
import "../style/components/teacher_page.css"
import { UserOutlined, TableOutlined, ReadOutlined, FormOutlined, ExclamationCircleOutlined, OrderedListOutlined } from '@ant-design/icons';

const { Sider, Header } = Layout;

class TeacherProfile extends Component{
    constructor(){
        super()
        this.state={
            t_id:sessionStorage.getItem('t_id'),
            teacher_info:{},
            tag_selected:0,
            tag_list:[
                {
                    tag:<><UserOutlined />{'My Profile'}</>,
                    node:<></>
                },
                {
                    tag:<><FormOutlined/>{'Edit Available Time'}</>,
                    node:<AvailableTime node={<Timetable 
                        type="teacher" 
                        t_id={sessionStorage.getItem('t_id')} 
                        getTime={(value=[])=>{
                            let postValue = {}
                            let timeObject = {}
                            value.forEach(course=>{
                                timeObject[course.sub_id] = course.available_time.sort().join(',')
                            })
                            postValue.t_id = sessionStorage.getItem('t_id')
                            postValue.time_object = timeObject
                            UpdateAvailableTime(postValue,data=>{
                                const {data:{status,tips}} = data
                                if(status!==100){
                                    AlertMsg({message:'Update Error',des:tips,type:'error'})
                                }else{
                                    AlertMsg({message:'Success',des:"Your course time was updated",type:'success',callback:()=>{
                                        this.setState({tag_selected:1})
                                    }})
                                }
                            }).catch(err=>{
                                AlertMsg({message:'网络错误',des:'请检查网络连接'})
                            })
                        }}
                        />} 
                        title="Edit Available Time" />
                },
                {
                    tag:<><OrderedListOutlined />{'My Timetable'}</>,
                    node:<UpcomingLessons/>
                },
                {
                    tag:<><TableOutlined/>{'Weekly Arrangements'}</>,
                    node:<></>
                },
                {
                    tag:<><ReadOutlined/>{'Course Resourses'}</>,
                    node:<LessonsInfo />
                },
                {
                    tag:<><ExclamationCircleOutlined />{'Time Change Requests'}</>,
                    node:<AlterTimeForm />
                }
            ]
        }
    }

    componentDidMount(){
        //检查是否登录
        if(CheckIfLogIn() === 1){
            if(sessionStorage.getItem('s_id')){
                window.location.href = "/userprofile"
                return 
            }
            if(sessionStorage.getItem('admin_id')){
                window.location.href = "/admin"
                return 
            }
        }else{
            window.location.href = "/login"
            return 
        }
        axiosAll([
            getTeacherProfile({t_id:this.state.t_id}),
            getTeacherStudent({t_id:this.state.t_id}),
            getAssignCourse({t_id:this.state.t_id}),
            getTeacherLessonsTime({t_id:sessionStorage.getItem('t_id')})
        ],result=>{
            let[profile, my_students, assign,lesson_time] = [result[0].data.result, result[1].data.result, result[2].data.result, result[3].data.result]
            let [...tag_list] = this.state.tag_list
            tag_list[0].node = <Summary teacher_info={profile} assign={assign} my_students={my_students} displayEditPage={this.displayEditPage.bind(this)} />
            let timeObj = {}
            lesson_time.forEach(el=>{
                const {time_index,name,code,username,lesson_time,duration} = el
                timeObj[time_index] = {
                    name:SplitENCN(name).EN,
                    code,
                    time:parseInt(lesson_time),
                    username,
                    duration,
                }
            })
            tag_list[3].node = <div style={{padding:'40px',width:'100%'}}>
            <Timetable 
                type="teacher" 
                time_data={timeObj}
            />
            </div>
            this.socket()
            if(sessionStorage.getItem("holiday") && Date.now()>parseInt(sessionStorage.getItem("holiday").split(","))){
                this.openHolidayNotification(sessionStorage.getItem("holiday").split(","))
            }
            this.setState({
                tag_list,
                teacher_info:profile
            })
        },error=>{
            console.log(error)
        })
    }

    openHolidayNotification = (period) => {
        const key = `open${Date.now()}`;
        const btn = (
          <Button type="danger" size="small" onClick={() =>{
            endHoliday({t_id:sessionStorage.getItem("t_id"),period,},data=>{
                const {data:{status,tips}} = data
                if(status!==100){
                    message.warning(tips)
                    return
                }
                sessionStorage.setItem("holiday","null")
                message.success("Welcome back from holiday")
                notification.close(key)
                setTimeout(()=>window.location.reload(),1500)
            }).catch(err=>{
                console.log(err)
            })
          }}>
            End Holiday Now
          </Button>
        );
        notification.open({
          message: 'Holiday Time',
          description:parseInt(period[1])<Date.now()
          ?
          <><p>Your vacation is over</p><p>End date:<b>{DateFormat("Y/M/D",parseInt(period[1]))}</b></p><p>Please click to end the holiday</p></>
          :
          <><p>You are on holiday</p><p>Start date:<b>{DateFormat("Y/M/D",parseInt(period[0]))}</b></p><p>End date:<b>{DateFormat("Y/M/D",parseInt(period[1]))}</b></p></>
          ,
          btn,
          duration: 0,
          key,
        });
      };

    socket(){
        const socket = this.context.socket
        socketMessage(socket,"teacher",sessionStorage.getItem("t_id"),onlineData=>{
            this.openNotification(onlineData)
        },offLineData=>{
            offLineData.forEach(data=>{
                this.openNotification(data)
            })
        })
    }

    openNotification = (msg) => {
        const args = {
          message: TransText({CN:"课程通知",EN:"Notification"}),
          description:
          msg,
          duration: 0,
        };
        notification.open(args);
    };

    displayEditPage(){
        let [...tag_list] = this.state.tag_list
        tag_list[0].node = <ChangeIngo role="teacher" back={this.backTo.bind(this)} />
        this.setState({
            tag_list,
        })
    }

    backTo(){
        let t_id_obj = {t_id:sessionStorage.getItem("t_id")}
        axiosAll([
            getTeacherProfile(t_id_obj),
            getAssignCourse(t_id_obj),
            getTeacherStudent(t_id_obj),
        ],result=>{
            let[profile, assign,my_students] = [result[0].data.result, result[1].data.result,result[2].data.result]
            let [...tag_list] = this.state.tag_list
            tag_list[0].node = <Summary teacher_info={profile} assign={assign} my_students={my_students} displayEditPage={this.displayEditPage.bind(this)} />
            this.setState({
                tag_list,
            })
        },error=>{
            console.log(error)
        })
    }

    handleTagChange(index){
        this.setState({
            tag_selected:index
        })
    }

    render(){
        const {tag_selected,tag_list} = this.state
        const content = tag_list[tag_selected].node
        return <>
            <Layout style={{ minHeight:'100vh' }}>
                <Header style={{backgroundColor: 'white'}}>
                    <TopNav menuItem={'6'}/>
                </Header>
                <Layout>
                    <Sider width={240}>
                        <SideMenu 
                        menuItem={'0'} 
                        menu={tag_list} 
                        divider={new Map()}
                        tagClick = {this.handleTagChange.bind(this)}
                        />
                    </Sider>
                    {content}
                </Layout>
            </Layout>
        </>
    }
}

TeacherProfile.contextType = GlobalContext

export default TeacherProfile;