import React, { Component } from 'react';
import { Layout, Slider, InputNumber, Row, Col, message, Spin,Modal } from 'antd';
import TopNav from '../components/TopNav';
import Banner from '../components/subject/Banner';
import Description from '../components/subject/Description';
import Footer1 from '../components/Footer1';
import TimeTable from '../components/userprofile/TimeTable'
import { getSubjectInfo,axiosAll,getProgress,OrderCourse} from '../request/axios';
import { AlertMsg,TransText } from '../components/PublicMethods';
import {GlobalContext} from '../request/context'
import {SubjectDes} from '../map'
import '../style/pages/subject.css'

const { Header, Content, Footer } = Layout;
const {Provider} = GlobalContext
const { confirm } = Modal;

class Course extends Component {
    constructor() {
        super()
        this.state = {
            sub_id: 0, //从url里拿
            student_info:{},//购买进度和已上进度
            subject_info: {},
            teachers: [],
            show_pay: false,
            inputValue: 1,
            loading:true
        }
    }

    componentDidMount() {
        const sub_id = window.location.search.split('?')[1]
        const s_id = sessionStorage.getItem('s_id') || 0
        axiosAll([
            getSubjectInfo({sub_id: sub_id}),
            getProgress({s_id:s_id, sub_id:sub_id})
        ],result=>{
            this.setState({
                sub_id,
                subject_info:result[0].data.result,
                student_info:result[1].data.result[0] || {},
                loading:false
            })
        },error=>{
            AlertMsg({ message: "网络错误", des: "请求数据出错" })
            console.log(error.message)
        })
    }

    onChange = value => {
        this.setState({
            inputValue: value,
        });
    };

    // 表格对象所需资料
    getDesObjectFrom = ()=>{
        const {subject_info} = this.state
        let output = []
        Object.keys(subject_info).forEach(key=>{
            if(key==='config'&&subject_info[key]){
                let configObj = JSON.parse(subject_info[key])
                if(configObj){
                    Object.keys(configObj).forEach(cfgkey=>{
                        if(!SubjectDes.get(cfgkey)) return
                        let {order,...rest} = SubjectDes.get(cfgkey)
                        output[order] = {...rest,value:configObj[cfgkey]}
                    })
                }
            }else{
                if(!SubjectDes.get(key)) return
                let {order,...rest} = SubjectDes.get(key)
                output[order] = {...rest,value:subject_info[key]}
            }
        })
        return output
    }

    render() {
		if(this.state.loading){
            return <Spin size="large"/>
        }
        const { subject_info,student_info, show_pay,inputValue,sub_id } = this.state
        const left_classes = (subject_info.total_hour || 0)-(student_info.progress%subject_info.total_hour || 0)
        return <>
            <Layout>
                <Header style={{ backgroundColor: 'white' }}>
                    <TopNav />
                </Header>
                <Content>
                    <Banner sub_name={subject_info.name} />
                <Provider value={this.getDesObjectFrom()}>
                    <Description
                        handleShowPay={() =>{
                            if(sessionStorage.getItem('s_id')){
                                if(student_info.progress !== student_info.pathway){
                                    message.info('请先上完已购的课程再进行新的预约。')
                                    return false
                                }else{
                                    this.setState({ show_pay: !show_pay })
                                    return true
                                }
                            }else{
                                AlertMsg({ message: "用户账户", des: "请先进行登录账户",callback:()=>window.location.href='/register' })
                                return false
                            }
                        }} />
                </Provider>
                </Content>
                <Content>
                    <div
                        id='pay-layout'
                        style={show_pay?{ height:'auto',padding:'48px'} : {height:0,padding:0}}>
                        <div>
                            <h2><TransText CN='选择课时' EN='Select the class times' /></h2>
                            <Row>
                                <Col span={8}>
                                    <Slider
                                        min={1}
                                        max={left_classes}
                                        onChange={this.onChange}
                                        value={typeof inputValue === 'number' ? inputValue : 0}
                                    />
                                </Col>
                                <Col span={8}>
                                    <label>
                                        <TransText CN='你选择的课时' EN='Number you selected' />
                                    </label>
                                    <InputNumber
                                        min={1}
                                        max={left_classes}
                                        style={{ margin: '0 16px' }}
                                        value={inputValue}
                                        onChange={this.onChange}
                                    />
                                </Col>
                                <Col span={8}>
                                    <div style={{height:"100%",lineHeight:'32px',fontSize:"1.1em"}}>
                                        本科目您还剩余<span style={{fontSize:"1.4em",margin:'0 5px'}}>{left_classes}</span>个课时
                                    </div>
                                </Col>
                            </Row>
                            {
                                sub_id?
                                <TimeTable type="student" sub_id={sub_id} duration={subject_info.duration} getTime={(timeObj)=>{
                                    if(timeObj.order_time.length){
                                        confirm({
                                            content:<p>请确认您选择的老师和时间,选择的课时为<b>{inputValue}节</b></p>,
                                            onCancel:()=>{},
                                            onOk:()=>{
                                                let insertValue={
                                                    sub_id,
                                                    ...timeObj,
                                                    s_id:sessionStorage.getItem('s_id'),
                                                    order_amount:inputValue
                                                }
                                                OrderCourse(insertValue,data=>{
                                                    const {data:{tips}} = data
                                                    if(tips){
                                                        AlertMsg({message:'出错了!',des:tips,type:'error'})
                                                        return
                                                    }
                                                    AlertMsg({message:'预约成功',des:"您已经成功预约该课程,我们会尽快与您联系。",type:'success',callback:()=>{
                                                        window.location.href='/course'
                                                    }})
                                                }).catch(err=>{
                                                    console.log(err)
                                                })
                                            }
                                        })
                                    }else{
                                        message.warning("您未选择课程时间")
                                    }
                                }} />
                                :
                                <></>
                            }
                            
                        </div>
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center', backgroundColor: '#fafafa' }}>
                    <Footer1 />
                </Footer>
            </Layout>
        </>
    }
}

export default Course;