import React from 'react';
import { Table, Input, Button, Space, Select, message, Result, Col, Row, Divider, Popconfirm, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { AdminDeleteLessons, AdminGetAllLesson, AdminGetStudent, AdminUpdateTidInLessons,getTeacherTime,AdvanceAndPostpone } from '../../request/axios'
import { AlertMsg, DateFormat } from '../PublicMethods';
import { SmileOutlined } from '@ant-design/icons';
import {DelayTimeList} from '../../map'

const { Option } = Select

class SearchableTable extends React.Component {
	state = {
	  searchText: '',
	  searchedColumn: '',
	  data: [],
	  students: [],
	  teachers: [],
	  teacher_time:[],
	  new_teacher_id: null,
	  selected_lesson_id:[],
	  selected_student_id:[],
	  selected_sub_id:[],
	  success:false,
	  loading:true,
	  table_loading:false,
	  moveTime:0,
	  moveTimeMount:null,
	  s_id:null,
	  total_price:0
	};
	
	loadData(){
		AdminGetStudent({admin_id:sessionStorage.getItem('admin_id'),all:true},request_res=>{
            const {data:{result,status,tips="查询错误"}} = request_res
            if(status!==100){
                AlertMsg({message:tips,des:"查询课程数据出错"})
            }else{
				result.forEach((el,index)=>{
					el.key = index
				})
                this.setState({
					students: result,
					loading:false,
					data: [],
					teachers: [],
					teacher_time:[],
					new_teacher_id: null,
					selected_lesson_id:[],
					selected_student_id:[],
					selected_sub_id:[],
					moveTime:0,
					moveTimeMount:null
				})
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })

	}

	componentDidMount(){
		this.loadData()
	}

	onStudentChange(value){
		this.setState({
			table_loading:true
		})
        AdminGetAllLesson({admin_id:sessionStorage.getItem('admin_id'),s_id:value},data=>{
			let {data:{status,tips,result}} = data
			if(status!==100){
				message.warning(tips)
				return
			}
			let new_result = []
			result.forEach((el,index)=>{
				el.key = index
				if(sessionStorage.getItem('auth_id') === '6'){
					if(el.proxy.toString() === sessionStorage.getItem('admin_id')){
						new_result.push(el)
					}
				}
			})
			this.setState({
				data:sessionStorage.getItem('auth_id') === '6'?new_result:result,
				table_loading:false,
				selected_lesson_id:[],
				s_id:value
			})
		}).catch(err=>{
			console.log(err)
		})
    }

	onSelectChange(value) {
        this.setState({
			new_teacher_id:value
        })
	}

	onSelectTeacherChange(index){
		const {teachers} = this.state
		this.setState({
			teacher_time : teachers[index].available_time,
			new_teacher_id:teachers[index].t_id
		})
	}
	
	onSelectTeacherTimeChange(value) {
        this.setState({
			select_time:value
        })
	}

	update(){
		const { new_teacher_id, selected_lesson_id, selected_student_id,select_time } = this.state
		if(!new_teacher_id){
			message.warning('请选择新教师！')
			return
		}
		if(!selected_lesson_id.length){
			message.warning('请选择要修改的课时！')
			return 
		}
		AdminUpdateTidInLessons({
			admin_id:sessionStorage.getItem('admin_id'), 
			new_teacher_id, 
			selected_lesson_id, 
			selected_student_id:[...selected_student_id][0],
			new_time:select_time,
		},request_res=>{
			const {data:{status,tips}} = request_res
			if(status!==100){
				AlertMsg({message:'数据错误',des:tips})
				return
			}
			message.success('修改成功！')
			this.loadData()
			this.setState({
				success: true
			})
		}).catch(err=>{
			AlertMsg({message:'网络错误',des:'网络连接出错'})
			console.log(err)
		})
	}

	delete(){
		const { selected_lesson_id,s_id } = this.state
		if(!selected_lesson_id.length){
			message.warning('请选择要删除的课时！')
			return 
		}
		AdminDeleteLessons({admin_id:sessionStorage.getItem('admin_id'), selected_lesson_id, s_id },request_res=>{
			const {data:{status,tips}} = request_res
			if(status!==100){
				AlertMsg({message:'数据错误',des:tips})
				return
			}
			message.success('删除成功！')
			this.loadData()
			this.setState({
				success: true
			})
		}).catch(err=>{
			AlertMsg({message:'网络错误',des:'网络连接出错'})
			console.log(err)
		})
	}

	AdvanceOrPostpone(){
		const { selected_lesson_id,moveTime,moveTimeMount } = this.state
		if(!moveTimeMount){
			message.warning("提前或推迟时间不能为空")
		}
		AdvanceAndPostpone({
			admin_id:sessionStorage.getItem("admin_id"),
			lesson_id_list:selected_lesson_id,
			type:moveTime,
			amount:moveTimeMount
		},data=>{
			const {data:{status,tips}} = data
			if(status!==100){
				AlertMsg({message:'变更错误',des:tips})
				return
			}
			message.success('更改成功')
			this.loadData()
			this.setState({
				success: true
			})
		})
	}

	getColumnSearchProps = dataIndex => ({
	  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		<div style={{ padding: 8 }}>
		  <Input
			ref={node => {
			  this.searchInput = node;
			}}
			placeholder={`搜索 ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ width: 188, marginBottom: 8, display: 'block' }}
		  />
		  <Space>
			<Button
			  type="primary"
			  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  icon={<SearchOutlined />}
			  size="small"
			  style={{ width: 90 }}
			>
			  搜索
			</Button>
			<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  重置
			</Button>
		  </Space>
		</div>
	  ),
	  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	  onFilter: (value, record) =>
		record[dataIndex]
		  ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		  : '',
	  onFilterDropdownVisibleChange: visible => {
		if (visible) {
		  setTimeout(() => this.searchInput.select(), 100);
		}
	  },
	  render: text =>
		this.state.searchedColumn === dataIndex ? (
		  <Highlighter
			highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			searchWords={[this.state.searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
		  />
		) : (
		  text
		),
	});
  
	handleSearch = (selectedKeys, confirm, dataIndex) => {
	  confirm();
	  this.setState({
		searchText: selectedKeys[0],
		searchedColumn: dataIndex,
	  });
	};
  
	handleReset = clearFilters => {
	  clearFilters();
	  this.setState({ searchText: '' });
	};

	getTeacherInfo(){
		let {selected_sub_id,selected_lesson_time} = this.state
		getTeacherTime({
			sub_id:[...selected_sub_id][0],
			lesson_time:selected_lesson_time.sort()[0],
		},data=>{
			const {data:{result,tips,status}} = data
			if(status!==100){
				message.warning(tips)
				return
			}
			this.setState({
				teachers:result
			})
			message.success("获取成功,选择老师")
		})
	}

	handleDelay = () =>{
		this.setState({

		})
	}
  
	render() {
		const {loading,success,selected_lesson_id,total_price} = this.state
        if(loading){
            return <Spin size="large"/>
        }
		if(success){
			return <Result
                icon={<SmileOutlined />}
                title={"操作成功"}
                subTitle="操作已成功执行。"
                extra={[
                    <Button type="primary" onClick={() => this.setState({success:false})}>
                        好的
                    </Button>
                ]}
            />
		}else{
			const rowSelection = {
				onChange: (selectedRowKeys, selectedRows) => {
				let selected_lesson_id = []
				let selected_student_id = new Set()
				let selected_lesson_time = []
				let selected_sub_id = new Set()
				let total_price = 0
				selectedRows.forEach(row=>{
					total_price += row.unit_price
					selected_lesson_id.push(row.lesson_id)
					selected_student_id.add(row.s_id)
					selected_sub_id.add(row.sub_id)
					selected_lesson_time.push(row.lesson_time)
				})
				this.setState({
					selected_lesson_id,
					selected_student_id,
					selected_sub_id,
					selected_lesson_time,
					select_time:null,
					teachers:[],
					total_price
				})
				},
				getCheckboxProps: (record) => ({
				disabled: record.name === 'Disabled User',
				// Column configuration not to be checked
				name: record.name,
				}),
			};
			const columns = [
				{
					title: '学号',
					dataIndex: 'student_num',
					key: 'student_num',
					...this.getColumnSearchProps('student_num'),
				},
				{
					title: '学生姓名',
					dataIndex: 'student_name',
					key: 'student_name',
					...this.getColumnSearchProps('student_name'),
				},
				{
					title: '教师工号',
					dataIndex: 'teacher_num',
					key: 'teacher_num',
					...this.getColumnSearchProps('teacher_num'),
				},
				{
					title: '教师姓名',
					dataIndex: 'teacher_name',
					key: 'teacher_name',
					...this.getColumnSearchProps('teacher_name'),
				},
				{
					title: '课程代码',
					dataIndex: 'subject_code',
					key: 'subject_code',
					...this.getColumnSearchProps('subject_code'),
				},
				{
					title: '课程名称',
					dataIndex: 'name',
					key: 'name',
					...this.getColumnSearchProps('name'),
				},
				{
					title: '开课时间-当地时间',
					dataIndex: 'lesson_time',
					key: 'lesson_time',
					width: 100,
					render: text => (
						DateFormat('M/D H:m',parseInt(text))
					),
					align:'center'
				},
				{
					title: '临时时间-当地时间',
					dataIndex: 'temp_time',
					key: 'temp_time',
					width: 100,
					render: text => {
						if(text === null){
							return '无'
						}else{
							return DateFormat('M/D H:m',parseInt(text))
						}
					},
					align:'center'
				},
				{
					title: 'zoom',
					dataIndex: 'zoom',
					key: 'zoom',
					...this.getColumnSearchProps('zoom'),
				},
			];
			let edit = <>
				<Row>
					<Col span={2}>
						<h2><b>已勾选</b></h2>
						<h2 style={{color:'#096dd9'}}><b>{this.state.selected_lesson_id.length}({total_price} RMB)</b></h2>
					</Col>
					<Col span={1}>
						<Divider type="vertical" style={{height:'100%'}}/>
					</Col>
					<Col span={9}>
						<h2><b>更换课时时间及教师（单节）</b></h2>
						<Select
							disabled = {!this.state.teachers.length}
							style={{minWidth:200}}
							placeholder="选择新教师"
							optionFilterProp="children"
							onChange={this.onSelectTeacherChange.bind(this)}
						>
							{
								this.state.teachers.map((teacher, index) => {
									return <Option key={index} value={index}>{`${teacher.username} : ${teacher.teacher_num}`}</Option>
								})
							}
						</Select>
						<Select
							disabled = {!this.state.teachers.length}
							style={{minWidth:200}}
							placeholder="选择时间"
							optionFilterProp="children"
							onChange={this.onSelectTeacherTimeChange.bind(this)}
						>
							{
								this.state.teacher_time.map((t, index) => {
									return <Option key={index} value={t}>{DateFormat("M/D H:m",t)}</Option>
								})
							}
						</Select>
						{
						this.state.select_time?
							<Button type="primary" onClick={()=>this.update()}>确认</Button>
						:
							<Button type="primary" disabled={selected_lesson_id.length!==1} onClick={()=>this.getTeacherInfo()}>获取可用的时间</Button>
						}
					</Col>
					<Col span={1}>
						<Divider type="vertical" style={{height:'100%'}}/>
					</Col>
					<Col span={6}>
						<h2><b>推迟或提前选中课程</b></h2>
						<Select defaultValue="提前" 
						disabled={!selected_lesson_id.length}
						style={{ width: 120 }} onChange={(e)=>this.setState({moveTime:e})}>
							{["提前","推迟"].map((p,index) => (
							<Option key={index}>{p}</Option>
							))}
						</Select>
						<Select style={{ width: 120 }} placeholder="提前或推迟时间" disabled={!selected_lesson_id.length} onChange={(e)=>this.setState({moveTimeMount:e})}>
							{DelayTimeList.map((p,index) => (
							<Option key={index} value={p.value} >{p.tag}</Option>
							))}
						</Select>
						<Popconfirm
							title="确定要执行吗?"
							onConfirm={()=>this.AdvanceOrPostpone()}
							okText="确定"
							cancelText="取消"
							icon={null}
						>
							<Button type="primary">确认更改</Button>
						</Popconfirm>
					</Col>
					<Col span={1}>
						<Divider type="vertical" style={{height:'100%'}}/>
					</Col>
					<Col span={4}>
						<h2><b>删除选中课程</b></h2>
						<Popconfirm
							title={`确定删除价值${total_price}(RMB)的课程?`}
							onConfirm={()=>this.delete()}
							okText="Yes"
							cancelText="No"
							icon={null}
						>
							<Button type="primary" danger>删除选中的课程</Button>
	  					</Popconfirm>
					</Col>
				</Row>
				<Divider />
			</>
			return <>
				<h2><b>选择学生</b></h2>
                <Select
					showSearch
                    style={{minWidth:200}}
                    placeholder="选择学生"
                    optionFilterProp="children"
                    onChange={this.onStudentChange.bind(this)}
                >
                    {
                        this.state.students.map((student, index) => {
                            return <Option key={index} value={student.s_id}>{`${student.username} : ${student.student_num}`}</Option>
                        })
                    }
				</Select>
				<Divider />
				{sessionStorage.getItem('auth_id') === '6'?<></>:edit}
				{this.state.table_loading?
					<>
					<Spin size="large"/>
					</>
				:
					<Table 
						bordered 
						rowSelection={{
							...rowSelection,
						}}
						columns={columns} 
						dataSource={this.state.data} 
						pagination={{ pageSize: 20 }}
						size="middle"
					/>
				}
				
			</>
		}
	}
}

class EditLesson extends React.Component {
    render(){
        return <>
            <SearchableTable />
        </>
    }
}

export default EditLesson;