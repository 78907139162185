import React, { Component } from 'react';
import { Layout, Collapse,Tabs } from 'antd';
import TopNav from '../components/TopNav';
import Footer1 from '../components/Footer1';
import {TransText} from "../components/PublicMethods"
import '../style/pages/help.css'

const { Header, Content, Footer } = Layout;
const { Panel } = Collapse;
const { TabPane } = Tabs;

class Help extends Component {

    render() {
        const question_answer = [
            {
                tagName:{CN:"学生",EN:"Student"},
                question:[
                    {
                        Q:"如何注册账户",
                        A:<p>注册账户入口位于登录界面表格下方的<span className="focus-block">"立即注册"</span>链接，然后根据提示即可完成注册。</p>
                    },
                    {
                        Q:"登录问题",
                        A:<p>登录失败请检查密码和学生账号是否输入正确，学生账号会在您注册时发往您的邮箱。</p>
                    },
                    {
                        Q:"网络问题",
                        A:<p>请检查本地网络连接，同时本网站不可在有第三方代理的环境下运行<span className="focus-warning">（VPN）</span>。</p>
                    },
                    {
                        Q:"收不到邮箱",
                        A:<p>检查是否被邮箱自动分类到其他地方，或者与代理联系修改邮箱。</p>
                    },
                    {
                        Q:"如何预定课程",
                        A:<p>在课程总览或者首页选中您所要预定的课程，点击<span className="focus-block">"立刻报名"</span>，选择购买的课时<span className="focus-element">（默认30节）</span>，选择老师以及合适的上课时间，蓝色时间块为可用时间，灰色为占用时间块，点击确定预约，
                        您的代理会尽快为您安排课程。</p>
                    },
                    {
                        Q:"更改课程时间，取消课程",
                        A:<p>点击<span className="focus-block">"我的课程"</span>，选中需要更改时间的课程，点击位于上课时间右方的<span className="focus-block"><span className='iconfont icon-shijian' />"时间图标"</span>,选择所要更换的时间或者取消课程，
                        更换时间必须在<span className="focus-element">上课24小时之前</span>提交，更换时间会以申请的方式发送给老师,待老师同意后即可更换成功，同时您的老师也可能发送更换时间申请，您可以在<span className="focus-block">"更换时间请求"</span>中查看。</p>
                    },
                    {
                        Q:"如何设置放假时间",
                        A:<p>您可以在<span className="focus-block">"本周安排"</span>右上方找到假期设置按钮，假期开始时间只能设置在<span className="focus-element">两周之后</span>，并且限定假期最长为<span className="focus-element">2周。</span></p>
                    },
                ]
            },
            {
                tagName:{CN:"老师",EN:"Teacher"},
                question:[
                    {
                        Q:"Can not log in?",
                        A:<p>Please check if the password and teacher number are entered correctly. The teacher account number will be sent to your email when you register.</p>
                    },
                    {
                        Q:"Internet problem?",
                        A:<p>Please check the local network connection, and this website cannot be operated in an environment with a third-party proxy <span className="focus-warning">(VPN)</span>.</p>
                    },
                    {
                        Q:"How to have a course?",
                        A:<p>First, the teacher administrator will assign you courses. You need to set your own available time. 
                        After logging in, in <span className="focus-block">"Edit Available Time"</span> click the course code and select the time block. 
                        The selected time means that you can teach during this time period</p>
                    },
                    {
                        Q:"How to conduct the lessons?",
                        A:<p><span className="focus-element">15 minutes</span> before the class, you can click on the course that is about to start in <span className="focus-block">"Course Resources"</span>. 
                        Normally, your next lesson will be the first in <span className="focus-block">"upcomming"</span>. 
                        Just click to <span className="focus-start">"start"</span> to start the lesson. You can click on the zoom id to copy it. It is more convenient to go to ZOOM to start the class. 
                        In addition, when this lesson is over, you need to click <span className="focus-end">"End Lesson"</span> to end the class, <span className="focus-warning">which is an important part of calculating the student's progress</span>
                        .All course-related documents, assignments, and videos can be uploaded directly in corresponding position, and the assignments submitted by students will also be imported in the corresponding course location</p>
                    },
                    {
                        Q:"Change a lesson time, cancel single lesson?",
                        A:<p>Click on <span className="focus-block">"Course Resources"</span>, select the lesson that needs to change the time, 
                        click on the <span className="focus-block"><span className='iconfont icon-shijian' />"clock icon"</span> located to the right of the lesson time, select the time to be changed or cancel the course, 
                        the time changing must be submitted <span className="focus-element">24 hours</span> before the class, and request for replacement time will be sent to the parent, 
                        then the replacement can be successful after the parent agrees. 
                        At the same time, you may also process the replacement time application from the student. Check it in the <span className="focus-block">"Time Change Request"</span></p>
                    },
                    {
                        Q:"How to set holiday time?",
                        A:<p>You can find the <span className="focus-block">"set holiday"</span> button in the upper right of <span className="focus-block">"Edit Available Time"</span>. The start time of the vacation must be <span className="focus-element">two weeks</span> later and the longest holiday is <span className="focus-element">two weeks</span>. 
                        During the vacation, you will <span className="focus-element">NOT</span> receive any new course.</p>
                    },
                ]
            }
        ]
        return (
            <Layout>
                <Header style={{ backgroundColor: 'white' }}>
                    <TopNav  menuItem={'4'}/>
                </Header>
                <Content style={{backgroundColor:"#ffffff"}}>
                    <section id="help">
                        <h1><TransText CN='常见问题' EN='Common Questions' /></h1>
                        <Tabs defaultActiveKey={sessionStorage.getItem("t_id")?"2":"1"} type="card" size="large">
                            {question_answer.map((question_data,index)=>{
                                return <TabPane tab={TransText(question_data.tagName)} key={index+1+""}>
                                    {
                                        question_data.question.map((q,i)=>{
                                            return <Collapse key={i} accordion>
                                                <Panel header={q.Q} key="1">
                                                    {q.A}
                                                </Panel>
                                            </Collapse>
                                        })
                                    }
                                </TabPane>
                            })}
                        </Tabs>
                        
                    </section>
                </Content>
                <Footer style={{ textAlign: 'center', backgroundColor: '#fafafa' }}>
                    <Footer1 />
                </Footer>
            </Layout>
        )
    }
}

export default Help;