import React from 'react';
import { Table, Input, Button, Space, Popconfirm, message, Spin, InputNumber,Tabs,List,Select } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { AdminConfirmOrderSheetHandle,AdminGetSettleView,axiosAll,GetAllAdmin,GetProxyNotSettleLessons } from '../../request/axios';
import { AlertMsg, DateFormat } from '../PublicMethods';
const { TabPane } = Tabs;
const { Option } = Select

class SearchableTable extends React.Component {
	state = {
	  searchText: '',
	  searchedColumn: '',
	  admin_list:[],
	  data: [],
	  view_data:[],
	  loading:true,
	  selectedRowKeys:[],
	  record:[],
	  can_settle : false,
	  proxy_persent : null
	};
    
	loadData(){
		axiosAll([
			AdminGetSettleView(),
			GetAllAdmin({admin_id:sessionStorage.getItem('admin_id')})
		],data=>{
			let view_data = data[0].data.result
			this.setState({
				admin_list:data[1].data.result,
				loading:false,
				view_data,
			})
		})
	}

    confirm = () => {
		const {record,proxy_persent,select_proxy} = this.state
		if(proxy_persent==null){
			message.warning("请输入代理分成")
			return
		}
		let param = {proxy_persent:proxy_persent/100,records:[]}
		let sum = 0,teacher_paid = 0,total = 0
		record.forEach(e=>{
			//课程总价
			total += e.unit_price
			//老师分成
			teacher_paid += (e.salary+e.amplitude)*5
			//代理提成
			sum += e.unit_price*proxy_persent/100
			param.records.push(e.lesson_id)
		})
		param.total = total.toFixed(2)
		param.teacher_paid = teacher_paid.toFixed(2)
		param.proxy_sum = sum.toFixed(2)
		param.admin_id = sessionStorage.getItem("admin_id")
		param.proxy_id = select_proxy
		param.benefit = total - teacher_paid -sum
		AdminConfirmOrderSheetHandle(param,request_res=>{
            const {data:{status,tips="查询错误"}} = request_res
            if(status!==100){
				message.warning(tips)
            }else{
                message.success('成功设置成已结算！')
                this.onProxyChange(this.state.select_proxy,true)
            }
        }).catch(err=>{
            AlertMsg({message:"网络错误",des:"请求数据出错"})
            console.log(err.message)
        })
    }

    cancel = (e) =>{
        message.error('Click on No');
    }
    
	componentDidMount(){
		this.loadData()
	}

	getColumnSearchProps = dataIndex => ({
	  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		<div style={{ padding: 8 }}>
		  <Input
			ref={node => {
			  this.searchInput = node;
			}}
			placeholder={`搜索 ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ width: 188, marginBottom: 8, display: 'block' }}
		  />
		  <Space>
			<Button
			  type="primary"
			  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
			  icon={<SearchOutlined />}
			  size="small"
			  style={{ width: 90 }}
			>
			  搜索
			</Button>
			<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  重置
			</Button>
		  </Space>
		</div>
	  ),
	  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	  onFilter: (value, record) =>
		record[dataIndex]
		  ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		  : '',
	  onFilterDropdownVisibleChange: visible => {
		if (visible) {
		  setTimeout(() => this.searchInput.select(), 100);
		}
	  },
	  render: text =>
		this.state.searchedColumn === dataIndex ? (
		  <Highlighter
			highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
			searchWords={[this.state.searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
		  />
		) : (
		  text
		),
	});
  
	handleSearch = (selectedKeys, confirm, dataIndex) => {
	  confirm();
	  this.setState({
		searchText: selectedKeys[0],
		searchedColumn: dataIndex,
	  });
	};
  
	handleReset = clearFilters => {
	  clearFilters();
	  this.setState({ searchText: '' });
	};
  
	onSelectChange = (selectedRowKeys,record)  =>{
		const {data} = this.state
		
		let can_settle = false
		let [...temp] = selectedRowKeys
		if(temp.length<5 && temp.length>0){
			while(temp.length<10){
				temp.push(temp.slice(-1)[0]+1)
				data.forEach(e=>{
					if(e.key === temp.slice(-1)[0]){
						record.push(e)
					}
				})
			}
		}
		if(temp.length>10){
			message.warning("最多选择10节课")
		}
		if(temp.length>=15){
			selectedRowKeys = []
		}
		can_settle = temp.length===10
		this.setState({ selectedRowKeys:temp,can_settle,record,proxy_persent:null });
	}

	onProxyChange(key,loading){
		GetProxyNotSettleLessons({admin_id:sessionStorage.getItem("admin_id"),target:key},data=>{
			const {data:{status,result,tips="查询错误"}} = data
			if(status!==100){
				message.warning(tips)
				return 
			}
			result.forEach((e,index)=>{
				e.key = index
			})
			if(loading){
				this.setState({
					data:result,
					select_proxy:key,
					selectedRowKeys:[],
					record:[],
					proxy_persent:null
				})
			}else{
				this.setState({
					data:result,
					select_proxy:key
				})
			}
		})
		
	}

	render() {
		if(this.state.loading){
            return <Spin size="large"/>
        }
		const { selectedRowKeys,proxy_persent,can_settle,view_data,admin_list } = this.state
		const columns = [
            {
                title: '学生学号',
                dataIndex: 'student_num',
				key: 'student_num',
				align:"center",
				...this.getColumnSearchProps('student_num'),
            },
            {
                title: '学生姓名',
                dataIndex: 'username',
				key: 'username',
				align:"center",
				...this.getColumnSearchProps('username'),
            },
            {
                title: '老师',
                dataIndex: 'teacher_name',
				key: 'teacher_name',
				align:"center",
				...this.getColumnSearchProps('teacher_name'),
            },
            {
                title: '课程代码',
                dataIndex: 'subject_code',
				key: 'subject_code',
				align:"center",
				...this.getColumnSearchProps('subject_code'),
            },
            {
                title: '课程单价',
                dataIndex: 'unit_price',
				key: 'unit_price',
				align:"center",
            },
            {
                title: '购买课程时间',
                dataIndex: 'creation_time',
				key: 'creation_time',
				align:"center",
				render : (text) =>{
					return DateFormat("Y/M/D",parseInt(text)) 
				}
            },
			{
                title: '上课时间',
                dataIndex: 'lesson_time',
				key: 'lesson_time',
				align:"center",
				render : (text) =>{
					return DateFormat("Y/M/D",parseInt(text)) 
				}
            },
        ];

		const rowSelection = {
			selectedRowKeys,
			onChange: this.onSelectChange,
		};

		//输入代理分成 细节计算
		const getString = () =>{
			const {record,proxy_persent} = this.state
			const columns = [
				{
					title:"课程单价",
					dataIndex:"unit",
					align:"center",
					key:"unit"
				},
				{
					title:"代理提成",
					dataIndex:"present",
					align:"center",
					key:"present"
				},
				{
					title:"学生涨幅",
					dataIndex:"amplitude",
					align:"center",
					key:"amplitude"
				},
				{
					title:"老师价格",
					dataIndex:"salary",
					align:"center",
					key:"salary"
				},
			]
			let source_data = []
			let sum = 0,teacher_paid = 0,total = 0
			record.forEach(e=>{
				source_data.push({
					present:proxy_persent+"%",
					unit:e.unit_price,
					amplitude:e.amplitude,
					salary:e.salary
				})
				//课程总价
				total += e.unit_price
				//老师分成
				teacher_paid += (e.salary+e.amplitude)*5
				//代理提成
				sum += e.unit_price*proxy_persent/100
			})
			total = total.toFixed(2)
			sum = sum.toFixed(2)
			teacher_paid = teacher_paid.toFixed(2)
			return (
				<>
				<Table 
				pagination = {false}
				dataSource={source_data} 
				columns={columns} 
				title={() =><h2>计算明细</h2>}
				/>
				<Table 
				pagination = {false}
				title={() =><h2>综合明细</h2>}
				columns={[
					{
						title:"课程总价",
						dataIndex:"total",
						key:"total",
						align:"center",
					},
					{
						title:"代理提成",
						dataIndex:"sum",
						key:"sum",
						align:"center",
					},
					{
						title:"老师薪水",
						dataIndex:"salary",
						key:"salary",
						align:"center",
					},
					{
						title:"股东盈余",
						dataIndex:"benefit",
						key:"benefit",
						align:"center",
					}
				]} dataSource={[
					{
						sum,
						total,
						salary:teacher_paid,
						benefit:total-sum-teacher_paid
					}
				]} />
				</>
			)
		}

		const inputPersent = (e)=>{
				this.setState({
					proxy_persent:e
				})
		}

	  	return (<Tabs defaultActiveKey="1" type="card" centered>
			<TabPane tab="代理结算统计" key="1">
				{view_data.length?
				<List
					header={<h2>未结算课时(只显示大于10节)</h2>}
					bordered
					dataSource = {view_data}
					renderItem={item=>{
						return item.count>=10?
						<List.Item key={item.admin_id}>
							{item.username}({item.admin_num}) 未结算课程数量: {item.count}
						</List.Item>
						:
						<></>
					}}
				/>
				:
				<></>
				}
			</TabPane>
			<TabPane tab="代理结算" key="2">
			<h2><b>选择代理</b></h2>
			<Select
				showSearch
				style={{minWidth:200}}
				placeholder="选择代理"
				onChange={this.onProxyChange.bind(this)}
			>
				{
					admin_list.map((student, index) => {
						return student.auth_id===6&&<Option key={index} value={student.admin_id}>{`${student.username} : ${student.admin_num}`}</Option>
					})
				}
			</Select>
			<div style={{margin:"20px",display:"flex",justifyContent:"space-around"}}>
			{
			selectedRowKeys.length
			?
			<>
			<InputNumber style={{margin:"0px 20px",width:"50%"}} 
				disabled={!can_settle}
				onChange={inputPersent} placeholder="请输入代理提成百分比(%)" />
				<Popconfirm
                                title="确认标记为已结算"
                                onConfirm={()=>this.confirm()}
                                onCancel={this.cancel}
                                okText="OK"
                                cancelText="NO"
                                style={{textAlign:'left'}}
                                icon={null}
                            >
                                <Button 
			style={{margin:"0px 20px"}}
			type="primary" >
				确认
			</Button>
            </Popconfirm>
			</>
			:
			<></>
			}
		  </div>
		  <div>	
		  	{
				proxy_persent!==null ?
				getString()
				:
				<></>
			}
		  </div>
		   
		  <Table 
		  bordered 
		  style={{marginTop:"30px"}}
		  rowSelection={rowSelection}
		  columns={columns} 
		  dataSource={this.state.data} 
		  pagination={{ pageSize: 15 }} 
		  size="middle"
		  />
			</TabPane>
		</Tabs>);
	}
}

class ViewOrderSheet extends React.Component {
    render(){
        return <>
            <SearchableTable />
        </>
    }
}

export default ViewOrderSheet;