import React from 'react';
import { Tabs } from 'antd';
// import { GlobalContext } from '../../request/context';
import ArrangeOrder from './ArrangeOrder';
import ViewProxyOrderSheet from './ViewProxyOrderSheet';
import InsertLesson from './InsertLesson';
const { TabPane } = Tabs;
// const { Provider } = GlobalContext;

class AuthArrange extends React.Component {
    render(){
        return <>
				<Tabs defaultActiveKey="1" type="card" centered>
					<TabPane tab="安排新课程" key="1">
						<ArrangeOrder />
					</TabPane>
					<TabPane tab="追加课时" key="2">
						<InsertLesson />
					</TabPane>
					<TabPane tab="安排课程记录" key="3">
						<ViewProxyOrderSheet />
					</TabPane>
				</Tabs>
        </>
    }
}

export default AuthArrange;