import React from 'react';
import { Form, Input, Button, Row, message,Checkbox  } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import PDF from 'react-pdf-js';
import "../../style/components/login/form.css"
import { login,setRead } from '../../request/axios';
import { AlertMsg, TransText } from '../PublicMethods';

class Form1 extends React.Component{

  constructor() {
    super()
    this.state = {
        number:'',
        password:'',
        pageNumber:1,
        showFile:false,
        file:null,
        notShowAgain:false,
    }
  }

  handleSubmit(){
    const data = {
        number:this.state.number, 
        password:this.state.password,
    }
    
    login(data,request_res=>{
        const {data:{result,status}} = request_res
        if(status!==100){
          AlertMsg({message:TransText({'CN':'登陆失败','EN':'Login Failed'}),des:TransText({'CN':'账号或密码错误','EN':'Incorrect number or password'})})
          return
        }
        if(data.number.startsWith("s")){
            sessionStorage.setItem('s_id', result.id)
            sessionStorage.setItem('s_username', result.username)
        }else{
            sessionStorage.setItem('t_id', result.id)
            sessionStorage.setItem('t_username', result.username)
        }
        sessionStorage.setItem('user_num', data.number)
        sessionStorage.setItem('holiday', result.holiday)
        if(!result.file_read){
          this.setState({
            showFile:true
          })
        }else{
          message.success(TransText({'CN':'登陆成功！','EN':'Logged in!'}))
          setTimeout(()=>window.location.href = this.props.target || "/userprofile",1000)
        }
    }).catch(err=>{
        AlertMsg({message:TransText({'CN':'登陆失败','EN':'Login Failed'}),des:TransText({'CN':'账号或密码错误','EN':'Incorrect number or password'})})
        console.log(err.message)
    })
  }

  handleInputChange = (event) =>{
    event.preventDefault()
    this.setState({
        [event.target.name]: event.target.value
    })
  }

  closeFilePage=()=>{
    const {notShowAgain} = this.state
    if(notShowAgain){
      setRead({s_id:sessionStorage.getItem("s_id"),t_id:sessionStorage.getItem("t_id")},data=>{
        const {data:{status,tips}} = data
        if(status!==100){
          message.warning(tips)
          return 
        }
        message.success(TransText({'CN':'登陆成功！','EN':'Logged in!'}))
        setTimeout(()=>window.location.href = this.props.target || "/userprofile",1000)
      })
    }else{
      if(sessionStorage.getItem("s_id") || sessionStorage.getItem("t_id")){
      message.success(TransText({'CN':'登陆成功！','EN':'Logged in!'}))
      setTimeout(()=>window.location.href = this.props.target || "/userprofile",1000)
      }
      this.setState({
        showFile:false,
        pageNumber:1
      })
    }
  }

  nextPage=()=>{
    this.setState({
      pageNumber:this.state.pageNumber+1
    })
  }

  notShowAgain=(e)=>{
    this.setState({
      notShowAgain:e.target.checked
    })
  }

    render(){
      const {pageNumber,showFile} = this.state
        return <>
        {showFile?
          <div id="view-file">
          <PDF file={`file/${
            sessionStorage.getItem("s_id") || sessionStorage.getItem("t_id")?
              sessionStorage.getItem("s_id")?"student_guidelines":"tutor_guidelines"
            :
            this.state.file==='s'?"student_guidelines":"tutor_guidelines"
            }.pdf`} page={pageNumber} />
          <div className="banner">
            {
            //文件总页数需要手动修改
            pageNumber===(sessionStorage.getItem("s_id") || sessionStorage.getItem("t_id")?
              sessionStorage.getItem("s_id")?3:4
              :
              this.state.file==='s'?3:4)?
            <>
            <Button type="primary" onClick={this.closeFilePage}>{TransText({CN:"关闭",EN:"Confirm"})}</Button>
            {
            sessionStorage.getItem("s_id") || sessionStorage.getItem("t_id") ?
            <Checkbox onChange={this.notShowAgain}>{TransText({CN:"下次登录不再显示",EN:"Do not show again next time"})}</Checkbox>
              :
              <></>
            }
            </>
            :
            <Button type="primary" onClick={this.nextPage}>{TransText({CN:"下一页",EN:"Next Page"})}</Button>
            }
          </div>
        </div>
        :
        <></>
        }
        
        <div className = "center">
          <Row>
          <img style = {{width:200,height:200,margin:"0 auto 30px auto"}} src = "images/static/eemulogo.png" alt = "logo"/>
          </Row>
        </div>
        <div className = "center">
            <Form
                name="normal_login"
                className="login-form"
                onFinish={this.handleSubmit.bind(this)}
                initialValues={{
                remember: true,
            }}
            >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: TransText({'CN':'请输入一个学生学号或者教师工号！','EN':'Please input a student or teacher number!'}),
                },
              ]}
            >
                <Input name="number" onChange={this.handleInputChange} prefix={<UserOutlined className="site-form-item-icon" />} placeholder={TransText({'CN':'学生学号/教师工号','EN':'Student/Teacher Number'})} />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: TransText({'CN':'请输入密码！','EN':'Please input your password!'}),
                  },
                ]}
              >
                <Input
                  name="password"
                  onChange={this.handleInputChange}
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder={TransText({'CN':'密码','EN':'Password'})}
                />
            </Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
              {TransText({'CN':'登录','EN':'Login'})}
            </Button>
            <Form.Item>
              <a className = "left" href="/register">{TransText({'CN':'立即注册','EN':'Register'})}</a>
              <a className="login-form-forgot" href="/forgotPassword">{TransText({'CN':'忘记密码？','EN':'Forgot Password?'})}</a>
            </Form.Item>
            <Form.Item>
              <Button type='link' block onClick={()=>this.setState({showFile:true, file:'s'})}>{TransText({'CN':'学生指引','EN':'Student Guidelines'})}</Button>
              <Button type='link' block onClick={()=>this.setState({showFile:true, file:'t'})}>{TransText({'CN':'教师指引','EN':'Tutor Guidelines'})}</Button>
            </Form.Item>
            </Form>
        </div>
        </>
    }
}


export default Form1;